import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getListShift(dispatch) {
  baseAxios
    .get(`/laporanshift/getshiftpatroliall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTSHIFTPATROLI", data: respon.data });
    })
    .catch(errorHandler);
}

export function addShift(data) {
  console.log(data);
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post(`/laporanshift/daftarshiftpatroli`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function addSoalShift(data) {
  if (data.pertanyaan == "") {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Pertanyaan tidak boleh kosong!",
    });
  } else if (data.dataShift.length == 0) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Shift tidak boleh kosong!",
    });
  } else {
    Swal.fire({
      title: "Loading ...",
      didOpen() {
        Swal.showLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    baseAxios
      .post("/laporanshift/daftarpertanyaanshift", data, {
        headers: { token: localStorage.getItem("tokenjti") },
      })
      .then(async (respon) => {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(errorHandler);
  }
}

export function getListSoal(dispatch) {
  baseAxios
    .get(`/laporanshift/getallpertanyaanshift`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTSOALSHIFT", data: respon.data });
    })
    .catch(errorHandler);
}

export function getAllReportShift(dispatch) {
  baseAxios
    .get(`/laporanshift/dapatakanlaporanall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_REPORTSHIFT", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpiReportShift(dispatch, data) {
  baseAxios
    .get(
      `/laporanshift/getgrafikreportshift?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabangWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPIREPORT", data: respon.data });
    })
    .catch(errorHandler);
}

export function getKpiReportShiftDashboard(dispatch, data) {
  try {
    let response = baseAxios.get(
      `/laporanshift/getgrafikreportshift?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabangWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export function createListLokasi(data) {
  console.log(data);
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post(`/laporanshift/createlokasi`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getListLokasi(dispatch) {
  baseAxios
    .get(`/laporanshift/getlokasi`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTLOKASI", data: respon.data });
    })
    .catch(errorHandler);
}

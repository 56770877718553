import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import {
  getCabangAktif,
  getGrafikIncident,
  getKpi1Training,
  getKpi2Pembinaan,
  getKpi3Absensi,
} from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const trainingPersonnel = useSelector((state) => state.trainingReducer);
  const incident = useSelector((state) => state.incidentReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(
    // new Date().setHours(0, 0, 0, 0)
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );
  const [smpTgl, setSmpTgl] = React.useState(
    // new Date().setHours(23, 59, 0, 0)
    new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 0, 0)
  );
  const [kpi1Option, setKpi1Option] = React.useState(null);
  const auth = useSelector((state) => state.authReducer);
  const [idCabang, setIdCabang] = React.useState([]);
  const [listCabang, setListCabang] = React.useState([]);

  // const [kpi2Option, setKpi2Option] = React.useState(null);
  // const [kpi3Option, setKpi3Option] = React.useState(null);

  // React.useEffect(() => {
  //   if (trainingPersonnel.kpi1) {
  //     setKpi1Option(trainingPersonnel.kpi1.data);
  //   }
  // }, [trainingPersonnel.kpi1]);
  React.useEffect(() => {
    if (incident.getGrafikIncident) {
      setKpi1Option(incident.getGrafikIncident.data);
    }
  }, [incident.getGrafikIncident]);
  // React.useEffect(() => {
  //   if (trainingPersonnel.kpi2) {
  //     setKpi2Option(trainingPersonnel.kpi2.data);
  //   }
  // }, [trainingPersonnel.kpi2]);
  // React.useEffect(() => {
  //   if (trainingPersonnel.kpi3) {
  //     setKpi3Option(trainingPersonnel.kpi3.data);
  //   }
  // }, [trainingPersonnel.kpi3]);
  React.useEffect(() => {
    getGrafikIncident(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: idCabang.value,
    });
    // getKpi2Pembinaan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi3Absensi(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
  }, [dariTgl, smpTgl, idCabang]);
  React.useEffect(() => {
    getCabangAktif(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        // ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });

    setListCabang(tmp);
    tmp.map((val, index) => {
      if (index === 0) {
        setIdCabang(val);
      }
    });
  }, [cabang.getCabangAktif]);
  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        {/* {trainingPersonnel.kpi3 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">
                    Personnel Attendance Achievement
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {Math.ceil(trainingPersonnel.kpi3.pencapaian)} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {trainingPersonnel.kpi3.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi3Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={kpi3Option}>
                              <XAxis dataKey="nama" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Masuk"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Ijin"
                                barSize={20}
                                fill="#ff9d00"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Absen"
                                barSize={20}
                                fill="#ff0000"
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/absen");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          trainingPersonnel.kpi3.pencapaian <
                          trainingPersonnel.kpi3.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )} */}

        {incident.getGrafikIncident && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Row>
                    <Col>
                      <Card.Title as="h4">Incident Recapitulation</Card.Title>
                    </Col>
                    {auth.role === "all" ||
                    auth.role === "gsc" ||
                    auth.role === "market" ? (
                      <>
                        <Col md="6">
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setIdCabang(value);
                            }}
                            value={idCabang}
                            options={[
                              // {
                              //   value: "",
                              //   label: "Choose the Client",
                              //   isDisabled: true,
                              // },
                              ...listCabang,
                            ]}
                            placeholder="Single Select"
                          />
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        {/* <div className="d-flex flex-column align-items-center">
                          <p
                            className="card-category"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            Status
                          </p>
                          <Card.Title as="h4">
                            <p
                              className="card-category"
                              style={{
                                fontWeight: "bold",
                                color:
                                  trainingPersonnel.kpi1.pencapaian === "Below"
                                    ? "red"
                                    : trainingPersonnel.kpi1.pencapaian ===
                                      "MID"
                                    ? "orange"
                                    : "green",
                              }}
                            >
                              {trainingPersonnel.kpi1.pencapaian}
                            </p>
                          </Card.Title>
                        </div> */}
                        {/* <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {trainingPersonnel.kpi1.pencapaian} %
                          </Card.Title>
                        </div> */}
                        {/* <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {trainingPersonnel.kpi1.target} %
                          </Card.Title>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi1Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={kpi1Option}>
                              <XAxis dataKey="nama" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Exceed"
                                barSize={20}
                                fill="#006605"
                                stackId="c"
                              ></Bar>
                              <Bar
                                dataKey="Meet"
                                barSize={20}
                                fill="#ff8200"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Below"
                                barSize={20}
                                fill="#ff002f"
                                stackId="b"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/incidentreport");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          // trainingPersonnel.kpi1.pencapaian <
                          // trainingPersonnel.kpi1.target
                          //   ? "danger"
                          //   : "info"
                          "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}

        {/* {trainingPersonnel.kpi2 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Mentoring Achievement</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {trainingPersonnel.kpi2.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {trainingPersonnel.kpi2.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {trainingPersonnel.kpi2.kejadian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {trainingPersonnel.kpi2.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      {kpi2Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={kpi2Option}>
                              <XAxis dataKey="nama" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="On Time"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Not Ontime"
                                barSize={20}
                                fill="#ff002f"
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/pembinaan");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          trainingPersonnel.kpi2.pencapaian <
                          trainingPersonnel.kpi2.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )} */}
      </Container>
    </>
  );
}

export default Panels;

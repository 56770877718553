import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getPertanyaan,
  kirimHasilSurvey,
  simpanPertanyaan,
} from "../../../stores";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function LoginPage() {
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.surveyReducer);
  const [pertanyaanArr, setPertanyaanArr] = React.useState([]);
  React.useEffect(() => {
    getPertanyaan(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = survey.pertanyaan.map((val, index) => {
      return {
        pertanyaan: val.pertanyaan,
      };
    });
    setPertanyaanArr(tmp);
  }, [survey.pertanyaan]);
  return (
    <>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg").default}
      >
        <div className="survey-form">
          <Card style={{ backgroundColor: "white" }}>
            <Card.Header>
              <Card.Title as="h4">Survey</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <Form.Label column>
                    Survey kepuasaan terhadap lingkungan
                  </Form.Label>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {pertanyaanArr.map((val, index) => {
            return (
              <Card style={{ backgroundColor: "white" }}>
                <Card.Body>
                  <Row>
                    <Col sm="12">
                      <Form className="form-horizontal">
                        <Form.Group>
                          <Row>
                            <Col className="control-label" md="1">
                              {index + 1}.
                            </Col>
                            <Col md="9">
                              <Form.Control
                                onChange={(e) => {
                                  setPertanyaanArr([
                                    ...pertanyaanArr.slice(0, index),
                                    {
                                      pertanyaan: e.target.value,
                                    },
                                    ...pertanyaanArr.slice(
                                      index + 1,
                                      pertanyaanArr.length
                                    ),
                                  ]);
                                }}
                                value={val.pertanyaan}
                                // placeholder="Email"
                                type="text"
                              ></Form.Control>
                            </Col>
                          </Row>
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col sm="12">
                      <Form.Group>
                        <Row>
                          <Col>
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  disabled={true}
                                  name={`pertanyaan${index}`}
                                  type="radio"
                                  value="50"
                                ></Form.Check.Input>
                                <span className=""></span>
                                Buruk
                              </Form.Check.Label>
                            </Form.Check>

                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  disabled={true}
                                  name={`pertanyaan${index}`}
                                  type="radio"
                                  value="100"
                                ></Form.Check.Input>
                                <span className=""></span>
                                Baik
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col>
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  disabled={true}
                                  name={`pertanyaan${index}`}
                                  value="1"
                                  type="radio"
                                ></Form.Check.Input>
                                <span className=""></span>
                                Buruk Sekali
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  disabled={true}
                                  name={`pertanyaan${index}`}
                                  type="radio"
                                  value="2"
                                ></Form.Check.Input>
                                <span className=""></span>
                                Buruk
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  disabled={true}
                                  name={`pertanyaan${index}`}
                                  type="radio"
                                  value="3"
                                ></Form.Check.Input>
                                <span className=""></span>
                                Cukup
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  disabled={true}
                                  name={`pertanyaan${index}`}
                                  type="radio"
                                  value="4"
                                ></Form.Check.Input>
                                <span className=""></span>
                                Cukup Baik
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  disabled={true}
                                  name={`pertanyaan${index}`}
                                  type="radio"
                                  value="5"
                                ></Form.Check.Input>
                                <span className=""></span>
                                Baik
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  disabled={true}
                                  name={`pertanyaan${index}`}
                                  type="radio"
                                  value="6"
                                ></Form.Check.Input>
                                <span className=""></span>
                                Baik Sekali
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Row> */}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      {index !== 0 && (
                        <Button
                          onClick={() => {
                            let tmp = pertanyaanArr[index - 1];
                            pertanyaanArr[index - 1] = pertanyaanArr[index];
                            pertanyaanArr[index] = tmp;
                            setPertanyaanArr([...pertanyaanArr]);
                          }}
                          className="btn-wd btn-outline mr-1"
                          type="button"
                          variant="info"
                        >
                          Up
                        </Button>
                      )}
                      {index !== pertanyaanArr.length - 1 && (
                        <Button
                          onClick={() => {
                            let tmp = pertanyaanArr[index + 1];
                            pertanyaanArr[index + 1] = pertanyaanArr[index];
                            pertanyaanArr[index] = tmp;
                            setPertanyaanArr([...pertanyaanArr]);
                          }}
                          className="btn-wd btn-outline mr-1"
                          type="button"
                          variant="info"
                        >
                          Down
                        </Button>
                      )}

                      <Button
                        onClick={() => {
                          setPertanyaanArr([
                            ...pertanyaanArr.slice(0, index),

                            ...pertanyaanArr.slice(
                              index + 1,
                              pertanyaanArr.length
                            ),
                          ]);
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="danger"
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            );
          })}

          <Row>
            <Col>
              <Button
                className="btn-fill btn-wd"
                variant="info"
                onClick={(e) => {
                  setPertanyaanArr([
                    ...pertanyaanArr,
                    {
                      pertanyaan: "",
                    },
                  ]);
                }}
              >
                Add Question
              </Button>
              <Button
                className="btn-fill btn-wd ml-2"
                variant="success"
                onClick={(e) => {
                  simpanPertanyaan(pertanyaanArr);
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>

        <div
          className="full-page-background"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/full-screen-image-2.jpg").default +
              ")",
          }}
        ></div>
      </div>
    </>
  );
}

export default LoginPage;

const initialState = {
  listPenampilan: [],
  kpiPenampilan: null,
};

const penampilanReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_LISTPENAMPILAN":
      return {
        ...state,
        listPenampilan: data,
      };
    case "SET_KPIPENAMPILAN":
      return {
        ...state,
        kpiPenampilan: data,
      };

    default:
      return state;
  }
};

export default penampilanReducer;

import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getPembinaanPenampilan(dispatch) {
  baseAxios
    .get(`/pembinaanpenampilan/getpembinaanpenampilanall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTPENAMPILAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiPembinaanPenampilan(data) {
  baseAxios
    .patch(`/pembinaanpenampilan/responpembinaanpenampilan`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi2PembinaanPenampilan(dispatch, data) {
  baseAxios
    .get(
      `/pembinaanpenampilan/getgrafikpembinaanpenampilan?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPIPENAMPILAN", data: respon.data });
    })
    .catch(errorHandler);
}

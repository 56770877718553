import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { getRekapKeluhan } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
function ReactTables() {
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const [modal, setModal] = React.useState(false);
  //   const [project, setProject] = React.useState({});
  const [data, setData] = React.useState([]);
  const [dataKeluhan, setDataKeluhan] = React.useState([]);

  const history = useHistory();
  React.useEffect(() => {
    let tmp = monitoring.rekapKeluhan.map((val, index) => {
      return {
        ...val,
        tglDilaporkan: moment(val.createdAt).format("DD MMM YYYY / h:mm:ss a"),
        nomorHp: val.noHp,
        actions: (
          <div className="actions-right">
            {(val.status == "On Progress" &&
              Date.parse(new Date()) > Date.parse(val.deadlineDate)) ||
            (val.status == "Done" &&
              Date.parse(val.responDate) > Date.parse(val.deadlineDate)) ||
            (val.status == "B&U" &&
              Date.parse(val.responDate) > Date.parse(val.deadlineDate)) ||
            (val.status == "Project" &&
              Date.parse(val.responDate) > Date.parse(val.deadlineDate)) ? (
              <>
                <Button
                  onClick={() => {
                    //   setDataGedung(val);
                    setModal(true);
                    // downloadFile(val.filePendukung[0]);
                    setDataKeluhan(val);
                  }}
                  variant="danger"
                  size="md"
                  className="btn-link remove text-danger"
                >
                  <i className="fa fa-info-circle fa-lg" />
                </Button>{" "}
              </>
            ) : (val.status == "Done" &&
                Date.parse(val.responDate) <= Date.parse(val.deadlineDate)) ||
              (val.status == "B&U" &&
                Date.parse(val.responDate) <= Date.parse(val.deadlineDate)) ||
              (val.status == "Project" &&
                Date.parse(val.responDate) <= Date.parse(val.deadlineDate)) ? (
              <>
                <Button
                  onClick={() => {
                    //   setDataGedung(val);
                    setModal(true);
                    // downloadFile(val.filePendukung[0]);
                    setDataKeluhan(val);
                  }}
                  variant="info"
                  size="md"
                  className="btn-link remove text-info"
                >
                  <i className="fa fa-info-circle fa-lg" />
                </Button>{" "}
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    //   setDataGedung(val);
                    setModal(true);
                    // downloadFile(val.filePendukung[0]);
                    setDataKeluhan(val);
                  }}
                  variant="warning"
                  size="md"
                  className="btn-link remove text-warning"
                >
                  <i className="fa fa-info-circle fa-lg" />
                </Button>{" "}
              </>
            )}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [monitoring.rekapKeluhan]);

  React.useEffect(() => {
    getRekapKeluhan(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Complaining Report</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Building",
                      accessor: "gedung",
                    },
                    {
                      Header: "Area",
                      accessor: "area",
                    },
                    {
                      Header: "Name",
                      accessor: "nama",
                    },
                    {
                      Header: "Phone Number",
                      accessor: "nomorHp",
                    },
                    {
                      Header: "Reported Date",
                      accessor: "tglDilaporkan",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Name
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataKeluhan.nama}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Phone Number
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataKeluhan.noHp}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Building
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataKeluhan.gedung}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Area
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataKeluhan.area}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataKeluhan.deskripsi}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Reported Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataKeluhan.createdAt).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Deadline
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataKeluhan.datelineDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataKeluhan.status}</Col>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        User Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataKeluhan.dokumentasiAwalArr ||
                      dataKeluhan.dokumentasiAwalArr.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          User Documentation is Not Available
                        </Col>
                      ) : (
                        dataKeluhan.dokumentasiAwalArr.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                    {dataKeluhan.status == "On Progress" ? null : (
                      <>
                        <hr />
                        {/* <Row style={{ marginTop: 20 }}>
                          <Col
                            sm="12"
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                              fontSize: 20,
                            }}
                          >
                            Tindak Lanjut
                          </Col>
                        </Row> */}

                        {/* <Row style={{ marginTop: 15 }}>
                          <Col sm="6" style={{ fontWeight: "bold" }}>
                            Tanggal Penyelesaian
                          </Col>
                          <Col sm="0.5">:</Col>
                          <Col sm="5">
                            {!dataKeluhan.problem[0].solvedDate
                              ? "-"
                              : moment(
                                  dataKeluhan.problem[0].solvedDate
                                ).format("DD MMM YYYY / h:mm:ss a")}
                          </Col>
                        </Row> */}
                        <Row style={{ marginTop: 30 }}>
                          <Col
                            sm="12"
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Final Documentation
                          </Col>
                        </Row>
                        <Row>
                          {!dataKeluhan.dokumentasiAkhirArr ||
                          dataKeluhan.dokumentasiAkhirArr.length == 0 ? (
                            <Col sm="12" style={{ textAlign: "center" }}>
                              Final Documentation is Not Available
                            </Col>
                          ) : (
                            dataKeluhan.dokumentasiAkhirArr.map(
                              (val, index) => {
                                return (
                                  <>
                                    <Col sm="4">
                                      <Image
                                        src={val}
                                        style={{
                                          width: 200,
                                          height: 250,
                                          margin: 20,
                                        }}
                                        thumbnail
                                      />
                                    </Col>
                                  </>
                                );
                              }
                            )
                          )}
                        </Row>
                      </>
                    )}

                    {/* <Row>
                      {!dataKeluhan.dokumentasiAkhir ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Dokumentasi Akhir Belum Tersedia
                        </Col>
                      ) : (
                        dataKeluhan.dokumentasiAkhir[0].map((val, index) => {
                          return (
                            <>
                              <Col sm="6">
                                <Image
                                  src={val}
                                  style={{ width: 200, height: 300 }}
                                  thumbnail
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row> */}

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Buttons from "views/Components/Buttons.js";
import GridSystem from "views/Components/GridSystem.js";
import Panels from "views/Components/Panels.js";
import SweetAlert from "views/Components/SweetAlertPage.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Typography from "views/Components/Typography.js";
import RegularForms from "views/Forms/RegularForms.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Wizard from "views/Forms/Wizard/Wizard.js";
import RegularTables from "views/Tables/RegularTables.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import ReactTables from "views/Tables/ReactTables.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import VectorMap from "views/Maps/VectorMap.js";
import Charts from "views/Charts.js";
import Calendar from "views/Calendar.js";
import UserPage from "views/Pages/UserPage.js";

import LoginPage from "views/Pages/LoginPage.js";
import SettingGedungPage from "views/Pages/PatroliKebersihan/SettingGedung";
import SettingShiftPage from "views/Pages/PatroliKebersihan/SettingShift";
import RekapMonitoringPage from "views/Pages/PatroliKebersihan/RekapLaporan";
import SurveyFormPage from "views/Pages/SurveyForm";
import DashboardMonitoringPage from "views/Pages/PatroliKebersihan/DashboardMonitoring";
import DashboardUtamaPage from "views/DashboardUtama";
import DetailMonitoringKebersihan from "views/Pages/Kebersihan/DetailMonitoringKebersihan";
import TambahGedung from "views/Pages/Kebersihan/TambahGedung";
import SettingShift from "views/Pages/Kebersihan/SettingShift";
import TambahKategori from "views/Pages/Project/TambahKategori";
import PengajuanProject from "views/Pages/Project/PengajuanProject";
import VerifikasiProject from "views/Pages/Project/VerifyProject";
import CalendarProject from "views/Pages/Project/CalendarProject";
import RekapProblem from "views/Pages/Kebersihan/RekapProblem";
import RekapKeluhan from "views/Pages/Kebersihan/RekapKeluhan";
import RekapKunjungan from "views/Pages/Kebersihan/RekapKunjungan";
import RekapSurvey from "views/Pages/Survey/RekapSurvey";
import VariableSurvey from "views/Pages/Survey/VariableSurvey";
import SettingSurvey from "views/Pages/Survey/SurveyForm";
import KeluhanProgress from "views/Pages/KeluhanProgress";
import VerifikasiProposal from "views/Pages/Proposal/VerifikasiProposal";
import VerifikasiTraining from "views/Pages/TrainingPersonnel/Training";
import VerifikasiPembinaan from "views/Pages/Pembinaan/Pembinaan";
import DetailMonitoringPM from "views/Pages/Pembinaan/DetailMonitoringPM";
import VerifikasiPembinaanPenampilan from "views/Pages/PembinaanPenampilan/PembinaanPenampilan";
import DetailMonitoringPP from "views/Pages/PembinaanPenampilan/DetailMonitoringPP";
import VerifikasiVisit from "views/Pages/PestRodent/Visit";
import TemuanPestRodent from "views/Pages/PestRodent/TemuanPest";
import Safety from "views/Pages/Safety/Kejadian";
import Sidak from "views/Pages/Sidak/Sidak";
import Issue from "views/Pages/Sidak/Issue";
import ProjectIT from "views/Pages/IT/ProjectIt";
import KeluhanPengajuanPage from "views/Pages/KeluhanPengajuan";

import RekapConsumable from "views/Pages/Consumable/RekapOrder";
import PesanConsumable from "views/Pages/Consumable/Pemesanan";
import StockBAP from "views/Pages/ConsumableBAP/Stock";
import RekapOrder from "views/Pages/ConsumableBAP/OrderStock";
import VariableConsumable from "views/Pages/Consumable/DetailMonitoringCS";
import DetailMonitoringTP from "views/Pages/TrainingPersonnel/DetailMonitoringTP";
import DetailMonitoringPR from "views/Pages/PestRodent/DetailMonitoringPR";
import DetailMonitoringIT from "views/Pages/IT/DetailMonitoringIT";
import DetailMonitoringST from "views/Pages/Safety/DetailMonitoringST";
import DetailMonitoringSidak from "views/Pages/Sidak/DetailMonitoringSidak";
import Hardware from "views/Pages/IT/Hardware";
import Software from "views/Pages/IT/Software";
import CalendarTraining from "views/Pages/TrainingPersonnel/CalendarTraining";
import CalendarVisitPest from "views/Pages/PestRodent/CalendarVisitPest";
import CalendarSidak from "views/Pages/Sidak/CalendarSidak";
import AbsensiKaryawan from "views/Pages/TrainingPersonnel/AbsenKaryawan";
import OrderGudang from "views/Pages/Consumable/PemesananGudang";
import RekapWarehouse from "views/Pages/Consumable/RekapOrderGudang";
import DetailConsumableBAP from "views/Pages/ConsumableBAP/DetailConsumable";
import ListShift from "views/Pages/PelaporanShift/ListShift";
import CreateSoal from "views/Pages/PelaporanShift/CreateSoal";
import ListLaporan from "views/Pages/PelaporanShift/ListLaporan";
import DetailReportShift from "views/Pages/PelaporanShift/DetailShift";
import RekapPatroli from "views/Pages/Kebersihan/RekapPatroli";
import DetailPatroli from "views/Pages/Kebersihan/DetailPatroli";
import MonitoringAbsen from "views/Pages/Absen/MonitoringAbsen";
import DetailAbsen from "views/Pages/Absen/DetailAbsen";

import settingtarget from "views/Pages/Target/TambahTarget";
import TambahCabang from "views/Pages/Target/TambahCabang";
import SettingPersonnel from "views/Pages/Absen/SettingPersonnel";
import SettingPatroli from "views/Pages/Kebersihan/SettingPatroli";

import IncidentReport from "views/Pages/Incident/Incident";
import IncidentRecapitulation from "views/Pages/Incident/DetailIncident";
import ChartUniformChecklist from "views/Pages/UniformChecklist/DetailChecklist";
import UniformChecklistDetail from "views/Pages/UniformChecklist/Checklist";
import ListUniformChecklist from "views/Pages/UniformChecklist/AddChecklist";
import ChartMonthlyReport from "views/Pages/MonthlyReport/DetailMonthly";
import MonthlyReport from "views/Pages/MonthlyReport/MonthlyReport";
var routes = [
  // {
  //   show: true,
  //   path: "/dashboard",
  //   layout: "/admin",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-chart-pie-35",
  //   component: Dashboard,
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/components",
  //   name: "Components",
  //   state: "openComponents",
  //   icon: "nc-icon nc-app",
  //   views: [
  //     {
  //       show: true,
  //       path: "/buttons",
  //       layout: "/admin",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //     },
  //     {
  //       show: true,
  //       path: "/grid-system",
  //       layout: "/admin",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //     },
  //     {
  //       show: true,
  //       path: "/panels",
  //       layout: "/admin",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //     },
  //     {
  //       show: true,
  //       path: "/sweet-alert",
  //       layout: "/admin",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //     },
  //     {
  //       show: true,
  //       path: "/notifications",
  //       layout: "/admin",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //     },
  //     {
  //       show: true,
  //       path: "/icons",
  //       layout: "/admin",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //     },
  //     {
  //       show: true,
  //       path: "/typography",
  //       layout: "/admin",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/forms",
  //   name: "Forms",
  //   state: "openForms",
  //   icon: "nc-icon nc-notes",
  //   group: "Form",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-forms",
  //       layout: "/admin",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //       group: "Form",
  //     },
  //     {
  //       show: true,
  //       path: "/extended-forms",
  //       layout: "/admin",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //       group: "Form",
  //     },
  //     {
  //       show: true,
  //       path: "/validation-forms",
  //       layout: "/admin",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //       group: "Form",
  //     },
  //     {
  //       path: "/wizard",
  //       layout: "/admin",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //       group: "Form",
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/tables",
  //   name: "Tables",
  //   state: "openTables",
  //   icon: "nc-icon nc-paper-2",
  //   views: [
  //     {
  //       show: true,
  //       path: "/regular-tables",
  //       layout: "/admin",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //     },
  //     {
  //       show: true,
  //       path: "/extended-tables",
  //       layout: "/admin",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //     },
  //     {
  //       show: true,
  //       path: "/react-table",
  //       layout: "/admin",
  //       name: "React Table",
  //       mini: "RT",
  //       component: ReactTables,
  //     },
  //   ],
  // },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/maps",
  //   name: "Maps",
  //   state: "openMaps",
  //   icon: "nc-icon nc-pin-3",
  //   views: [
  //     {
  //       show: true,
  //       path: "/google-maps",
  //       layout: "/admin",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //     },
  //     {
  //       show: true,
  //       path: "/full-screen-maps",
  //       layout: "/admin",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //     },
  //     {
  //       show: true,
  //       path: "/vector-maps",
  //       layout: "/admin",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //     },
  //   ],
  // },
  // {
  //   path: "/charts",
  //   layout: "/admin",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  // },
  // {
  //   show: true,
  //   path: "/calendar",
  //   layout: "/admin",
  //   name: "Calendar",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: Calendar,
  // },
  // // Batas Template

  // {
  //   collapse: true,
  //   show: true,
  //   path: "/monitoringpatroli",
  //   name: "Patroli",
  //   state: "openPatroli",
  //   icon: "nc-icon nc-puzzle-10",
  //   group: "Patroli",
  //   views: [
  // {
  //   path: "/dashboardmonitoring",
  //   layout: "/admin",
  //   name: "Dashboard",
  //   mini: "D",
  //   component: DashboardMonitoringPage,
  // },
  // {
  //   path: "/rekapmonitoring",
  //   layout: "/admin",
  //   name: "Rekap Monitoring",
  //   mini: "RM",
  //   component: RekapMonitoringPage,
  // },
  // {
  //   path: "/settingareapatroli",
  //   layout: "/admin",
  //   show: true,
  //   name: "Setting Area Patroli",
  //   mini: "SA",
  //   component: RuangPatroli,
  //   group: "Patroli",
  // },
  // {
  //   path: "/settingshift",
  //   layout: "/admin",
  //   name: "Setting Shift",
  //   mini: "SS",
  //   component: SettingShiftPage,
  // },
  //   ],
  // },

  {
    show: true,
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: DashboardUtamaPage,
    group: "Dashboard",
  },

  // {
  //   show: true,
  //   collapse: true,
  //   path: "/proposal",
  //   name: "Proposal",
  //   state: "openc",
  //   icon: "fas fa-file-signature",
  //   views: [
  //     {
  //       show: true,
  //       path: "/verifikasiProposal",
  //       layout: "/admin",
  //       name: "Verifikasi Proposal",
  //       mini: "VP",
  //       component: VerifikasiProposal,
  //     },
  //   ],
  // },

  {
    show: true,
    collapse: true,
    path: "/settingArea",
    name: "Patrol",
    state: "openb",
    icon: "fas fa-shield-alt",
    group: "Setting Area",
    views: [
      {
        show: true,
        path: "/detailPatroli",
        layout: "/admin",
        name: "Patrol Recapitulation",
        mini: "PR",
        component: DetailPatroli,
        group: "Setting Area",
      },
      {
        show: true,
        path: "/reportPatroli",
        layout: "/admin",
        name: "Patrol Report",
        mini: "PR",
        component: RekapPatroli,
        group: "Setting Area",
      },
      {
        show: true,
        path: "/tambahGedung",
        layout: "/admin",
        name: "Building",
        mini: "B",
        component: TambahGedung,
        group: "Setting Area",
      },
      {
        show: true,
        path: "/settingpatroli",
        layout: "/admin",
        name: "Target Patroli",
        mini: "TP",
        component: SettingPatroli,
        group: "Setting Area",
      },
      {
        show: true,
        path: "/settingShift",
        layout: "/admin",
        name: "Setting Shift",
        mini: "SS",
        component: SettingShift,
        group: "Setting Area",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/settingArea",
    name: "Incident Report",
    state: "openn",
    icon: "fas fa-user-injured",
    group: "IncidentReport",
    views: [
      {
        show: true,
        path: "/incidentrecapitulation",
        layout: "/admin",
        name: "Incident Recapitulation",
        mini: "IR",
        component: IncidentRecapitulation,
        group: "IncidentReport",
      },
      {
        show: true,
        path: "/incidentreport",
        layout: "/admin",
        name: "Incident Report",
        mini: "IR",
        component: IncidentReport,
        group: "IncidentReport",
      },
    ],
  },

  {
    show: false,
    path: "/keluhan",
    layout: "/survey",
    name: "Keluhan",
    icon: "nc-icon nc-chart-pie-35",
    component: KeluhanPengajuanPage,
  },
  {
    show: true,
    collapse: true,
    path: "/kebersihan",
    name: "Cleaning Service",
    state: "opena",
    icon: "fas fa-broom",
    group: "Cleaning Service",
    views: [
      {
        show: true,
        path: "/detailMonitoring",
        layout: "/admin",
        name: "Cleaning Recapitulation",
        mini: "CR",
        component: DetailMonitoringKebersihan,
        group: "Cleaning Service",
      },
      {
        show: true,
        path: "/kunjunganGedung",
        layout: "/admin",
        name: "Visiting Report",
        mini: "VR",
        component: RekapKunjungan,
        group: "Cleaning Service",
      },
      {
        show: true,
        path: "/rekapProblem",
        layout: "/admin",
        name: "Problem Report",
        mini: "PR",
        component: RekapProblem,
        group: "Cleaning Service",
      },
      {
        show: true,
        path: "/rekapKeluhan",
        layout: "/admin",
        name: "Complaining Report",
        mini: "CR",
        component: RekapKeluhan,
        group: "Cleaning Service",
      },
      {
        path: "/verifyProject",
        layout: "/admin",
        name: "Project Verification",
        mini: "PV",
        component: VerifikasiProject,
        show: true,
        group: "Cleaning Service",
      },
      {
        path: "/calendarProject",
        layout: "/admin",
        name: "Project Schedule",
        mini: "PS",
        component: CalendarProject,
        show: true,
        group: "Cleaning Service",
      },
    ],
  },

  {
    show: true,
    collapse: true,
    path: "/trainingpersonnel",
    name: "Training Matrix",
    state: "opend",
    icon: "fas fa-user-tie",
    group: "Training",
    views: [
      {
        show: true,
        path: "/chartTraining",
        layout: "/admin",
        name: "Training Recapitulation",
        mini: "TR",
        component: DetailMonitoringTP,
        group: "Training",
      },
      // {
      //   show: true,
      //   path: "/absen",
      //   layout: "/admin",
      //   name: "Personnel Attendance",
      //   mini: "PA",
      //   component: AbsensiKaryawan,
      //   group: "Training",
      // },
      {
        show: true,
        path: "/training",
        layout: "/admin",
        name: "Training Detail",
        mini: "TD",
        component: VerifikasiTraining,
        group: "Training",
      },
      // {
      //   show: true,
      //   path: "/pembinaan",
      //   layout: "/admin",
      //   name: "Mentoring",
      //   mini: "M",
      //   component: VerifikasiPembinaan,
      //   group: "Training",
      // },
      {
        path: "/calendarTraining",
        layout: "/admin",
        name: "Training Plan",
        mini: "TP",
        component: CalendarTraining,
        show: true,
        group: "Training",
      },
    ],
  },

  {
    show: true,
    collapse: true,
    path: "/pembinaanpersonnel",
    name: "Coaching",
    state: "openk",
    icon: "fas fa-file-alt",
    group: "Pembinaan",
    views: [
      {
        show: true,
        path: "/chartPembinaan",
        layout: "/admin",
        name: "Coaching Recapitulation",
        mini: "MR",
        component: DetailMonitoringPM,
        group: "Pembinaan",
      },

      {
        show: true,
        path: "/pembinaan",
        layout: "/admin",
        name: "Coaching Rules",
        mini: "MR",
        component: VerifikasiPembinaan,
        group: "Pembinaan",
      },
    ],
  },
  // {
  //   show: true,
  //   collapse: true,
  //   path: "/pembinaanpenampilan",
  //   name: "Mentoring Perform",
  //   state: "openl",
  //   icon: "fas fa-tshirt",
  //   group: "PembinaanPenampilan",
  //   views: [
  //     {
  //       show: true,
  //       path: "/chartPembinaanPenampilan",
  //       layout: "/admin",
  //       name: "Mentoring Recapitulation",
  //       mini: "MR",
  //       component: DetailMonitoringPP,
  //       group: "PembinaanPenampilan",
  //     },

  //     {
  //       show: true,
  //       path: "/listpembinaanpenampilan",
  //       layout: "/admin",
  //       name: "Mentoring Appearance",
  //       mini: "MA",
  //       component: VerifikasiPembinaanPenampilan,
  //       group: "PembinaanPenampilan",
  //     },
  //   ],
  // },
  {
    show: true,
    collapse: true,
    path: "/pestrodent",
    name: "Pest & Rodent",
    state: "opene",
    icon: "fas fa-cat",
    group: "Pest",
    views: [
      {
        show: true,
        path: "/chartPest",
        layout: "/admin",
        name: "Pest & Rodent Recapitulation",
        mini: "PR",
        component: DetailMonitoringPR,
        group: "Pest",
      },
      {
        show: true,
        path: "/kunjunganPest",
        layout: "/admin",
        name: "Visitation",
        mini: "V",
        component: VerifikasiVisit,
        group: "Pest",
      },
      {
        show: true,
        path: "/temuanPest",
        layout: "/admin",
        name: "Finding",
        mini: "F",
        component: TemuanPestRodent,
        group: "Pest",
      },
      {
        path: "/calendarVisitPest",
        layout: "/admin",
        name: "Pest & Rodent Visiting Schedule",
        mini: "VS",
        component: CalendarVisitPest,
        show: true,
        group: "Pest",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/safety",
    name: "Safety",
    state: "openf",
    icon: "fas fa-hard-hat",
    group: "Safety",
    views: [
      {
        show: true,
        path: "/chartSafety",
        layout: "/admin",
        name: "Safety Recapitulation",
        mini: "SR",
        component: DetailMonitoringST,
        group: "Safety",
      },
      {
        show: true,
        path: "/kejadian",
        layout: "/admin",
        name: "Incident",
        mini: "I",
        component: Safety,
        group: "Safety",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/sidak",
    name: "Inspection & Manvis",
    state: "openg",
    icon: "fas fa-search",
    group: "Sidak",
    views: [
      {
        show: true,
        path: "/chartSidak",
        layout: "/admin",
        name: "Inspection & Manvis Recap",
        mini: "IR",
        component: DetailMonitoringSidak,
        group: "Sidak",
      },
      {
        show: true,
        path: "/kunjunganSidak",
        layout: "/admin",
        name: "Inspection & Manvis Detail",
        mini: "ID",
        component: Sidak,
        group: "Sidak",
      },
      // {
      //   show: true,
      //   path: "/issue",
      //   layout: "/admin",
      //   name: "Issue",
      //   mini: "IS",
      //   component: Issue,
      //   group: "Industrial",
      // },
      {
        path: "/calendarSidak",
        layout: "/admin",
        name: "Inspection & Manvis Plan",
        mini: "IP",
        component: CalendarSidak,
        show: true,
        group: "Sidak",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/report",
    name: "Monthly Report",
    state: "openi",
    icon: "fas fa-calendar-alt",
    group: "MonthlyReport",
    views: [
      {
        show: true,
        path: "/chartmonthlyreport",
        layout: "/admin",
        name: "Monthly Report Recap",
        mini: "MRR",
        component: ChartMonthlyReport,
        group: "MonthlyReport",
      },
      {
        show: true,
        path: "/monthlyreport",
        layout: "/admin",
        name: "Monthly Report Detail",
        mini: "MRD",
        component: MonthlyReport,
        group: "MonthlyReport",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/checklist",
    name: "Uniform Checklist",
    state: "openh",
    icon: "fas fa-list",
    group: "Checklist",
    views: [
      {
        show: true,
        path: "/chartChecklist",
        layout: "/admin",
        name: "Uniform Checklist Recap",
        mini: "UCR",
        component: ChartUniformChecklist,
        group: "Checklist",
      },
      {
        show: true,
        path: "/checklist",
        layout: "/admin",
        name: "Uniform Checklist Detail",
        mini: "UCD",
        component: UniformChecklistDetail,
        group: "Checklist",
      },
      // {
      //   show: true,
      //   path: "/issue",
      //   layout: "/admin",
      //   name: "Issue",
      //   mini: "IS",
      //   component: Issue,
      //   group: "Industrial",
      // },
      {
        path: "/listchecklist",
        layout: "/admin",
        name: "List Uniform Checklist",
        mini: "LUC",
        component: ListUniformChecklist,
        show: true,
        group: "Checklist",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/absen",
    name: "Attendance",
    state: "openm",
    icon: "fas fa-address-book",
    group: "Absen",
    views: [
      {
        show: true,
        path: "/chartAbsen",
        layout: "/admin",
        name: "Attendance Recapitulation",
        mini: "AR",
        component: MonitoringAbsen,
        group: "Absen",
      },
      {
        show: true,
        path: "/detailAbsen",
        layout: "/admin",
        name: "Attendace Detail",
        mini: "AD",
        component: DetailAbsen,
        group: "Absen",
      },
      {
        show: true,
        path: "/settingPersonnel",
        layout: "/admin",
        name: "Setting Personnel",
        mini: "SP",
        component: SettingPersonnel,
        group: "Absen",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/laporanshift",
    name: "Shift Report",
    state: "openj",
    icon: "fas fa-tasks",
    group: "Shift",
    views: [
      {
        show: true,
        path: "/chartShift",
        layout: "/admin",
        name: "Shift Recapitulation",
        mini: "SR",
        component: DetailReportShift,
        group: "Shift",
      },
      {
        show: true,
        path: "/listshiftlaporan",
        layout: "/admin",
        name: "Shift Report",
        mini: "SR",
        component: ListLaporan,
        group: "Shift",
      },
      {
        show: true,
        path: "/listshift",
        layout: "/admin",
        name: "Shift Plan",
        mini: "S",
        component: ListShift,
        group: "Shift",
      },
      // {
      //   show: true,
      //   path: "/soalshift",
      //   layout: "/admin",
      //   name: "Shift Question",
      //   mini: "SQ",
      //   component: CreateSoal,
      //   group: "Shift",
      // },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/target",
    name: "Setting",
    state: "openh",
    icon: "fas fa-cogs",
    group: "Setting Target",
    views: [
      {
        path: "/settingtarget",
        layout: "/admin",
        name: "Setting Target",
        mini: "ST",
        component: settingtarget,
        show: true,
        group: "Setting Target",
      },
      {
        path: "/addbranch",
        layout: "/admin",
        name: "Add Branch",
        mini: "AB",
        component: TambahCabang,
        show: true,
        group: "Setting Target",
      },
    ],
  },

  {
    show: true,
    collapse: true,
    path: "/safety",
    name: "IT",
    state: "openh",
    icon: "fas fa-mobile-alt",
    group: "IT",
    views: [
      {
        show: true,
        path: "/chartIT",
        layout: "/admin",
        name: "IT Recapitulation",
        mini: "RP",
        component: DetailMonitoringIT,
        group: "IT",
      },
      {
        show: true,
        path: "/project",
        layout: "/admin",
        name: "IT Project",
        mini: "IP",
        component: ProjectIT,
        group: "IT",
      },
      {
        show: true,
        path: "/hardware",
        layout: "/admin",
        name: "Hardware Problem",
        mini: "HP",
        component: Hardware,
        group: "IT",
      },
      {
        show: true,
        path: "/software",
        layout: "/admin",
        name: "Software Problem",
        mini: "SP",
        component: Software,
        group: "IT",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/survey-admin",
    name: "Survey",
    state: "opensurvey",
    icon: "fas fa-chart-line",
    group: "Survey",
    views: [
      {
        show: true,
        path: "/variable-survey",
        layout: "/admin",
        name: "Survey Variable",
        mini: "SV",
        component: VariableSurvey,
        group: "Survey",
      },
      {
        show: true,
        path: "/rekap-survey",
        layout: "/admin",
        name: "Survey Recapitulation",
        mini: "SR",
        component: RekapSurvey,
        group: "Survey",
      },
      {
        show: true,
        path: "/setting-survey",
        layout: "/admin",
        name: "Creating Survey",
        mini: "CS",
        component: SettingSurvey,
        group: "Survey",
      },
    ],
  },

  {
    show: true,
    collapse: true,
    path: "/consumablesupply",
    name: "Consumable",
    state: "openi",
    icon: "fas fa-box-open",
    group: "Consumable",
    views: [
      {
        show: true,
        path: "/variableconsumable",
        layout: "/admin",
        name: "Consumable Variable",
        mini: "CV",
        component: VariableConsumable,
        group: "Consumable",
      },
      {
        show: true,
        path: "/pemasanan",
        layout: "/admin",
        name: "Order",
        mini: "O",
        component: PesanConsumable,
        group: "Consumable",
      },
      {
        show: true,
        path: "/rekapconsumable",
        layout: "/admin",
        name: "Order Recapitulation",
        mini: "OR",
        component: RekapConsumable,
        group: "Consumable",
      },
      {
        show: true,
        path: "/pemesanangudang",
        layout: "/admin",
        name: "Warehouse",
        mini: "W",
        component: OrderGudang,
        group: "Consumable",
      },
      {
        show: true,
        path: "/rekapconsumablegudang",
        layout: "/admin",
        name: "Order Warehouse",
        mini: "OW",
        component: RekapWarehouse,
        group: "Consumable",
      },
    ],
  },
  {
    show: true,
    collapse: true,
    path: "/stockBAP",
    name: "Consumable BAP",
    state: "openc",
    icon: "fas fa-box-open",
    group: "Stock BAP",
    views: [
      {
        show: true,
        path: "/variableconsumablebap",
        layout: "/admin",
        name: "Consumable Variable",
        mini: "CV",
        component: DetailConsumableBAP,
        group: "Stock BAP",
      },
      {
        show: true,
        path: "/stock",
        layout: "/admin",
        name: "Stock",
        mini: "S",
        component: StockBAP,
        group: "Stock BAP",
      },
      {
        show: true,
        path: "/rekaporder",
        layout: "/admin",
        name: "Order Recapitulation",
        mini: "OR",
        component: RekapOrder,
        group: "Stock BAP",
      },
      // {
      //   show: true,
      //   path: "/pemesanangudang",
      //   layout: "/admin",
      //   name: "Warehouse",
      //   mini: "W",
      //   component: OrderGudang,
      //   group: "Stock BAP",
      // },
      // {
      //   show: true,
      //   path: "/rekapconsumablegudang",
      //   layout: "/admin",
      //   name: "Order Warehouse",
      //   mini: "OW",
      //   component: RekapWarehouse,
      //   group: "Stock BAP",
      // },
    ],
  },

  // {
  //   collapse: true,
  //   path: "/project",
  //   name: "Project",
  //   state: "openc",
  //   icon: "fas fa-tasks",
  //   show: true,
  //   views: [
  // {
  //   path: "/tambahKategori",
  //   layout: "/admin",
  //   name: "Tambah Kategori",
  //   mini: "TC",
  //   component: TambahKategori,
  //   show: true,
  // },
  // {
  //   path: "/pengajuanProject",
  //   layout: "/admin",
  //   name: "Pengajuan Project",
  //   mini: "AP",
  //   component: PengajuanProject,
  //   show: true,
  // },
  // {
  //   path: "/verifyProject",
  //   layout: "/admin",
  //   name: "Verifikasi Project",
  //   mini: "VP",
  //   component: VerifikasiProject,
  //   show: true,
  // },
  // {
  //   path: "/calendarProject",
  //   layout: "/admin",
  //   name: "Calendar Project",
  //   mini: "CP",
  //   component: CalendarProject,
  //   show: true,
  // },
  // ],
  // },
  // {
  //   collapse: true,
  //   path: "/facilityb",
  //   name: "Facility B",
  //   state: "openb",
  //   icon: "nc-icon nc-puzzle-10",
  //   views: [],
  // },

  {
    show: false,
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "nc-icon nc-puzzle-10",
    views: [
      // {
      //   path: "/user-page",
      //   layout: "/admin",
      //   name: "User Page",
      //   mini: "UP",
      //   component: UserPage,
      // },
      {
        show: false,
        path: "/login-page",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginPage,
      },
    ],
  },
  {
    show: false,
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openSurvey",
    icon: "nc-icon nc-puzzle-10",
    views: [
      {
        show: false,
        path: "/survey-form",
        layout: "/survey",
        name: "Survey",
        mini: "S",
        component: SurveyFormPage,
      },
      {
        show: false,
        path: "/keluhan-progress",
        layout: "/survey",
        name: "Survey",
        mini: "S",
        component: KeluhanProgress,
      },
    ],
  },
];
export default routes;

import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getCabangAktif,
  getPembinaan,
  verifikasiPembinaan,
  tambahMentoring,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const pembinaan = useSelector((state) => state.pembinaanReducer);
  const cabang = useSelector((state) => state.cabangReducer);

  const [modal, setModal] = React.useState(false);
  const [modalMentoring, setModalMentoring] = React.useState(false);
  const [dataPembinaan, setDataPembinaan] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [tindakan, setTindakan] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [name, setName] = React.useState("");
  const [jabatan, setJabatan] = React.useState("");
  const [noHp, setNoHp] = React.useState("");
  const [jenisPelanggaran, setJenisPelanggaran] = React.useState("");
  const [deskripsiMentoring, setDeskripsiMentoring] = React.useState("");
  const [gambar, setGambar] = React.useState([]);
  const [dariTgl, setDariTgl] = React.useState(new Date());
  let tglLaporan = new Date(dariTgl).toISOString();
  const [tglPelaksanaan, setTglPelaksanaan] = React.useState(new Date());
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [type, setType] = React.useState({
    value: "",
    label: "Choose the Type",
    isDisabled: true,
  });
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [listCabang, setListCabang] = React.useState([]);
  function submitMentoring(e) {
    e.preventDefault();
    tambahMentoring({
      name,
      jabatan,
      noHp,
      jenisPelanggaran,
      deskripsiMentoring,
      tglLaporan,
      idClient: client.idClient,
      idCabang: client.idCabang,
      // type: type.value,
    });
    // tambahTraining({
    //   jenisTraining: jenis,
    //   deskripsi: deskripsiTraining,
    //   startDate: new Date(dariTgl).toISOString(),
    //   deadlineDate: new Date(smpTgl).toISOString(),
    // });
  }

  function onsubmit(e) {
    e.preventDefault();
    let fd = new FormData();
    fd.append("pembinaanId", dataPembinaan._id);
    fd.append("kategori", tindakan);
    fd.append("alasan", deskripsi);
    fd.append("tglPelaksanaan", tglPelaksanaan);
    gambar.map((val, index) => {
      fd.append("buktiSelesai", val);
    });
    verifikasiPembinaan(fd);
  }

  React.useEffect(() => {
    getCabangAktif(dispatch);
  }, []);

  const validDate = (current) => {
    // Hanya izinkan tanggal hari ini dan setelahnya
    return current.isSameOrAfter(new Date(), "day");
  };
  function handleOpenModal(e) {
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setJenisPelanggaran({
      value: "",
      label: "Choose the Type Violation",
      isDisabled: true,
    });
    setName("");
    setNoHp("");
    setJabatan("");
    setDeskripsi("");
    setDariTgl(new Date());
  }
  // console.log(monitoring.listProjectAll);
  const history = useHistory();
  React.useEffect(() => {
    let tmp = pembinaan.listPembinaan.map((val, index) => {
      return {
        ...val,
        kategory: val.kategori == undefined ? "-" : val.kategori,
        tglPembinaan: moment(val.startDate).format("DD MMM YYYY / h:mm:ss a"),
        nomorHp: 0 + val.noHp,
        actions:
          // we've added some custom button actions
          val.status == "Waiting for Approval" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataPembinaan(val);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>{" "}
            </div>
          ) : val.status == "Below" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataPembinaan(val);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>
              {/* <Button
                  onClick={() => {
                    setModal(true);
                    setDataPembinaan(val);
                  }}
                  variant="success"
                  size="md"
                  className="btn-link remove text-success"
                >
                  <i className="fa fa-info-circle fa-lg" />
                </Button> */}
            </div>
          ) : val.status == "Meet" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataPembinaan(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataPembinaan(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>
            </div>
          ),
      };
    });
    setData(tmp);
  }, [pembinaan.listPembinaan]);
  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);

  React.useEffect(() => {
    getPembinaan(dispatch);
  }, []);
  return (
    <>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalMentoring(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add Coaching
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Coaching</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Name of Violation",
                      accessor: "namaPelanggar",
                    },
                    {
                      Header: "Type of Violation",
                      accessor: "jenisPelanggaran",
                    },
                    {
                      Header: "Category",
                      accessor: "kategory",
                    },

                    {
                      Header: "Position",
                      accessor: "jabatan",
                    },
                    {
                      Header: "Phone Number",
                      accessor: "nomorHp",
                    },
                    {
                      Header: "Coaching Date",
                      accessor: "tglPembinaan",
                    },
                    {
                      Header: "Submitter",
                      accessor: "diajukanOleh",
                    },
                    {
                      Header: "Branch",
                      accessor: "namaCabang",
                    },
                    {
                      Header: "Client",
                      accessor: "namaClient",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
          setGambar([]);
          setTglPelaksanaan(new Date());
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataPembinaan.status == "Waiting for Approval" ? (
            <>
              <Row>
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <label>Implimentation Date</label>
                      <Form.Group>
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: "Date Picker Here",
                          }}
                          onChange={(e) => {
                            setTglPelaksanaan(e.toDate());
                          }}
                          value={tglPelaksanaan}
                          initialValue={tglPelaksanaan}
                          isValidDate={validDate}
                          timeFormat={true}
                        ></ReactDatetime>
                      </Form.Group>
                      <Form.Group>
                        <label>Follow-Up</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={tindakan.val}
                          onChange={(val) => setTindakan(val.value)}
                          options={[
                            {
                              value: "Teguran Tertulis",
                              label: "Teguran Tertulis",
                            },
                            { value: "SP1", label: "SP1" },
                            { value: "SP2", label: "SP2" },
                            { value: "SP3", label: "SP3" },
                            { value: "PHK", label: "PHK" },
                          ]}
                          placeholder="-Choose-"
                        />
                      </Form.Group>

                      <Form.Group>
                        <label>Description</label>
                        <Form.Control
                          onChange={(e) => {
                            setDeskripsi(e.target.value);
                            // setProject({
                            //   ...project,
                            //   deskripsi: e.target.value,
                            // });
                          }}
                          // placeholder="Tuliskan Deskripsi Project"
                          as="textarea"
                        ></Form.Control>
                      </Form.Group>
                      <label
                        className="label-input-file btn btn-danger text-white mb-4"
                        for="import2"
                      >
                        Add Photo
                        <input
                          type="file"
                          id="import2"
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                          onChange={(e) => {
                            setGambar([
                              ...gambar,
                              ...Array.from(e.target.files),
                            ]);
                          }}
                          style={{ display: "none" }}
                          accept="image/*"
                          multiple
                        />
                      </label>
                      {gambar.map((val, index) => {
                        return (
                          <div className="d-flex align-items-start">
                            <p>{val.name}</p>
                            <Button
                              onClick={() => {
                                setGambar([
                                  ...gambar.slice(0, index),
                                  ...gambar.slice(index + 1, gambar.length),
                                ]);
                              }}
                              variant="danger"
                              size="sm"
                              className="btn-link remove text-danger"
                            >
                              <i className="fa fa-times" />
                            </Button>
                          </div>
                        );
                      })}
                      <p>
                        <Button
                          className="btn-fill pull-right"
                          type="submit"
                          variant="info"
                          onClick={onsubmit}
                        >
                          Submit
                        </Button>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          ) : null}
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Name of Violation
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataPembinaan.namaPelanggar}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Type of Violation
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataPembinaan.jenisPelanggaran}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Position
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataPembinaan.jabatan}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Phone Number
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{0 + dataPembinaan.noHp}</Col>
                    </Row>

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Submitter
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataPembinaan.diajukanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Branch
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataPembinaan.namaCabang}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Client
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataPembinaan.namaClient}</Col>
                    </Row>

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataPembinaan.deskripsi}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Coaching Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataPembinaan.startDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Deadline
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataPembinaan.deadlineDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Responded By
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!dataPembinaan.diresponOleh
                          ? "-"
                          : dataPembinaan.diresponOleh}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Solve Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!dataPembinaan.solvedDate
                          ? "-"
                          : moment(dataPembinaan.solvedDate).format(
                              "DD MMM YYYY / h:mm:ss a"
                            )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataPembinaan.status}</Col>
                    </Row>

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        On Schedule
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataPembinaan.status === "Waiting for Approval"
                          ? "-"
                          : dataPembinaan.solvedInTime
                          ? "Ya"
                          : "Tidak"}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Note
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!dataPembinaan.alasan ? "-" : dataPembinaan.alasan}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Initital Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataPembinaan.buktiPengajuan ||
                      dataPembinaan.buktiPengajuan.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Initital Documentation is Not Available
                        </Col>
                      ) : (
                        dataPembinaan.buktiPengajuan.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                  onClick={() => {
                                    setModalPreviewFoto(val);
                                  }}
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Final Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataPembinaan.buktiSelesai ||
                      dataPembinaan.buktiSelesai.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Final Documentation is Not Available
                        </Col>
                      ) : (
                        dataPembinaan.buktiSelesai.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                  onClick={() => {
                                    setModalPreviewFoto(val);
                                  }}
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalMentoring}
        onHide={() => {
          setModalMentoring(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Coaching</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                        <Form.Group>
                          <label>Type of Violation</label>
                          <Form.Control
                            onChange={(e) => {
                              setJenisPelanggaran(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group>
                          <label>Employee name </label>
                          <Form.Control
                            onChange={(e) => {
                              setName(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Position</label>
                          <Form.Control
                            onChange={(e) => {
                              setJabatan(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>No Hp</label>
                          <Form.Control
                            onChange={(e) => {
                              setNoHp(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Type of violation</label>
                          <Form.Control
                            onChange={(e) => {
                              setJenisPelanggaran(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            onChange={(e) => {
                              setDeskripsiMentoring(e.target.value);
                              // setProject({
                              //   ...project,
                              //   deskripsi: e.target.value,
                              // });
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <label>Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitMentoring}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

const initialState = {
  getTargetByMonth: [],
  getTargetByMonthCabang: [],
  getTargetAll: [],
  getAchievementPatroli: [],
  getAchievementShift: [],
};

const targetReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_GETTARGETBYMONTH":
      return {
        ...state,
        getTargetByMonth: data,
      };

    case "SET_GETTARGETBYMONTHCABANG":
      return {
        ...state,
        getTargetByMonthCabang: data,
      };
    case "SET_GETTARGETALL":
      return {
        ...state,
        getTargetAll: data,
      };
    case "SET_GETACHIEVEMENTPATROLI":
      return {
        ...state,
        getAchievementPatroli: data,
      };
    case "SET_GETACHIEVEMENTSHIFT":
      return {
        ...state,
        getAchievementShift: data,
      };
    default:
      return state;
  }
};

export default targetReducer;

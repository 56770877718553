import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { getRekapProblem } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import { CardBody } from "reactstrap";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const [modal, setModal] = React.useState(false);
  //   const [project, setProject] = React.useState({});
  const [data, setData] = React.useState([]);
  const [dataProblem, setDataProblem] = React.useState([]);
  const history = useHistory();
  React.useEffect(() => {
    let tmp = monitoring.rekapProblem.map((val, index) => {
      return {
        ...val,
        tglLaporan: moment(val.createdAt).format("DD MMM YYYY / h:mm:ss a"),

        // tglPenyelesaian: val.statusSolved
        //   ? moment(val.solvedDate).format("DD MMM YYYY / h:mm:ss a")
        //   : "-",

        actions: (
          <div className="actions-right">
            {(val.status == "On Progress" &&
              Date.parse(new Date()) > Date.parse(val.deadlineDate)) ||
            (val.status == "Done" &&
              Date.parse(val.responDate) > Date.parse(val.deadlineDate)) ||
            (val.status == "B&U" &&
              Date.parse(val.responDate) > Date.parse(val.deadlineDate)) ||
            (val.status == "Project" &&
              Date.parse(val.responDate) > Date.parse(val.deadlineDate)) ? (
              <>
                <Button
                  onClick={() => {
                    //   setDataGedung(val);
                    setModal(true);
                    // downloadFile(val.filePendukung[0]);
                    setDataProblem(val);
                  }}
                  variant="danger"
                  size="md"
                  className="btn-link remove text-danger"
                >
                  <i className="fa fa-info-circle fa-lg" />
                </Button>{" "}
              </>
            ) : (val.status == "Done" &&
                Date.parse(val.responDate) <= Date.parse(val.deadlineDate)) ||
              (val.status == "B&U" &&
                Date.parse(val.responDate) <= Date.parse(val.deadlineDate)) ||
              (val.status == "Project" &&
                Date.parse(val.responDate) <= Date.parse(val.deadlineDate)) ? (
              <>
                <Button
                  onClick={() => {
                    //   setDataGedung(val);
                    setModal(true);
                    // downloadFile(val.filePendukung[0]);
                    setDataProblem(val);
                  }}
                  variant="info"
                  size="md"
                  className="btn-link remove text-info"
                >
                  <i className="fa fa-info-circle fa-lg" />
                </Button>{" "}
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    //   setDataGedung(val);
                    setModal(true);
                    // downloadFile(val.filePendukung[0]);
                    setDataProblem(val);
                  }}
                  variant="warning"
                  size="md"
                  className="btn-link remove text-warning"
                >
                  <i className="fa fa-info-circle fa-lg" />
                </Button>{" "}
              </>
            )}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [monitoring.rekapProblem]);

  React.useEffect(() => {
    getRekapProblem(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Problem Report</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Building",
                      accessor: "gedung",
                    },
                    {
                      Header: "Area",
                      accessor: "area",
                    },
                    {
                      Header: "Problem",
                      accessor: "title",
                    },
                    {
                      Header: "Reported Date",
                      accessor: "tglLaporan",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail Problem
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  {/* <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Detail Problem</Card.Title>
                    </Card.Header>
                  </Card.Header> */}
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Building
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataProblem.gedung}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Area
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataProblem.area}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Problem
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataProblem.title}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataProblem.deskripsi}</Col>
                    </Row>

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Reported Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataProblem.createdAt).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataProblem.status}</Col>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Previous Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataProblem.dokumentasiAwalArr ||
                      dataProblem.dokumentasiAwalArr.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Previous Documentation is Not Available
                        </Col>
                      ) : (
                        dataProblem.dokumentasiAwalArr.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                    {dataProblem.status == "On Progress" ? null : (
                      <>
                        <hr />
                        <Row style={{ marginTop: 30 }}>
                          <Col
                            sm="12"
                            style={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Final Documentation
                          </Col>
                        </Row>
                        <Row>
                          {!dataProblem.dokumentasiAkhirArr ||
                          dataProblem.dokumentasiAkhirArr.length == 0 ? (
                            <Col sm="12" style={{ textAlign: "center" }}>
                              Final Documentation is Not Available
                            </Col>
                          ) : (
                            dataProblem.dokumentasiAkhirArr.map(
                              (val, index) => {
                                return (
                                  <>
                                    <Col sm="4">
                                      <Image
                                        src={val}
                                        style={{
                                          width: 200,
                                          height: 250,
                                          margin: 20,
                                        }}
                                        thumbnail
                                      />
                                    </Col>
                                  </>
                                );
                              }
                            )
                          )}
                        </Row>
                      </>
                    )}

                    {/* <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        setModal(false);
                        e.preventDefault();
                      }}
                    >
                      Close
                    </Button> */}
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

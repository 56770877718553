import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { getGrafikAbsen, getCabangAktif } from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const absen = useSelector((state) => state.absenReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const auth = useSelector((state) => state.authReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(new Date().setHours(0, 0, 0, 0));
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));
  const [kpi1Option, setKpi1Option] = React.useState(null);
  const [listCabang, setListCabang] = React.useState([]);

  const [idCabang, setIdCabang] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  React.useEffect(() => {
    if (absen.kpiAbsen) {
      setKpi1Option(absen.kpiAbsen.data);
      console.log(absen.kpiAbsen.data, ">>>>>>>>>>>>");
    }
  }, [absen.kpiAbsen]);
  React.useEffect(() => {
    getCabangAktif(dispatch);
  }, []);
  React.useEffect(() => {
    getGrafikAbsen(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: idCabang.idCabang,
    });
  }, [dariTgl, smpTgl, idCabang]);
  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);
  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        {/* Baris Satu */}

        {absen.kpiAbsen && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Row>
                    <Col md="6">
                      <Card.Title as="h4">Attendance Achievement</Card.Title>
                    </Col>
                    {auth.role === "all" ||
                    auth.role === "gsc" ||
                    auth.role === "market" ? (
                      <>
                        <Col md="6">
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setIdCabang(value);
                            }}
                            value={idCabang}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {absen.kpiAbsen.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {absen.kpiAbsen.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi1Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={kpi1Option}>
                              <XAxis dataKey="nama" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="presence"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="sick"
                                barSize={20}
                                fill="#4287f5"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="leave_permission"
                                barSize={20}
                                fill="#6219e0"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="absence"
                                barSize={20}
                                fill="#ff002f"
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/detailAbsen");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          absen.kpiAbsen.pencapaian < absen.kpiAbsen.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default Panels;

import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { getRekapSurvey } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
function ReactTables() {
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.surveyReducer);
  //   const [modal, setModal] = React.useState(false);
  //   const [project, setProject] = React.useState({});
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    let tmp = survey.rekapkuisioner.map((val, index) => {
      return {
        ...val,
        createdAt: moment(val.createdAt).format("DD MMM YYYY"),
        actions: (
          <div className="actions-right">
            {/* <Button
              onClick={() => {
                  setDataGedung(val);
                  setModalRuangan(true);
                downloadFile(val.filePendukung[0]);
                console.log(val._id);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fa fa-info-circle fa-lg" />
            </Button>{" "} */}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [survey.rekapkuisioner]);
  React.useEffect(() => {
    getRekapSurvey(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Survey Recapitulation</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Score",
                      accessor: "score",
                    },
                    {
                      Header: "Rating",
                      accessor: "bobotTotal",
                    },
                    {
                      Header: "Submit Date",
                      accessor: "createdAt",
                    },

                    {
                      Header: "",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      > */}
      {/* <Modal.Header closeButton> */}
      {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
      {/* </Modal.Header> */}
      {/* <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Pengajuan Project</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Kategori</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={
                              !project.category
                                ? project.category
                                : project.category.val
                            }
                            onChange={(val) =>
                              setProject({
                                ...project,
                                category: val.label,
                              })
                            }
                            options={monitoring.listKategori.map(
                              (val, index) => {
                                return {
                                  value: val.jenis,
                                  label: val.jenis,
                                };
                              }
                            )}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                        <FormGroup>
                          <label>Judul</label>
                          <Form.Control
                            onChange={(e) => {
                              setProject({
                                ...project,
                                title: e.target.value,
                              });
                            }}
                            placeholder="Masukan Judul Project"
                            type="text"
                          ></Form.Control>
                        </FormGroup>
                        <FormGroup>
                          <label>Deskripsi</label>
                          <Form.Control
                            onChange={(e) => {
                              setProject({
                                ...project,
                                deskripsi: e.target.value,
                              });
                            }}
                            placeholder="Tuliskan Deskripsi Project"
                            type="text"
                          ></Form.Control>
                        </FormGroup>
                        <FormGroup>
                          <label>Tanggal Mulai Project</label>
                          <ReactDatetime
                            dateFormat="DD MMMM YYYY"
                            value={project.startDate}
                            onChange={(e) => {
                              setProject({
                                ...project,
                                startDate: e.toDate().toDateString(),
                              });
                            }}
                            inputProps={{
                              className: "form-control",
                              placeholder: "Pilih tanggal mulai",
                            }}
                            timeFormat={false}
                          ></ReactDatetime>
                        </FormGroup>
                        <FormGroup>
                          <label>Tanggal Akhir Project</label>
                          <ReactDatetime
                            dateFormat="DD MMMM YYYY"
                            value={project.deadlineDate}
                            onChange={(e) => {
                              setProject({
                                ...project,
                                deadlineDate: e.toDate().toDateString(),
                              });
                            }}
                            inputProps={{
                              className: "form-control",
                              placeholder: "Pilih tanggal akhir",
                            }}
                            timeFormat={false}
                          ></ReactDatetime>
                        </FormGroup>
                        <FormGroup>
                          <label>File Pendukung</label>
                          <Form.Control
                            onChange={(e) => {
                              setProject({
                                ...project,
                                dokumen: e.target.files[0],
                              });
                            }}
                            type="file"
                          ></Form.Control>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        pengajuanProject(fd);
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body> */}
      {/* </Modal> */}
    </>
  );
}

export default ReactTables;

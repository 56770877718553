import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getVisit(dispatch) {
  baseAxios
    .get(`/kunjunganpest/getvisitall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTVISIT", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiVisit(data) {
  baseAxios
    .patch(`/kunjunganpest/responvisit`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi1KunjunganPest(dispatch, data) {
  baseAxios
    .get(
      `/kunjunganpest/getGrafikKunjunganPest?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI1KUNJUNGAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function getTemuanPest(dispatch) {
  baseAxios
    .get(`/temuanpest/gettemuanpestall`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTTEMUANPEST", data: respon.data });
    })
    .catch(errorHandler);
}

export function verifikasiTemuanPest(data) {
  baseAxios
    .patch(`/temuanpest/respontemuanpest`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getKpi2TemuanPest(dispatch, data) {
  baseAxios
    .get(
      `/temuanpest/gettemuanpestgrafik?dari=${data.dariTgl}&sampai=${data.smpTgl}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPI2TEMUAN", data: respon.data });
    })
    .catch(errorHandler);
}

export function tambahVisit(data) {
  console.log(data);
  baseAxios
    .post(`/kunjunganpest/ajukanvisit`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function editVisit(data) {
  console.log(data);
  baseAxios
    .patch(`/kunjunganpest/ajukanvisitulang`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getCalendarVisitPest(dispatch) {
  baseAxios
    .get("/kunjunganpest/getcalendarvisitpest", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_CALENDAR", data: respon.data });
    })
    .catch(errorHandler);
}

const initialState = {
  listTraining: [],
  listPembinaan: [],
  kpi1: null,
  kpi2: null,
  calendar: [],
  listAbsensi: [],
  kpi3: null,
};

const trainingReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_LISTTRAINING":
      return {
        ...state,
        listTraining: data,
      };
    case "SET_KPI1TRAINING":
      return {
        ...state,
        kpi1: data,
      };
    case "SET_LISTPEMBINAAN":
      return {
        ...state,
        listPembinaan: data,
      };
    case "SET_KPI2PEMBINAAN":
      return {
        ...state,
        kpi2: data,
      };
    case "SET_CALENDAR":
      return {
        ...state,
        calendar: data,
      };
    case "SET_LISTABSENSI":
      return {
        ...state,
        listAbsensi: data,
      };
    case "SET_KPI3ABSENSI":
      return {
        ...state,
        kpi3: data,
      };

    default:
      return state;
  }
};

export default trainingReducer;

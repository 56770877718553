import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import Chart from "react-apexcharts";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { getKpiMonitoringSurvey } from "../../../stores";
import { useHistory } from "react-router-dom";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const survey = useSelector((state) => state.surveyReducer);
  const [filter, setFilter] = React.useState({
    tahun: new Date().getFullYear(),
  });

  const [kpi1Option, setKpi1Option] = React.useState(null);
  React.useEffect(() => {
    if (survey.variablesurvey) {
      setKpi1Option(survey.variablesurvey.data);
    }
  }, [survey.variablesurvey]);
  React.useEffect(() => {
    getKpiMonitoringSurvey(dispatch, filter);
  }, [filter]);

  // console.log(survey.variablesurvey.pencapaian);
  return (
    <>
      <Container fluid>
        {survey.variablesurvey && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Survey Achievement</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {survey.variablesurvey.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {" "}
                            {survey.variablesurvey.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi1Option && (
                        <ComposedChart
                          width={730}
                          height={250}
                          data={kpi1Option}
                        >
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} />
                          <CartesianGrid stroke="#f5f5f5" />
                          <Bar dataKey="Score" barSize={20} fill="#ff002f">
                            <LabelList dataKey="Score" position="top" />
                          </Bar>
                          <Bar
                            dataKey="Total Bobot"
                            barSize={20}
                            fill="#0ed907"
                          >
                            <LabelList dataKey="Total Bobot" position="top" />
                          </Bar>
                          <Bar
                            dataKey="Participant"
                            barSize={20}
                            fill="#e0ac10"
                          >
                            <LabelList dataKey="Participant" position="top" />
                          </Bar>
                        </ComposedChart>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/rekap-survey");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          survey.variablesurvey.pencapaian <
                          survey.variablesurvey.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}

        {/* end row */}
      </Container>
    </>
  );
}

export default Panels;

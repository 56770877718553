import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  Tab,
  InputGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import {
  getPatroliReport,
  getAllCabang,
  getCabangAktif,
  exportPatroli,
  // getShiftDropdown,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import { CardBody } from "reactstrap";
import moment from "moment";
// CSS Pagination
import "./Pagination.css";
// Loading Table
import { ColorRing } from "react-loader-spinner";
import Swal from "sweetalert2";

function ReactTables() {
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const auth = useSelector((state) => state.authReducer);

  // console.log(monitoring, "LLLL");
  const [modal, setModal] = React.useState(false);
  //   const [project, setProject] = React.useState({});
  const [data, setData] = React.useState([]);
  const [dataProblem, setDataProblem] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const history = useHistory();
  const [detailPatroli, setDataPatroli] = React.useState([]);
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [listCabang, setListCabang] = React.useState([]);
  const [idCabang, setIdCabang] = React.useState({
    value: "",
    label: "ALL",
    // isDisabled: true,
  });
  const [shift, setListShift] = React.useState({
    value: "",
    label: "ALL",
  });
  const date = new Date();

  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );

  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));
  // Pagination
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleFilterChange = () => {
    setLoading(true);
    getPatroliReport(dispatch, {
      page: 1,
      limit: totalPerHalaman,
      cari: searchText,
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabang: idCabang.value,
      shift: shift.value,
    })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memuat data.",
        });
      });
  };

  React.useEffect(() => {
    getAllCabang(dispatch);
    getCabangAktif(dispatch);
  }, []);
  // React.useEffect(() => {
  //   dispatch, { idCabang: idCabang.value };
  // }, [idCabang]);
  React.useEffect(() => {
    setLoading(true);
    getPatroliReport(dispatch, {
      page: page + 1,
      limit: totalPerHalaman,
      cari: searchText,
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabang: idCabang.value,
      shift: shift.value,
    }).then((response) => {
      setLoading(false);
    });
  }, [totalPerHalaman, page, dariTgl, smpTgl, idCabang, shift]);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        // ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });

    setListCabang(tmp);
  }, [cabang.getCabangAktif]);

  React.useEffect(() => {
    let tmp =
      monitoring.listReportPatroli.data &&
      monitoring.listReportPatroli.data.map((val, index) => {
        return {
          ...val,
          tglLaporan: moment(val.createdAt).format("DD MMM YYYY"),
          waktuLaporan: moment(val.createdAt).format("HH:mm"),
          status: val.inTime ? "Valid" : "Not Valid",
          actions: val.inTime ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataPatroli(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  // console.log(val);
                  setDataPatroli(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
        };
      });
    setData(tmp);
  }, [monitoring.listReportPatroli]);

  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const listShiftPelem = [
    { value: "Shift 1 Ke-1", label: "Shift 1 Ke-1" },
    { value: "Shift 1 Ke-2", label: "Shift 1 Ke-2" },
    { value: "Shift 1 Ke-3", label: "Shift 1 Ke-3" },
    { value: "Shift 2 Ke-1", label: "Shift 2 Ke-1" },
    { value: "Shift 2 Ke-2", label: "Shift 2 Ke-2" },
    { value: "Shift 2 Ke-3", label: "Shift 2 Ke-3" },
    { value: "Shift 3 Ke-1", label: "Shift 3 Ke-1" },
    { value: "Shift 3 Ke-2", label: "Shift 3 Ke-2" },
    { value: "Shift 3 Ke-3", label: "Shift 3 Ke-3" },
  ];
  const listPerShift = [
    { value: "Shift 1", label: "Shift 1" },
    { value: "Shift 2", label: "Shift 2" },
    { value: "Shift 3", label: "Shift 3" },
  ];
  const cabangPilihan = () => {
    if (idCabang.value === "") {
      return [...listPerShift, ...listShiftPelem];
    } else if (idCabang.value === "2012") {
      return listShiftPelem;
    } else {
      return listPerShift;
    }
  };
  const cabangDilipih = cabangPilihan();

  const colPencairan = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) =>
        index + monitoring.listReportPatroli.pagingCounter,
    },
    {
      name: "Building",
      minWidth: "300px",
      center: true,
      selector: (row) => row.area,
    },
    {
      name: "Shift",
      minWidth: "100px",
      center: true,
      selector: (row) => row.namaShift,
    },
    {
      name: "Name",
      minWidth: "200px",
      center: true,
      selector: (row) => row.dilaporkanOleh,
    },
    {
      name: "Reported Date",
      minWidth: "150px",
      center: true,
      selector: (row) => row.tglLaporan,
    },
    {
      name: "Reported Time",
      minWidth: "150px",
      center: true,
      selector: (row) => row.waktuLaporan,
    },
    {
      name: "Client Name",
      minWidth: "150px",
      center: true,
      selector: (row) => row.namaClient,
    },
    {
      name: "Branch Name",
      minWidth: "150px",
      center: true,
      selector: (row) => row.namaCabang,
    },
    {
      name: "Status",
      minWidth: "150px",
      center: true,
      sortable: true,
      selector: (row) => row.status,
    },
    {
      name: "Action",
      minWidth: "150px",
      center: true,
      selector: (row) => row.actions,
    },
  ];
  function openModalExport() {
    Swal.fire({
      title: "Apakah anda yakin akan mengekspor data patroli?",
      html: `
      <table style="width: 100%;">
           <tr>
             <td style="text-align: left; width: 100px;">Start Date</td>
             <td>:</td>
             <td style="text-align: left;">${moment(new Date(dariTgl)).format(
               "DD-MM-YYYY"
             )}</td>
           </tr>
           <tr>
             <td style="text-align: left;">End Date</td>
             <td>:</td>
             <td style="text-align: left;">${moment(new Date(smpTgl)).format(
               "DD-MM-YYYY"
             )}</td>
           </tr>
           <tr>
             <td style="text-align: left;">Branch</td>
             <td>:</td>
             <td style="text-align: left;">${idCabang.label}</td>
           </tr>
           <tr>
             <td style="text-align: left;">Shift</td>
             <td>:</td>
             <td style="text-align: left;">${shift.label}</td>
           </tr>
         </table> `,
      icon: "question",
      showCancelButton: true,
      preConfirm: async () => {
        try {
          const data = await exportPatroli({
            dariTgl: dariTgl,
            smpTgl: smpTgl,
            cabang: idCabang.value,
            shift: shift.value,
          });
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Export data patroli ${moment(new Date(dariTgl)).format(
              "DD-MM-YYYY"
            )} sampai ${moment(new Date(smpTgl)).format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
          });
        } catch (error) {
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  }
  return (
    <>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 0,
                // backgroundColor: "blue",
              }}
            >
              <Col>
                <h4 className="title">Patroli Report</h4>
              </Col>

              {/* <div>
                <Button
                  // variant="info"
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    backgroundColor: "#4c6fc8",
                    border: 0,
                    textAlign: "center",
                    marginTop: 20,
                  }}
                  onClick={openModalExport}
                >
                  <i class="fas fa-file-excel"></i>
                  Export Data
                </Button>
              </div> */}
            </Row>

            <Card style={{ backgroundColor: "white" }}>
              <Card.Header>
                <Tab.Content>
                  {auth.role === "all" ||
                  auth.role === "gsc" ||
                  auth.role === "market" ? (
                    <>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // padding: "0 15px",
                        }}
                      >
                        <Col md="3">
                          <label
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                              fontSize: 12,
                              color: "black",
                            }}
                          >
                            {" "}
                            Start Date
                          </label>
                          <Form.Group>
                            <ReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "Date Picker Here",
                                readOnly: true,
                                style: {
                                  cursor: "default",
                                  backgroundColor: "white",
                                  color: "black !important",
                                },
                              }}
                              onChange={(e) => {
                                setDariTgl(e.toDate().getTime());
                              }}
                              value={dariTgl}
                              // initialValue={dariTgl}
                              timeFormat={false}
                              dateFormat="DD MMM YYYY"
                            ></ReactDatetime>
                          </Form.Group>
                        </Col>
                        <Col md="3">
                          <label
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                              fontSize: 12,
                              color: "black",
                            }}
                          >
                            {" "}
                            End Date
                          </label>
                          <Form.Group>
                            <ReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "Date Picker Here",
                                readOnly: true,
                                style: {
                                  cursor: "default",
                                  backgroundColor: "white",
                                  color: "black !important",
                                },
                              }}
                              onChange={(e) => {
                                setSmpTgl(e.toDate().setHours(23, 59, 59, 0));
                              }}
                              value={smpTgl}
                              // initialValue={dariTgl}
                              timeFormat={false}
                              dateFormat="DD MMM YYYY"
                            ></ReactDatetime>
                          </Form.Group>
                        </Col>

                        <Col md="3">
                          <label
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            Branch Filter
                          </label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setIdCabang(value);
                              setListShift({ value: "", label: "ALL" });
                            }}
                            value={idCabang}
                            options={[
                              {
                                value: "",
                                label: "ALL",
                                // isDisabled: true,
                              },
                              ...listCabang,
                            ]}
                            placeholder="Single Select"
                          />
                        </Col>
                        <Col md="3">
                          <label
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                              color: "black",
                              fontSize: 12,
                            }}
                          >
                            {" "}
                            Shift Filter
                          </label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setListShift(value);
                            }}
                            value={shift}
                            options={[
                              {
                                value: "",
                                label: "ALL",
                                // isDisabled: true,
                              },
                              ...cabangDilipih,
                            ]}
                            placeholder="Single Select"
                          />
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Card
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 10,
                      border: 0,
                    }}
                  >
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Col md={"3"} style={{ display: "flex" }}>
                        <InputGroup>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                            }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Name or Area"
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              className="input-group-sm mb-1"
                              style={{
                                cursor: "pointer",
                                marginLeft: "auto",
                              }}
                              onClick={handleClearSearch}
                            >
                              <i class="fas fa-times"></i>
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                      <Col md={"0"}>
                        <Button
                          // variant="info"
                          style={{
                            fontSize: 16,
                            backgroundColor: "#4c6fc8",
                            border: 0,
                            // paddingTop: 5,
                            marginRight: 15,
                            paddingBottom: 8,
                          }}
                          onClick={handleFilterChange}
                        >
                          <i class="fas fa-search"></i>
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Tab.Content>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div
                    className="loader-container"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#394978",
                        "#394978",
                        "#394978",
                        "#394978",
                        "#394978",
                      ]}
                    />
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 19,
                      }}
                    ></p>
                  </div>
                ) : (
                  <DataTable
                    columns={colPencairan}
                    data={data}
                    // {listPencairan.filter(
                    //   (row) =>
                    //     row.id_pinjaman
                    //       .toLowerCase()
                    //       .includes(searchText.toLowerCase()) ||
                    //     row.namaLengkap
                    //       .toLowerCase()
                    //       .includes(searchText.toLowerCase())
                    // )}
                    customStyles={customStyles}
                    paginationPerPage={totalPerHalaman}
                    progressPending={loading}
                  />
                )}

                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label style={{ fontWeight: "bold" }}>
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={options.find(
                              (option) => option.value === totalPerHalaman
                            )}
                            onChange={handlePageSizeChange}
                            options={options}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={page}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={monitoring.listReportPatroli.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        // initialPage={"page"}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPage(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                {/* </div> */}
                {/* ))} */}
                {/* <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Building",
                      accessor: "area",
                    },
                    {
                      Header: "Shift",
                      accessor: "namaShift",
                    },
                    {
                      Header: "Name",
                      accessor: "dilaporkanOleh",
                    },
                    {
                      Header: "Reported Date",
                      accessor: "tglLaporan",
                    },
                    {
                      Header: "Reported Time",
                      accessor: "waktuLaporan",
                    },
                    {
                      Header: "Client Name",
                      accessor: "namaClient",
                    },
                    {
                      Header: "Branch Name",
                      accessor: "namaCabang",
                    },
                    // {
                    //   Header: "Status",
                    //   accessor: "status",
                    // },
                    {
                      Header: "Action",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                /> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    {/* <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Title
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{detailPatroli.judul}</Col>
                    </Row> */}
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Building
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{detailPatroli.area}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Shift
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{detailPatroli.namaShift}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Submitter
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{detailPatroli.dilaporkanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Reported Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(detailPatroli.tanggalLaporan).format(
                          "DD MMM YYYY "
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Reported Time
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(detailPatroli.createdAt).format(" HH:mm ")}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {detailPatroli.inTime ? "Valid" : "Not Valid"}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{detailPatroli.deskripsi}</Col>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Final Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!detailPatroli.dokumentasiAwalArr ||
                      detailPatroli.dokumentasiAwalArr.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Final Documentation is Not Available
                        </Col>
                      ) : (
                        detailPatroli.dokumentasiAwalArr.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                  onClick={() => {
                                    setModalPreviewFoto(val);
                                  }}
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

const initialState = {
  getAllCabang: [],
  getCabangAktif: [],
  getCabangDashboard: [],
  kirimIdCabang: null,
  getClient: [],
};
const cabangReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (action.type) {
    case "SET_GETALLCABANG":
      return {
        ...state,
        getAllCabang: data,
      };
    case "SET_GETCABANGAKTIF":
      return {
        ...state,
        getCabangAktif: data,
      };
    case "SET_GETCABANGDASHBOARD":
      return {
        ...state,
        getCabangDashboard: data,
      };
    case "SET_GETCLIENT":
      return {
        ...state,
        getClient: data,
      };

    default:
      return state;
  }
};

export default cabangReducer;

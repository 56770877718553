import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getAllCabang(dispatch, data) {
  baseAxios
    .get(`/client/getallcabang`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_GETALLCABANG", data: respon.data });
    });
}

export function getCabangAktif(dispatch, data) {
  baseAxios
    .get(`/client/getcabangaktif`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_GETCABANGAKTIF", data: respon.data });
    });
}

export function getCabangDashboard(dispatch, data) {
  baseAxios
    .get(`/client/getcabangdashboard`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_GETCABANGDASHBOARD", data: respon.data });
    });
}
export function getClient(dispatch, data) {
  baseAxios
    .get(`/client/getclient`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_GETCLIENT", data: respon.data });
    });
}

export function daftarCabang(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/client/createcabang", data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import {
  getCabangAktif,
  getKpi1Sidak,
  getKpi2ResponIssue,
} from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";
import pestRodentReducer from "stores/pestRodent";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const sidak = useSelector((state) => state.sidakReducer);
  const cabang = useSelector((state) => state.cabangReducer);

  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );

  const [smpTgl, setSmpTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 0, 0)
  );
  const [kpi1Option, setKpi1Option] = React.useState(null);
  const [kpi2Option, setKpi2Option] = React.useState(null);
  const auth = useSelector((state) => state.authReducer);
  const [idCabang, setIdCabang] = React.useState([]);
  const [listCabang, setListCabang] = React.useState([]);

  React.useEffect(() => {
    if (sidak.kpi1) {
      setKpi1Option(sidak.kpi1.data);
    }
  }, [sidak.kpi1]);
  React.useEffect(() => {
    if (sidak.kpi2) {
      setKpi2Option(sidak.kpi2.data);
    }
  }, [sidak.kpi2]);
  React.useEffect(() => {
    getKpi1Sidak(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: idCabang.value,
      idClientWeb: idCabang.value2,
    });
    getKpi2ResponIssue(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
  }, [dariTgl, smpTgl, idCabang]);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        // ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
        value2: val.idClient,
      };
    });

    setListCabang(tmp);
    tmp.map((val, index) => {
      if (index === 0) {
        setIdCabang(val);
      }
    });
  }, [cabang.getCabangAktif]);
  React.useEffect(() => {
    getCabangAktif(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>
        {sidak.kpi1 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                  <Row>
                    <Col>
                      <Card.Title as="h4">
                        Inspection and Management Visit Achievement
                      </Card.Title>
                    </Col>
                    {auth.role === "all" ||
                    auth.role === "gsc" ||
                    auth.role === "market" ? (
                      <>
                        <Col md="6">
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setIdCabang(value);
                            }}
                            value={idCabang}
                            options={[
                              // {
                              //   value: "",
                              //   label: "Choose the Client",
                              //   isDisabled: true,
                              // },
                              ...listCabang,
                            ]}
                            placeholder="Single Select"
                          />
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p
                            className="card-category"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            Inspection
                          </p>
                          <Card.Title
                            as="h4"
                            style={{
                              fontWeight: "bold",
                              color:
                                sidak.kpi1.pencapaianInspection === "Below"
                                  ? "red"
                                  : sidak.kpi1.pencapaianInspection === "Meet"
                                  ? "orange"
                                  : "green",
                            }}
                          >
                            {sidak.kpi1.pencapaianInspection}
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p
                            className="card-category"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            Management Visit
                          </p>
                          <Card.Title
                            as="h4"
                            style={{
                              fontWeight: "bold",
                              color:
                                sidak.kpi1.pencapaianManvis === "Below"
                                  ? "red"
                                  : sidak.kpi1.pencapaianManvis === "Meet"
                                  ? "orange"
                                  : "green",
                            }}
                          >
                            {sidak.kpi1.pencapaianManvis}
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi1Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={kpi1Option}>
                              <XAxis dataKey="nama" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Target"
                                barSize={20}
                                fill="#ff8200"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Online"
                                barSize={20}
                                fill="#006605"
                                stackId="b"
                              ></Bar>
                              <Bar
                                dataKey="Offline"
                                barSize={20}
                                fill="#006bf7"
                                stackId="c"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/kunjunganSidak");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          sidak.kpi1.pencapaian < sidak.kpi1.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}

        {/* {issue.kpi2 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Issue Achievement</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {issue.kpi2.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">{issue.kpi2.target} %</Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi2Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={kpi2Option}>
                              <XAxis dataKey="nama" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="On Time"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Not Ontime"
                                barSize={20}
                                fill="#ff002f"
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/issue");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          issue.kpi2.pencapaian < issue.kpi2.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )} */}
      </Container>
    </>
  );
}

export default Panels;

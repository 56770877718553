import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  Image,
} from "react-bootstrap";
// import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDatetime from "react-datetime";
import logoBAP from "../assets/img/image.jpg";

import {
  getKpiMonitoringProblem,
  getKpiMonitoringKeluhan,
  getKpiMonitoringProject,
  getKpiMonitoringKunjungan,
  getKpi1Consumable,
  getKpiReportShift,
  getKpi2PembinaanPenampilan,
  getKpi1KunjunganPest,
  getKpi2TemuanPest,
  getKpi1ItProject,
  getKpi1Safety,
  getGrafikAbsen,
  getKpiPatroli,
  getKpi1VisitIssue,
  getKpi2ResponIssue,
  getKpi2Hardware,
  getKpi3Software,
  getKpi3Absensi,
  getKpiMonitoringSurvey,
  getTargetByMonth,
  getTargetByMonthCabang,
  setKirimIdCabang,
  getCabangAktif,

  //Get Grafik
  getGrafikIncident,
  getKpi1Training,
  getKpi2Pembinaan,
  getKpi1Sidak,
} from "../stores";
function DashboardBakalan(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  //===================Penilaian Grafik========================
  const incident = useSelector((state) => state.incidentReducer);
  const trainingPersonnel = useSelector((state) => state.trainingReducer);
  const listShift = useSelector((state) => state.shiftReportReducer);
  const pembinaan = useSelector((state) => state.pembinaanReducer);
  const penampilan = useSelector((state) => state.penampilanReducer);
  const safety = useSelector((state) => state.safetyReducer);
  const auth = useSelector((state) => state.authReducer);
  const sidak = useSelector((state) => state.sidakReducer);
  const absen = useSelector((state) => state.absenReducer);
  const monitoring = useSelector((state) => state.monitoringReducer);
  const target = useSelector((state) => state.targetReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  // const issue = useSelector((state) => state.industrialReducer);
  // const survey = useSelector((state) => state.surveyReducer);
  // const pestRodent = useSelector((state) => state.pestRodentReducer);
  // const projectIt = useSelector((state) => state.itProjectReducer);
  // const monitoring = useSelector((state) => state.monitoringReducer);
  // const consumable = useSelector((state) => state.consumableReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );
  const [persenIsmCabang, setPersenIsmCabang] = React.useState("");

  const [smpTgl, setSmpTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 0, 0)
  );
  const [filter, setFilter] = React.useState({
    tahun: new Date().getFullYear(),
  });
  const [totalKpi, setTotalKpi] = React.useState("");
  //CARD KPI
  const [kpiPatrol, setKpiPatrol] = React.useState(null);
  const [kpiIncident, setKpiIncident] = React.useState(null);
  const [kpiTraining, setKpiTraining] = React.useState(null);
  const [kpiPembinaan, setKpiPembinaan] = React.useState(null);
  const [kpiSidak, setKpiSidak] = React.useState(null);

  const [namaCabang, setNamaCabang] = React.useState([]);
  React.useEffect(() => {
    getCabangAktif(dispatch);
    getKpiPatroli(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
    });
    getGrafikIncident(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
    });
    getKpi1Training(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
    });
    getKpi2Pembinaan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
    });
    getKpi1Sidak(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang.idCabang,
      idClientWeb: props.idCabang.idClient,
    });
    //==========================BELUM DIPAKAI======================

    getKpi1Safety(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });

    getKpi2PembinaanPenampilan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getGrafikAbsen(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: props.idCabang,
    });

    getTargetByMonth(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getTargetByMonthCabang(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabang: props.idCabang,
    });
    // getKpiReportShift(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    //   idCabangWeb: props.idCabang,
    // });
    // getKpiMonitoringProblem(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpiMonitoringKeluhan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpiMonitoringProject(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpiMonitoringKunjungan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1Consumable(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2Pembinaan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1KunjunganPest(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2TemuanPest(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1ItProject(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1VisitIssue(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2ResponIssue(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2Hardware(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi3Software(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi3Absensi(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
  }, [dariTgl, smpTgl]);

  // React.useEffect(() => {
  //   getKpiMonitoringSurvey(dispatch, filter);
  // }, [filter]);

  // React.useEffect(() => {
  //   // monitoring.kpi1 &&
  //   // monitoring.kpi2 &&
  //   // monitoring.kpi4 &&
  //   // monitoring.kpi3 &&

  //   trainingPersonnel.kpi1 &&
  //   listShift.kpi &&
  //   safety.kpi1 &&
  //   pembinaan.kpi2 &&
  //   penampilan.kpiPenampilan &&
  //   // sidak.kpi1 &&
  //   absen.kpiAbsen &&
  //   monitoring.kpiPatroli
  //     ? // &&
  //       // trainingPersonnel.kpi2 &&
  //       // consumable.kpi1 &&
  //       // pestRodent.kpi1 &&
  //       // pestRodent.kpi2 &&
  //       // projectIt.kpi1 &&
  //       // safety.kpi1 &&
  //       // issue.kpi1 &&
  //       // issue.kpi2 &&
  //       // projectIt.kpi2 &&
  //       // projectIt.kpi3 &&
  //       // trainingPersonnel.kpi3 &&
  //       // survey.variablesurvey
  //       (setPersenIsmCabang(
  //         Math.ceil(
  //           (trainingPersonnel.kpi1.pencapaian +
  //             listShift.kpi.pencapaian +
  //             (safety.kpi1.F2 +
  //               safety.kpi1.FAC2 +
  //               safety.kpi1.LTI2 +
  //               safety.kpi1.MTC2 +
  //               safety.kpi1.NM2 +
  //               safety.kpi1.RWC2) /
  //               6 +
  //             pembinaan.kpi2.kejadian +
  //             penampilan.kpiPenampilan.kejadian +
  //             absen.kpiAbsen.pencapaian +
  //             monitoring.kpiPatroli.pencapaian) /
  //             7
  //         )
  //         // Math.ceil(
  //         //   (monitoring.kpi1.pencapaian +
  //         //     monitoring.kpi2.pencapaian +
  //         //     monitoring.kpi3.pencapaian +
  //         //     monitoring.kpi4.pencapaian +
  //         //     consumable.kpi1.pencapaian +
  //         //     trainingPersonnel.kpi1.pencapaian +
  //         //     trainingPersonnel.kpi2.pencapaian +
  //         //     pestRodent.kpi1.pencapaian +
  //         //     pestRodent.kpi2.pencapaian +
  //         //     projectIt.kpi1.pencapaian +
  //         //     safety.kpi1.pencapaian +
  //         //     issue.kpi1.pencapaian +
  //         //     issue.kpi2.pencapaian +
  //         //     projectIt.kpi2.pencapaian +
  //         //     projectIt.kpi3.pencapaian +
  //         //     trainingPersonnel.kpi3.pencapaian +
  //         //     survey.variablesurvey.pencapaian) /
  //         //     17
  //         // )
  //       ),
  //       setTotalKpi(
  //         trainingPersonnel.kpi1.pencapaian +
  //           listShift.kpi.pencapaian +
  //           safety.kpi1.F2 +
  //           safety.kpi1.FAC2 +
  //           safety.kpi1.LTI2 +
  //           safety.kpi1.MTC2 +
  //           safety.kpi1.NM2 +
  //           safety.kpi1.RWC2 +
  //           pembinaan.kpi2.kejadian +
  //           penampilan.kpiPenampilan.kejadian +
  //           absen.kpiAbsen.pencapaian +
  //           monitoring.kpiPatroli.pencapaian
  //         // monitoring.kpi1.pencapaian +
  //         //   monitoring.kpi2.pencapaian +
  //         //   monitoring.kpi3.pencapaian +
  //         //   monitoring.kpi4.pencapaian +
  //         //   consumable.kpi1.pencapaian +
  //         //   trainingPersonnel.kpi1.pencapaian +
  //         //   trainingPersonnel.kpi2.pencapaian +
  //         //   pestRodent.kpi1.pencapaian +
  //         //   pestRodent.kpi2.pencapaian +
  //         //   projectIt.kpi1.pencapaian +
  //         //   safety.kpi1.pencapaian +
  //         //   issue.kpi1.pencapaian +
  //         //   issue.kpi2.pencapaian +
  //         //   projectIt.kpi2.pencapaian +
  //         //   projectIt.kpi3.pencapaian +
  //         //   trainingPersonnel.kpi3.pencapaian +
  //         //   survey.variablesurvey.pencapaian
  //       ))
  //     : setPersenIsmCabang("100");
  // });

  React.useEffect(() => {
    setPersenIsmCabang(
      Math.round(
        (monitoring.kpiPatroli.pencapaian + listShift.kpi.pencapaian) / 2
      )
        ? Math.round(
            (monitoring.kpiPatroli.pencapaian + listShift.kpi.pencapaian) / 2
          )
        : 0
    );
  });
  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        cabang: val.namaCabang,
      };
    });
    setNamaCabang(tmp);
  }, [cabang.getCabangAktif]);

  React.useEffect(() => {
    if (target.getTargetByMonth && target.getTargetByMonth.targetCabang) {
      let tmp = target.getTargetByMonth.targetCabang.map((val, index) => {
        return {
          ...val,
        };
      });
    }
  }, [target.getTargetByMonth]);

  // Card KPI
  React.useEffect(() => {
    if (monitoring.kpiPatroli) {
      setKpiPatrol(monitoring.kpiPatroli.data);
    }
  }, [monitoring.kpiPatroli]);
  React.useEffect(() => {
    if (incident.getGrafikIncident) {
      setKpiIncident(incident.getGrafikIncident.data);
    }
  }, [incident.getGrafikIncident]);
  React.useEffect(() => {
    if (trainingPersonnel.kpi1) {
      setKpiTraining(trainingPersonnel.kpi1.data);
    }
  }, [trainingPersonnel.kpi1]);
  React.useEffect(() => {
    if (pembinaan.kpi2) {
      setKpiPembinaan(pembinaan.kpi2.data);
    }
  }, [pembinaan.kpi2]);
  React.useEffect(() => {
    if (sidak.kpi1) {
      setKpiSidak(sidak.kpi1.data);
    }
  }, [sidak.kpi1]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={{ backgroundColor: "#04293A" }}>
              {/* <Card.Header
                className="d-none d-md-inline"
                // style={{ backgroundColor: "white" }}
              >
                <Card.Title as="h2">
                  <Row>
                    <Col md="8" className="p-3" style={{ color: "white" }}>
                      <b>
                        ISM Performance Achievement
                        {namaCabang.map((val) => {
                          if (val.idCabang === props.idCabang) {
                            return <> {val.namaCabang}</>;
                          }
                        })}{" "}
                      </b>
                    </Col>
                    <Col md="4" className="p-3 d-flex justify-content-end">
                      {/* <Image
                        src={
                          "https://backoffice.bapguard.com/upload/logo_bap.png"
                        }
                        width="auto"
                        height="50px"
                      /> */}
              {/* <Image 
                        src={
                          "https://ptbap.net/assets/images/logo/invert-bap.png"
                        }
                        width="auto"
                        height="50px"
                      />
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Header> */}
              <Card.Body className="d-none d-md-inline">
                <Row>
                  <Col
                    as="h2"
                    //  md="8"
                    // className="p-3"
                    className="d-none d-lg-flex align-items-center "
                    style={{ color: "white" }}
                  >
                    <b>
                      ISM Performance Achievement
                      {namaCabang.map((val) => {
                        if (val.idCabang === props.idCabang.idCabang) {
                          return <> {val.namaCabang}</>;
                        }
                      })}{" "}
                    </b>
                  </Col>
                  {/* <Col
                    lg="5"
                    className="d-none d-lg-flex align-items-center justify-content-center"
                  >
                    <i
                      className="fas fa-user-shield fa-5x"
                      style={{ color: "white" }}
                    ></i>
                  </Col> */}
                  {/* <Col lg="7" xs="12">
                    <div className="numbers d-flex justify-content-around">
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b style={{ color: "white" }}>Achievement</b>
                          </h3>
                        </p>
                        {target.getTargetByMonthCabang.map((val) => {
                          return (
                            <>
                              <Card.Title
                                as="h2"
                                style={{
                                  color:
                                    persenIsmCabang < val.totalTarget
                                      ? "#CD1818"
                                      : "#20c400",
                                  fontFamily: "sans-serif",
                                }}
                              >
                                <b>{persenIsmCabang}%</b>
                              </Card.Title>
                            </>
                          );
                        })}
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b style={{ color: "white" }}>Target</b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{ color: "#20c400", fontFamily: "sans-serif" }}
                        >
                          {target.getTargetByMonthCabang.map((val) => {
                            return <b>{Math.round(val.totalTarget)}%</b>;
                          })}
                        </Card.Title>
                      </div>
                    </div>
                  </Col> */}
                  <Col md="4" className="p-3 d-flex justify-content-end">
                    <Image
                      src={
                        "https://ptbap.net/assets/images/logo/invert-bap.png"
                      }
                      width="auto"
                      height="50px"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title mt-2">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title mt-2">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>

        <>
          <Row>
            {/* {auth.username === "admin" ? (
      <Col md="3">
        <Card>
          <Card.Header>
            <Card.Title as="h4">Cleaning Service</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs="3">
                <div className="text-center">
                  <i className="fas fa-tv text-warning fa-2x"></i>
                </div>
              </Col>
              <Col xs="8">
                <div className="numbers d-flex justify-content-around">
                  <div className="d-flex flex-column align-items-center">
                    <p className="card-category">Achievement</p>
                    <Card.Title as="h5">
                      {monitoring.kpi1 &&
                      monitoring.kpi2 &&
                      monitoring.kpi4 &&
                      monitoring.kpi3
                        ? Math.ceil(
                            (monitoring.kpi1.pencapaian +
                              monitoring.kpi2.pencapaian +
                              monitoring.kpi3.pencapaian +
                              monitoring.kpi4.pencapaian) /
                              4
                          )
                        : "100"}{" "}
                      %
                    </Card.Title>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <p className="card-category">Target</p>
                    <Card.Title as="h5">100 %</Card.Title>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <hr></hr>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  onClick={() => {
                    history.push("/admin/detailMonitoring");
                  }}
                  className="btn-wd btn-outline mr-1"
                  type="button"
                  variant="info"
                >
                  Detail
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
    ) : null} */}
            {/* {auth.username === "admin" || auth.username === "Logistik JTI" ? (
      <Col md="3">
        <Card>
          <Card.Header>
            <Card.Title as="h4">Consumable Supply</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs="3">
                <div className="text-center">
                  <i className="fas fa-box-open text-primary fa-2x"></i>
                </div>
              </Col>
              <Col xs="8">
                <div className="numbers d-flex justify-content-around">
                  <div className="d-flex flex-column align-items-center">
                    <p className="card-category">Achievement</p>
                    <Card.Title as="h5">
                      {consumable.kpi1
                        ? consumable.kpi1.pencapaian
                        : "100"}{" "}
                      %
                    </Card.Title>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <p className="card-category">Target</p>
                    <Card.Title as="h5">
                      {" "}
                      {consumable.kpi1 ? consumable.kpi1.target : "100"} %
                    </Card.Title>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <hr></hr>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  onClick={() => {
                    history.push("/admin/variableconsumable");
                  }}
                  className="btn-wd btn-outline mr-1"
                  type="button"
                  variant="info"
                >
                  Detail
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
    ) : null} */}

            {/* {auth.username === "admin" ? ( */}
            <Col md="8">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="1">
                      <div className="text-center">
                        <i className="fas fa-shield-alt text-primary fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Patrol</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Row className="justify-content-center align-items-center">
                    {kpiPatrol?.map((val) => {
                      return (
                        <>
                          <Row noGutters>
                            <Col
                              // xs="12"
                              // md="8"
                              className="p-2 d-flex flex-column justify-content-center align-items-center mx-auto"
                            >
                              <div className="d-flex flex-column align-items-center">
                                <Card style={{ width: "18rem", margin: "2px" }}>
                                  <Card.Header className="d-flex flex-column align-items-center">
                                    <p
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                      className="card-category"
                                    >
                                      {val.namaShift}
                                    </p>
                                  </Card.Header>
                                  <hr></hr>
                                  <Card.Body>
                                    <Row noGutters style={{ marginTop: -20 }}>
                                      <Col
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 12,
                                          borderRight: "1px solid #ddd",
                                        }}
                                      >
                                        Achievement
                                      </Col>
                                      <Col
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 12,
                                          borderRight: "1px solid #ddd",
                                        }}
                                      >
                                        Target
                                      </Col>
                                      <Col
                                        className="p-1 d-flex justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 12,
                                        }}
                                      >
                                        Status
                                      </Col>
                                    </Row>
                                    <Row noGutters>
                                      <Col
                                        className="p-2 d-flex justify-content-center"
                                        style={{
                                          borderRight: "1px solid #ddd",
                                        }}
                                      >
                                        {val["Data Patroli"]}{" "}
                                      </Col>
                                      <Col
                                        className="p-2 d-flex justify-content-center"
                                        style={{
                                          borderRight: "1px solid #ddd",
                                        }}
                                      >
                                        {val["Target Patroli"]}{" "}
                                      </Col>
                                      <Col
                                        className="p-2 d-flex justify-content-center"
                                        style={{
                                          fontWeight: "bold",
                                          color:
                                            val.hasilPatroli === "Below"
                                              ? "red"
                                              : val.hasilPatroli === "Meet"
                                              ? "orange"
                                              : "green",
                                        }}
                                      >
                                        {val.hasilPatroli}{" "}
                                      </Col>
                                    </Row>
                                    {/* <div
                                      className="numbers d-flex justify-content-around "
                                      style={{
                                        // backgroundColor: "green",
                                        margin: -15,
                                        marginBottom: 1,
                                      }}
                                    >
                                      <div className="d-flex flex-column align-items-center">
                                        <p
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                          }}
                                          className="card-category"
                                        >
                                          Achievement
                                        </p>
                                        <Card.Title as="h5"></Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center mx-3">
                                        <p
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                          }}
                                          className="card-category"
                                        >
                                          Target
                                        </p>
                                        <Card.Title as="h5"></Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center mx-3">
                                        <p
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                          }}
                                          className="card-category"
                                        >
                                          Status
                                        </p>
                                        <Card.Title as="h5"></Card.Title>
                                      </div>
                                    </div> */}
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/detailPatroli");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-user-injured text-danger fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Incident Report</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr />
                  <Row>
                    <Col
                      className="p1 d-flex align-items-center"
                      // style={{ backgroundColor: "red" }}
                    >
                      <Card
                        style={{ width: "15rem", margin: "2px", marginLeft: 1 }}
                      >
                        <Card.Header className="d-flex flex-column align-items-center">
                          <p
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                            className="card-category"
                          >
                            {kpiIncident?.[0]?.nama}
                          </p>
                        </Card.Header>
                        <hr />
                        <Card.Body>
                          <Row noGutters style={{ marginTop: -20 }}>
                            <Col
                              className="p-1 d-flex justify-content-center"
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              Exceed
                            </Col>
                            <Col
                              className="p-1 d-flex justify-content-center"
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              Meet
                            </Col>
                            <Col
                              className="p-1 d-flex justify-content-center"
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                              }}
                            >
                              Below
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col
                              className="p-2 d-flex justify-content-center"
                              style={{
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {kpiIncident?.[0]?.Exceed}
                            </Col>
                            <Col
                              className="p-2 d-flex justify-content-center"
                              style={{
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {kpiIncident?.[0]?.Meet}
                            </Col>
                            <Col className="p-2 d-flex justify-content-center">
                              {kpiIncident?.[0]?.Below}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col className="p-2 d-flex">
                      <Card style={{ width: "15rem", margin: "2px" }}>
                        <Card.Header className="d-flex flex-column align-items-center">
                          <p
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                            className="card-category"
                          >
                            {kpiIncident?.[1]?.nama}
                          </p>
                        </Card.Header>
                        <hr />
                        <Card.Body>
                          <Row noGutters style={{ marginTop: -20 }}>
                            <Col
                              className="p-1 d-flex justify-content-center"
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              Exceed
                            </Col>
                            <Col
                              className="p-1 d-flex justify-content-center"
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              Meet
                            </Col>
                            <Col
                              className="p-1 d-flex justify-content-center"
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                              }}
                            >
                              Below
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col
                              className="p-2 d-flex justify-content-center"
                              style={{
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {kpiIncident?.[1]?.Exceed}
                            </Col>
                            <Col
                              className="p-2 d-flex justify-content-center"
                              style={{
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {kpiIncident?.[1]?.Meet}
                            </Col>
                            <Col className="p-2 d-flex justify-content-center">
                              {kpiIncident?.[1]?.Below}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/incidentrecapitulation");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-user-tie text-warning fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Training Matrix</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      <Row noGutters>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            // fontSize: 12,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Achievement
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            // fontSize: 12,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Target
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            // fontSize: 12,
                          }}
                        >
                          Status
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {kpiTraining?.[0]?.["On Time"]}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {kpiTraining?.[0]?.Target}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            color:
                              trainingPersonnel.kpi1.pencapaian === "Below"
                                ? "red"
                                : trainingPersonnel.kpi1.pencapaian === "Meet"
                                ? "orange"
                                : "green",
                          }}
                        >
                          {trainingPersonnel.kpi1.pencapaian}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartTraining");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-file-alt text-info fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Coaching</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      <Row noGutters>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            marginLeft: -32,
                            fontWeight: "bold",
                            fontSize: 15,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Exceed
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Meet
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Below
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          Waiting for Approval
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            marginLeft: -32,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {kpiPembinaan?.[0]?.Exceed}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiPembinaan?.[0]?.Meet}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiPembinaan?.[0]?.Below}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiPembinaan?.[0]?.["Waiting for Approval"]}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartPembinaan");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>

            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-search text-success fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Inspection</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      <Row noGutters>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            marginLeft: -32,
                            fontWeight: "bold",
                            fontSize: 15,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Online
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Offline
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Target
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          Status
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            marginLeft: -32,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {kpiSidak?.[0].Online}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiSidak?.[0].Offline}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiSidak?.[0].Target}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            color:
                              sidak.kpi1.pencapaianInspection === "Below"
                                ? "red"
                                : sidak.kpi1.pencapaianInspection === "Meet"
                                ? "orange"
                                : "green",
                          }}
                        >
                          {" "}
                          {sidak.kpi1.pencapaianInspection}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Card.Body>

                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartSidak");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs="2">
                      <div className="text-center">
                        <i className="fas fa-user-friends text-secondaray fa-2x"></i>
                      </div>
                    </Col>
                    <Card.Title as="h4">Management Visit</Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body style={{ marginBlock: -10 }}>
                  <hr></hr>
                  <Card style={{ margin: "2px" }}>
                    <Card.Body>
                      <Row noGutters>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            marginLeft: -32,
                            fontWeight: "bold",
                            fontSize: 15,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Offline
                        </Col>

                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          Target
                        </Col>
                        <Col
                          className="p-1 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          Status
                        </Col>
                      </Row>
                      <Row noGutters>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",
                            marginLeft: -32,

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiSidak?.[1].Offline}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            borderRight: "1px solid #ddd",

                            //   color:
                            //     trainingPersonnel.kpi1.pencapaian === "Below"
                            //       ? "red"
                            //       : trainingPersonnel.kpi1.pencapaian === "Meet"
                            //       ? "orange"
                            //       : "green",
                          }}
                        >
                          {kpiSidak?.[1].Target}
                        </Col>
                        <Col
                          className="p-2 d-flex justify-content-center"
                          style={{
                            fontWeight: "bold",
                            color:
                              sidak.kpi1.pencapaianManvis === "Below"
                                ? "red"
                                : sidak.kpi1.pencapaianManvis === "Meet"
                                ? "orange"
                                : "green",
                          }}
                        >
                          {" "}
                          {sidak.kpi1.pencapaianManvis}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/chartSidak");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant="info"
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row></Row>
        </>
      </Container>
    </>
  );
}

export default DashboardBakalan;

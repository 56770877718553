const initialState = {
  getIncidentReportAll: [],
  getGrafikIncident: null,
};
const incidentReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (action.type) {
    case "SET_GETREPORTINCIDENT":
      return {
        ...state,
        getIncidentReportAll: data,
      };
    case "SET_GRAFIKINCIDENT":
      return {
        ...state,
        getGrafikIncident: data,
      };
    default:
      return state;
  }
};
export default incidentReducer;

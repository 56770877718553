import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { getKpiPatroli, getAllCabang, getCabangAktif } from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";
import { array } from "prop-types";
import { CardBody, CardHeader } from "reactstrap";

function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const auth = useSelector((state) => state.authReducer);

  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(new Date().setHours(0, 0, 0, 0));
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));
  const [kpiOption, setKpiOption] = React.useState(null);
  const [idCabang, setIdCabang] = React.useState([]);
  const [listRuang, setListRuang] = React.useState([]);
  // {
  //   value: "",
  //   label: "Choose the Client",
  //   isDisabled: true,
  // }
  const [listCabang, setListCabang] = React.useState([]);
  React.useEffect(() => {
    if (monitoring.kpiPatroli) {
      setKpiOption(monitoring.kpiPatroli.data);
      setListRuang(monitoring.kpiPatroli.listRuang);
    }
  }, [monitoring.kpiPatroli]);

  React.useEffect(() => {
    getAllCabang(dispatch);
    getCabangAktif(dispatch);
  }, []);
  React.useEffect(() => {
    console.log(dariTgl);
    console.log(smpTgl);
    getKpiPatroli(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
      idCabangWeb: idCabang.value,
    });
  }, [dariTgl, smpTgl, idCabang]);
  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        // ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });

    setListCabang(tmp);
    tmp.map((val, index) => {
      if (index === 0) {
        setIdCabang(val);
      }
    });
  }, [cabang.getCabangAktif]);

  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
        </Row>

        {monitoring.kpiPatroli && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Row>
                    <Col md="6">
                      <Card.Title as="h4">
                        Report Patroli Achievement
                      </Card.Title>
                    </Col>

                    {auth.role === "all" ||
                    auth.role === "gsc" ||
                    auth.role === "market" ? (
                      <>
                        <Col md="6">
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setIdCabang(value);
                            }}
                            value={idCabang}
                            options={[
                              // {
                              //   value: "",
                              //   label: "Choose the Client",
                              //   isDisabled: true,
                              // },
                              ...listCabang,
                            ]}
                            placeholder="Single Select"
                          />
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    {monitoring.kpiPatroli.data.map((val) => {
                      return (
                        <>
                          <Col>
                            <div className="numbers d-flex justify-content-center">
                              <div className="d-flex flex-column align-items-center">
                                <p
                                  className="card-category"
                                  style={{ fontWeight: "bold", color: "black" }}
                                >
                                  {val.namaShift}
                                  {":"}
                                </p>
                                <Card.Title as="h4"></Card.Title>
                              </div>
                              <div className="d-flex flex-column align-items-center ml-3">
                                <p
                                  className="card-category"
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      val.hasilPatroli === "Below"
                                        ? "red"
                                        : val.hasilPatroli === "Meet"
                                        ? "orange"
                                        : "green",
                                  }}
                                >
                                  {val.hasilPatroli}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                  {/* <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {monitoring.kpiPatroli.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {monitoring.kpiPatroli.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      {kpiOption && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={kpiOption}>
                              <XAxis dataKey="namaShift" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Target Patroli"
                                barSize={20}
                                fill="#ff8200"
                                stackId="b"
                              ></Bar>
                              <Bar
                                dataKey="Data Patroli"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/reportPatroli");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          monitoring.kpiPatroli.pencapaian <
                          monitoring.kpiPatroli.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
              <Card>
                <CardHeader>
                  <h3>Patrol Location</h3>
                </CardHeader>
                <CardBody style={{ maxHeight: "300px", overflowY: "auto" }}>
                  <div className="d-flex flex-wrap flex-column">
                    {listRuang.map((val, index) => {
                      return (
                        <div
                          style={{
                            borderWidth: 2,
                            width: 600,
                            marginTop: 0,
                            // backgroundColor: "red",
                          }}
                        >
                          <Row key={index}>
                            <Col sm="1">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",
                                  fontWeight: "bold",
                                  color: "black",
                                }}
                              >
                                {index + 1}
                              </label>
                            </Col>
                            <Col sm="0">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",

                                  color: "black",
                                }}
                              >
                                :
                              </label>
                            </Col>
                            <Col sm="9">
                              <label
                                style={{
                                  textTransform: "none",
                                  fontSize: 14,
                                  color: "black",

                                  color: "black",
                                }}
                              >
                                {val.area}
                              </label>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default Panels;

import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  createReport,
  getTraining,
  verifikasiTraining,
  tambahTraining,
  editTraining,
  kirimReportTraining,
  deleteTraining,
  getCabangAktif,
  getReportIncident,
  updateIncidentReport,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import Swal from "sweetalert2";
import { compose } from "redux";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const incident = useSelector((state) => state.incidentReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalTraining, setModalTraining] = React.useState(false);
  const [modalEditTraining, setModalEditTraining] = React.useState(false);
  const [modalTrainingApprove, setModalTrainingApprove] = React.useState(false);
  const [dataIncident, setDataIncident] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [jenis, setJenis] = React.useState("");
  const [trainingId, setTrainingId] = React.useState("");
  const [deskripsiTraining, setDeskripsiTraining] = React.useState("");
  const [noteTraining, setNoteTraining] = React.useState("");
  const [dariTgl, setDariTgl] = React.useState(
    // new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
    new Date()
  );
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));
  const [gambar, setGambar] = React.useState([]);
  const [kategoriKejadian, setKategroiKejadian] = React.useState({
    value: "",
    label: "Choose the Category",
    isDisabled: true,
  });
  const respon = "Done";
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [type, setType] = React.useState({
    value: "",
    label: "Choose the Type of Incident",
    isDisabled: true,
  });
  const [tindakan, setTindakan] = React.useState({
    value: "",
    label: "Choose the Action",
    isDisabled: true,
  });
  const [listCabang, setListCabang] = React.useState([]);
  const [judul, setJudul] = React.useState("");
  const [dropMajor, setDropMajor] = React.useState(false);
  const [dropNonMajor, setDropNonMajor] = React.useState(false);
  let tanggalSekarang = new Date();

  const handleTypeChange = (selectedOption) => {
    setType(selectedOption);
    // Reset state sebelum mengatur kondisi baru
    setDropMajor(false);
    setDropNonMajor(false);

    if (selectedOption.value === "Major") {
      setDropMajor(true);
    } else if (selectedOption.value === "Non Major") {
      setDropNonMajor(true);
    }
  };
  function updateIncident(e) {
    e.preventDefault();

    if (!tindakan || !deskripsi || !gambar || gambar.length === 0) {
      Swal.fire({
        title: "Form data wajib diisi",
        icon: "error",
      });
    } else {
      let fd = new FormData();
      fd.append("idKejadian", dataIncident._id);
      fd.append("deskripsi", deskripsi);
      fd.append("tanggalUpdate", new Date(dariTgl).toISOString());
      fd.append("statusKejadian", tindakan.value);
      gambar.map((val, index) => [fd.append("dokumentasi", val)]);
      updateIncidentReport(fd);
    }
  }

  function submitIncident(e) {
    e.preventDefault();
    if (
      !client.idClient ||
      !client.idCabang ||
      !judul ||
      !type.value ||
      !kategoriKejadian.value ||
      !deskripsi ||
      !gambar ||
      gambar.length === 0
    ) {
      Swal.fire({
        title: "Form data wajib diisi",
        icon: "error",
      });
    } else {
      let idCabang = client.idCabang;
      let fd = new FormData();
      fd.append("idCabang", idCabang);
      fd.append("judul", judul);
      fd.append("tipeKejadian", type.value);
      fd.append("kategoriKejadian", kategoriKejadian.value);
      fd.append("deskripsi", deskripsi);
      fd.append("incidentDate", new Date(dariTgl).toISOString());
      gambar.map((val, index) => [fd.append("dokumentasi", val)]);
      createReport(fd);
    }
  }

  function handleOpenModal(e) {
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });
    setDeskripsi("");
    setGambar([]);
    setJudul("");
    setDariTgl(new Date());
    setDropMajor(false);
    setDropNonMajor(false);
    setKategroiKejadian({
      value: "",
      label: "Choose the Category",
      isDisabled: true,
    });
    setType({
      value: "",
      label: "Choose the Type of Incident",
      isDisabled: true,
    });
  }
  React.useEffect(() => {
    getCabangAktif(dispatch);
    getReportIncident(dispatch);
  }, []);
  function submitReportTraining(e) {
    e.preventDefault();
    kirimReportTraining({ noteTraining, gambar, trainingId, respon });
  }

  function edit(e) {
    e.preventDefault();
    editTraining({
      trainingId: trainingId,
      startDate: new Date(dariTgl).toISOString(),
      deadlineDate: new Date(smpTgl).toISOString(),
    });
  }
  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);
  React.useEffect(() => {
    let tmp = incident.getIncidentReportAll.map((val, index) => {
      return {
        ...val,
        statusKejadian: val.History[0].statusKejadian,
        tglKejadian: moment(val.incidentDate).format("DD MMM YYYY"),
        actions:
          val.History[0].statusKejadian === "Initial Report" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataIncident(val);
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>
            </div>
          ) : val.History[0].statusKejadian === "Follow-Up" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataIncident(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-edit fa-lg" />
              </Button>
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataIncident(val);
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
        // we've added some custom button actions
        // val.status === "Meet" ? (
        //   <div className="actions-right">
        //     <Button
        //       onClick={() => {
        //         setModal(true);
        //         setDataIncident(val);
        //       }}
        //       variant="danger"
        //       size="md"
        //       className="btn-link remove text-warning"
        //     >
        //       <i className="fa fa-info-circle fa-lg" />
        //     </Button>{" "}
        //   </div>
        // ) : val.status === "Below" ? (
        //   <div className="actions-right">
        //     <Button
        //       onClick={() => {
        //         setModal(true);
        //         setDataIncident(val);
        //       }}
        //       variant="danger"
        //       size="md"
        //       className="btn-link remove text-danger"
        //     >
        //       <i className="fa fa-info-circle fa-lg" />
        //     </Button>{" "}
        //   </div>
        // ) : (
        //   <div className="actions-right">
        //     <Button
        //       onClick={() => {
        //         setModal(true);
        //         setDataIncident(val);
        //       }}
        //       variant="success"
        //       size="md"
        //       className="btn-link remove text-success"
        //     >
        //       <i className="fa fa-info-circle fa-lg" />
        //     </Button>{" "}
        //   </div>
        // ),
      };
    });
    setData(tmp);
  }, [incident.getIncidentReportAll]);

  const history = useHistory();

  React.useEffect(() => {
    getTraining(dispatch);
  }, []);
  return (
    <>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalTraining(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add Incident
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Incident Recapitulation</h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Incident Name",
                      accessor: "judul",
                    },
                    {
                      Header: "Submitter",
                      accessor: "diLaporkanOleh",
                    },
                    {
                      Header: "Date of Incident",
                      accessor: "tglKejadian",
                    },
                    {
                      Header: "Type of Incident",
                      accessor: "typeIncident",
                    },
                    {
                      Header: "Category of Incident",
                      accessor: "kategoriKejadian",
                    },
                    {
                      Header: "Branch Name",
                      accessor: "namaCabang",
                    },
                    // {
                    //   Header: "Client Name",
                    //   accessor: "namaClient",
                    // },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Incident Status",
                      accessor: "statusKejadian",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setStatus("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <div style={{ maxHeight: "800px", overflowY: "scroll" }}>
          <Modal.Body>
            {(dataIncident.History &&
              dataIncident.History[0].statusKejadian === "Initial Report") ||
            (dataIncident.History &&
              dataIncident.History[0].statusKejadian === "Follow-Up") ? (
              <>
                <Row>
                  <Col md="12">
                    <Card>
                      <Card.Body>
                        <h5 style={{ marginTop: 0 }}>Event Action</h5>
                        <label>Action Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate());
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            // isValidDate={validDate}
                            timeFormat={true}
                          ></ReactDatetime>
                        </Form.Group>
                        <Form.Group>
                          <label>Action</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={tindakan}
                            onChange={(val) => setTindakan(val)}
                            options={[
                              {
                                value: "",
                                label: "Choose the Action",
                                isDisabled: true,
                              },
                              {
                                value: "Follow-Up",
                                label: "Follow-Up",
                              },
                              { value: "Closed", label: "Closed" },
                            ]}
                            placeholder="-Choose-"
                          />
                        </Form.Group>
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            onChange={(e) => {
                              setDeskripsi(e.target.value);
                              // setProject({
                              //   ...project,
                              //   deskripsi: e.target.value,
                              // });
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                        <label
                          className="label-input-file btn btn-danger text-white mb-4"
                          for="import2"
                        >
                          Add Photo
                          <input
                            type="file"
                            id="import2"
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                            onChange={(e) => {
                              setGambar([
                                ...gambar,
                                ...Array.from(e.target.files),
                              ]);
                            }}
                            style={{ display: "none" }}
                            accept="image/*"
                            multiple
                          />
                        </label>
                        {gambar.map((val, index) => {
                          return (
                            <div className="d-flex align-items-start">
                              <p>{val.name}</p>
                              <Button
                                onClick={() => {
                                  setGambar([
                                    ...gambar.slice(0, index),
                                    ...gambar.slice(index + 1, gambar.length),
                                  ]);
                                }}
                                variant="danger"
                                size="sm"
                                className="btn-link remove text-danger"
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </div>
                          );
                        })}
                        <p>
                          <Button
                            className="btn-fill pull-right"
                            type="submit"
                            variant="info"
                            onClick={updateIncident}
                          >
                            Submit
                          </Button>
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Incident Name
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataIncident.judul}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Submitter
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataIncident.diLaporkanOleh}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Branch
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataIncident.namaCabang}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Client
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataIncident.namaClient}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Type of Incident
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataIncident.typeIncident}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Category of Incident
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">{dataIncident.kategoriKejadian}</Col>
                      </Row>

                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Date of Incident
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {moment(dataIncident.incidentDate).format(
                            "DD MMM YYYY / HH:MM"
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          Date of Reported
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="6">
                          {moment(dataIncident.tanggalDilaporkan).format(
                            "DD MMM YYYY / HH:MM"
                          )}
                        </Col>
                      </Row>

                      {/* <Row>
                      <Col sm="5" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataIncident.deskripsi}</Col>
                    </Row> */}
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          {" "}
                          Status
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="5">{dataIncident.status}</Col>
                      </Row>
                      <Row>
                        <Col sm="5" style={{ fontWeight: "bold" }}>
                          {" "}
                          Incident Status
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="5">
                          {dataIncident.History &&
                            dataIncident.History[0]?.statusKejadian}
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 40 }}>
                        <Col
                          sm="12"
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          History
                        </Col>
                      </Row>
                      <Row>
                        <VerticalTimeline lineColor="black">
                          {dataIncident.History?.map((val, index) => {
                            return (
                              <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{
                                  background: "rgb(255, 255, 255)",
                                  color: "rgb(0, 0, 0)",
                                  boxShadow: "0 10px 10px rgb(186, 186, 186)",
                                }}
                                contentArrowStyle={{
                                  borderRight: "6px solid rgb(196, 199, 200)",
                                }}
                                date={
                                  <span
                                    style={{
                                      marginLeft: "15px",
                                      marginRight: "15px",
                                    }}
                                  >
                                    {moment(val.tanggalUpdate).format(
                                      "HH MMM YYYY / HH:MM"
                                    )}
                                  </span>
                                }
                                key={index}
                                iconStyle={{
                                  background:
                                    val.statusKejadian === "Initial Report"
                                      ? "rgb(33, 150, 243)"
                                      : val.statusKejadian === "Follow-Up"
                                      ? "rgb(255, 183, 0)"
                                      : "rgb(105, 255, 0)",
                                  color: "#fff",
                                }}
                                // icon={<WorkIcon />}
                              >
                                <h4
                                  style={{ marginTop: 0, fontWeight: "bold" }}
                                >
                                  {val.statusKejadian}
                                </h4>
                                <hr
                                  style={{
                                    borderTop:
                                      val.statusKejadian === "Initial Report"
                                        ? "3px solid rgb(33, 150, 243)"
                                        : val.statusKejadian === "Follow-Up"
                                        ? "3px solid rgb(255, 183, 0)"
                                        : "3px solid rgb(105, 255, 0)",
                                  }}
                                />
                                {val.statusKejadian ===
                                "Initial Report" ? null : (
                                  <Row>
                                    <Col
                                      sm="6"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 14,
                                      }}
                                    >
                                      Responded By
                                    </Col>
                                    <Col sm="0.5">:</Col>
                                    <Col
                                      sm="5"
                                      style={{
                                        fontSize: 14,
                                      }}
                                    >
                                      {val.diresponOleh}
                                    </Col>
                                  </Row>
                                )}

                                <Row>
                                  <Col
                                    sm="6"
                                    style={{ fontWeight: "bold", fontSize: 14 }}
                                  >
                                    Description
                                  </Col>
                                  <Col sm="0.5">:</Col>
                                </Row>
                                <Row>
                                  <Col sm="12">{val.deskripsi}</Col>
                                </Row>
                                <Row>
                                  <Col
                                    sm="12"
                                    style={{
                                      marginTop: "10px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Documentation
                                  </Col>
                                </Row>
                                <Row>
                                  {!val.dokumentasi ||
                                  val.dokumentasi.length == 0 ? (
                                    <Col
                                      sm="12"
                                      style={{ textAlign: "center" }}
                                    >
                                      Documentation is Not Available
                                    </Col>
                                  ) : (
                                    val.dokumentasi.map((val, index) => {
                                      return (
                                        <>
                                          <Col sm="12">
                                            <Image
                                              src={val}
                                              style={{
                                                width: 200,
                                                height: 250,
                                                margin: 20,
                                              }}
                                              thumbnail
                                              onClick={() => {
                                                setModalPreviewFoto(val);
                                              }}
                                            />
                                          </Col>
                                        </>
                                      );
                                    })
                                  )}
                                </Row>
                                {/* <Col sm="5">{val.deskripsi}</Col> */}
                              </VerticalTimelineElement>
                            );
                          })}
                        </VerticalTimeline>
                      </Row>
                      {/* <Row>
                        {!dataIncident.dokumentasi ||
                        dataIncident.dokumentasi.length == 0 ? (
                          <Col sm="12" style={{ textAlign: "center" }}>
                            Final Documentation is Not Available
                          </Col>
                        ) : (
                          dataIncident.dokumentasi.map((val, index) => {
                            return (
                              <>
                                <Col sm="4">
                                  <Image
                                    src={val}
                                    style={{
                                      width: 200,
                                      height: 250,
                                      margin: 20,
                                    }}
                                    thumbnail
                                    onClick={() => {
                                      setModalPreviewFoto(val);
                                    }}
                                  />
                                </Col>
                              </>
                            );
                          })
                        )}
                      </Row> */}
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        size="lg"
        show={modalTraining}
        onHide={() => {
          setModalTraining(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Add Incident</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                        <Form.Group>
                          <label>Type of Incident</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={handleTypeChange}
                            value={type}
                            options={[
                              {
                                value: "",
                                label: "Choose the Type of Incident",
                                isDisabled: true,
                              },
                              {
                                value: "Major",
                                label: "Major",
                              },
                              {
                                value: "Non Major",
                                label: "Non Major",
                              },

                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                        {dropMajor && (
                          <>
                            <Form.Group>
                              <label>Category of Major</label>
                              <Select
                                className="react-select primary pb-2"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                // isDisabled={formSidak === true ? true : false}
                                onChange={(value) => {
                                  setKategroiKejadian(value);
                                }}
                                value={kategoriKejadian}
                                options={[
                                  {
                                    value: "",
                                    label: "Choose the Category",
                                    isDisabled: true,
                                  },
                                  {
                                    value:
                                      "Pencurian atau Kehilangan > 1 Juta USD",
                                    label:
                                      "Pencurian atau Kehilangan > 1 Juta USD",
                                  },
                                  {
                                    value: "Kecelakaan Kerja Fatal",
                                    label: "Kecelakaan Kerja Fatal",
                                  },
                                  {
                                    value: "Bencana Alam Besar",
                                    label: "Bencana Alam Besar",
                                  },
                                  {
                                    value: "Kebakaran Besar",
                                    label: "Kebakaran Besar",
                                  },
                                  {
                                    value: "Penyakit Menular",
                                    label: "Penyakit Menular",
                                  },

                                  "-",
                                ]}
                                placeholder="Single Select"
                              />
                            </Form.Group>
                          </>
                        )}
                        {dropNonMajor && (
                          <>
                            <Form.Group>
                              <label>Category of Non Major</label>
                              <Select
                                className="react-select primary pb-2"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                // isDisabled={formSidak === true ? true : false}
                                onChange={(value) => {
                                  setKategroiKejadian(value);
                                }}
                                value={kategoriKejadian}
                                options={[
                                  {
                                    value: "",
                                    label: "Choose the Category",
                                    isDisabled: true,
                                  },
                                  {
                                    value:
                                      "Pencurian atau Kehilangan < 1 Juta USD",
                                    label:
                                      "Pencurian atau Kehilangan < 1 Juta USD",
                                  },
                                  {
                                    value: "Kecelakaan Kerja Ringan",
                                    label: "Kecelakaan Kerja Ringan",
                                  },
                                  {
                                    value: "Bencana Alam Ringan",
                                    label: "Bencana Alam Ringan",
                                  },
                                  {
                                    value: "Kebakaran Ringan",
                                    label: "Kebakaran Ringan",
                                  },

                                  "-",
                                ]}
                                placeholder="Single Select"
                              />
                            </Form.Group>
                          </>
                        )}
                        <Form.Group>
                          <label>Incident Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setJudul(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            onChange={(e) => {
                              setDeskripsi(e.target.value);
                              // setProject({
                              //   ...project,
                              //   deskripsi: e.target.value,
                              // });
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Date of Incident</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate());
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={true}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>

                      {/* <Col sm="6">
                        <label>Time</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={smpTgl}
                            initialValue={smpTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col> */}
                    </Row>
                    <label
                      className="label-input-file btn btn-danger text-white mb-4"
                      for="import2"
                    >
                      Add Photo
                      <input
                        type="file"
                        id="import2"
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                        onChange={(e) => {
                          setGambar([...gambar, ...Array.from(e.target.files)]);
                        }}
                        style={{ display: "none" }}
                        accept="image/*"
                        multiple
                      />
                    </label>
                    {gambar.map((val, index) => {
                      return (
                        <div className="d-flex align-items-start">
                          <p>{val.name}</p>
                          <Button
                            onClick={() => {
                              setGambar([
                                ...gambar.slice(0, index),
                                ...gambar.slice(index + 1, gambar.length),
                              ]);
                            }}
                            variant="danger"
                            size="sm"
                            className="btn-link remove text-danger"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      );
                    })}

                    <div className="clearfix"></div>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitIncident}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { addKejadian, getSafety } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import { CardBody } from "reactstrap";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const safety = useSelector((state) => state.safetyReducer);
  const [modal, setModal] = React.useState(false);
  const [modalKejadian, setModalKejadian] = React.useState(false);
  //   const [project, setProject] = React.useState({});
  const [data, setData] = React.useState([]);
  const [dataSafety, setDataSafety] = React.useState([]);
  const [namaKaryawan, setNamaKaryawan] = React.useState("");
  const [jabatan, setJabatan] = React.useState("");
  const [kejadian, setKejadian] = React.useState("");
  const [deskripsi, setDeskripsi] = React.useState("");
  const [gambar, setGambar] = React.useState([]);

  const history = useHistory();

  React.useEffect(() => {
    let tmp = safety.listSafety.map((val, index) => {
      return {
        ...val,
        tglLaporan: moment(val.startDate).format("DD MMM YYYY / h:mm:ss a"),

        actions:
          val.status == "Penanganan" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataSafety(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataSafety(val);
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
      };
    });
    setData(tmp);
  }, [safety.listSafety]);

  const onSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData();
    fd.append("namaKaryawan", namaKaryawan);
    fd.append("jabatan", jabatan);
    fd.append("jenisKejadian", kejadian.value);
    fd.append("deskripsi", deskripsi);
    gambar.map((val, index) => {
      fd.append("dokumentasiAwalArr", val);
    });
    addKejadian(fd);
  };

  React.useEffect(() => {
    getSafety(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalKejadian(true);
          }}
          variant="primary"
        >
          Add Incident
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Incident</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Name",
                      accessor: "namaKaryawan",
                    },
                    {
                      Header: "Position",
                      accessor: "jabatan",
                    },
                    {
                      Header: "Incident",
                      accessor: "jenisKejadian",
                    },
                    {
                      Header: "Report Date",
                      accessor: "tglLaporan",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  {/* <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Detail Problem</Card.Title>
                    </Card.Header>
                  </Card.Header> */}
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Name
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSafety.namaKaryawan}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Position
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSafety.jabatan}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Incident
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSafety.jenisKejadian}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSafety.deskripsi}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Submitter
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSafety.diajukanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Report Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataSafety.startDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSafety.status}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        On Schedule
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataSafety.status != "Done"
                          ? "-"
                          : dataSafety.solvedInTime
                          ? "Ya"
                          : "Tidak"}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataSafety.dokumentasiAwalArr ||
                      dataSafety.dokumentasiAwalArr.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Documentation is Not Available
                        </Col>
                      ) : (
                        dataSafety.dokumentasiAwalArr.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalKejadian}
        onHide={() => {
          setModalKejadian(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Incident</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label style={{ textTransform: "none" }}>
                            Name of Employee
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setNamaKaryawan(e.target.value);
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label style={{ textTransform: "none" }}>
                            Position
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setJabatan(e.target.value);
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label style={{ textTransform: "none" }}>
                            Incident
                          </label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setKejadian(value);
                            }}
                            value={kejadian}
                            options={[
                              {
                                value: "",
                                label: "Choose the Incident",
                                isDisabled: true,
                              },
                              {
                                value: "Fatalities",
                                label: "Fatalities",
                              },
                              {
                                value: "Lost time injuries (LTI)",
                                label: "Lost time injuries (LTI)",
                              },
                              {
                                value: "Restricted Work Cases (RWC)",
                                label: "Restricted Work Cases (RWC)",
                              },
                              {
                                value: "Medical Treatment Cases (MTC)",
                                label: "Medical Treatment Cases (MTC)",
                              },
                              {
                                value: "First Aid Case (FAC)",
                                label: "First Aid Case (FAC)",
                              },
                              {
                                value: "Near Miss (NM)",
                                label: "Near Miss (NM)",
                              },
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            onChange={(e) => {
                              setDeskripsi(e.target.value);
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <label
                      className="label-input-file btn btn-danger text-white mb-4"
                      for="import2"
                    >
                      Add Documentation
                      <input
                        type="file"
                        id="import2"
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                        onChange={(e) => {
                          setGambar([...gambar, ...Array.from(e.target.files)]);
                        }}
                        style={{ display: "none" }}
                        accept="image/*"
                        multiple
                      />
                    </label>
                    {gambar.map((val, index) => {
                      return (
                        <div className="d-flex align-items-start">
                          <p>{val.name}</p>
                          <Button
                            onClick={() => {
                              setGambar([
                                ...gambar.slice(0, index),
                                ...gambar.slice(index + 1, gambar.length),
                              ]);
                            }}
                            variant="danger"
                            size="sm"
                            className="btn-link remove text-danger"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      );
                    })}
                    <p>
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        variant="info"
                        onClick={onSubmit}
                      >
                        Submit
                      </Button>
                    </p>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

function getRouting(routes, val) {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].name === val) {
      return routes[i].layout + routes[i].path;
    }
    if (routes[i].collapse) {
      return getRouting(routes[i].views, val);
    }
  }
  return "/";
}

export function login(dispatch, data, history) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .post("/user/loginperusahaan", data)
    .then(async (respon) => {
      let { idCabang, perusahaan, username, menu, role } = respon.data;

      Swal.close();
      localStorage.setItem("tokenjti", respon.data.token);
      dispatch({
        type: "SET_IDENTITY",
        data: { perusahaan, username, menu, role, idCabang },
      });
      history.push("/admin/dashboard");
      // history.push("/admin/detailPatroli");
    })
    .catch(errorHandler);
}
export function refresh(dispatch) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .get("/user/refresh", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      let { perusahaan, username, menu, idCabang, role } = respon.data;

      localStorage.setItem("tokenjti", respon.data.token);
      dispatch({
        type: "SET_IDENTITY",
        data: { idCabang, perusahaan, username, menu, role },
      });
      Swal.close();
    })
    .catch(errorHandler);
}

export function logout() {
  localStorage.removeItem("tokenjti");
  window.location.replace("/auth/login-page");
}

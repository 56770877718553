import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getTargetAll,
  getAllCabang,
  daftarCabang,
  getCabangAktif,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

function ReactTables() {
  const [singleSelect, setSingleSelect] = React.useState("");
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const monitoring = useSelector((state) => state.monitoringReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const targetReducer = useSelector((state) => state.targetReducer);
  const [data, setData] = React.useState([]);
  const [dataRuangan, setDataRuangan] = React.useState([]);
  const [dataBaru, setDataBaru] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [dataGedung, setDataGedung] = React.useState({});
  const [modalRuangan, setModalRuangan] = React.useState(false);
  const [modalEditRuangan, setModalEditRuangan] = React.useState(false);
  const [idRuangan, setIdRuangan] = React.useState("");
  const [kodeRuangan, setKodeRuangan] = React.useState("");
  const [namaEditClient, setNamaClient] = React.useState("");
  const [idCabang, setIdCabang] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [listCabang, setListCabang] = React.useState([]);
  const [namaEditCabang, setNamaEdtCabang] = React.useState("");
  const [idEditCabang, setIdEditCabang] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const history = useHistory();
  const [dataCabangAktif, setCabangAktif] = React.useState([]);
  const [target, setTarget] = React.useState({});
  const [bulan, setBulan] = React.useState({});

  React.useEffect(() => {
    getTargetAll(dispatch);
    getAllCabang(dispatch);
    getCabangAktif(dispatch);
  }, []);

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
      };
    });
    setCabangAktif(tmp);
  }, [cabang.getCabangAktif]);
  React.useEffect(() => {
    let tmp = cabang.getAllCabang.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getAllCabang]);

  return (
    <>
      <Container fluid>
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalRuangan(true);
            setBulan("");
            setTarget("");
            setIdCabang({
              value: "",
              label: "Choose the Client",
              isDisabled: true,
            });
          }}
          variant="primary"
        >
          Add Branch
        </Button>

        <Row>
          <Col md="12">
            <h4 className="title">Target</h4>
            <Card>
              <Card.Body>
                <ReactTable
                  data={dataCabangAktif}
                  columns={[
                    {
                      Header: "Branch Name",
                      accessor: "namaCabang",
                    },
                    {
                      Header: "Client Name",
                      accessor: "namaClient",
                    },

                    // {
                    //   Header: "Actions",
                    //   accessor: "actions",
                    //   sortable: false,
                    //   filterable: false,
                    // },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modalRuangan}
        onHide={() => {
          setModalRuangan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Branch</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setIdCabang(value);
                            }}
                            value={idCabang}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={(e) => {
                        e.preventDefault();
                        daftarCabang({
                          idCabang: idCabang.idCabang,
                          idClient: idCabang.idClient,
                        });
                      }}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

const initialState = {
  listShiftPatroli: [],
  listSoal: [],
  listReportShift: [],
  listLokasi: [],
  kpi: null,
};

const shiftReportReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_LISTSHIFTPATROLI":
      return {
        ...state,
        listShiftPatroli: data,
      };
    case "SET_LISTSOALSHIFT":
      return {
        ...state,
        listSoal: data,
      };
    case "SET_REPORTSHIFT":
      return {
        ...state,
        listReportShift: data,
      };
    case "SET_LISTLOKASI":
      return {
        ...state,
        listLokasi: data,
      };
    case "SET_KPIREPORT":
      return {
        ...state,
        kpi: data,
      };
    default:
      return state;
  }
};

export default shiftReportReducer;

import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Table,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getStockGudang,
  tambahStockGudang,
  getKategoryCS,
  pesanDariGudang,
  //   importStock,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment";
function ReactTables() {
  const dispatch = useDispatch();
  const consumable = useSelector((state) => state.consumableReducer);

  const [data, setData] = React.useState([]);
  const [orderedItem, setOrderedItem] = React.useState([]);
  const [listKategori, setListKategori] = React.useState([]);
  const [divisi, setDivisi] = React.useState([]);
  const [choosenKategori, setChoosenKategori] = React.useState({
    value: "",
    label: "Choose the Category",
    isDisabled: true,
  });
  const [modal, setModal] = React.useState(false);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [dataStock, setDataStock] = React.useState(null);
  const [kode, setKode] = React.useState("");
  const [nama, setNama] = React.useState("");
  const [stock, setStock] = React.useState(0);
  const [satuan, setSatuan] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [spek, setSpek] = React.useState("");
  const [dataItem, setDataItem] = React.useState([]);

  function onsubmit(e) {
    e.preventDefault();
    // console.log(choosenKategori.value);
    tambahStockGudang({
      kode: kode.toUpperCase(),
      nama: nama,
      stock: stock,
      minStock: stock,
      satuan: satuan,
      kategori: choosenKategori.value,
      brand: brand,
      spek: spek,
    });
    // let fd = new FormData();
    // fd.append("file", dataStock);
    // importStock(fd);
  }

  React.useEffect(() => {
    let tmp = consumable.stockGudang.map((val, index) => {
      return {
        code: val.kode,
        item: val.nama,
        qty: val.stock,
        satuan: val.satuan,
        brand: val.brand,
        kategori: val.kategori,
        speck: val.spek,
        minStock: val.minStock,
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                let tmp2 = [
                  ...orderedItem,
                  {
                    idItem: val._id,
                    kode: val.kode,
                    nama: val.nama,
                    unit: 1,
                    harga: 1,
                    totalHarga: 1,
                    satuan: val.satuan,
                    kategori: val.kategori,
                    max: val.stock,
                  },
                ];
                setOrderedItem(tmp2);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fas fa-plus fa-lg"></i>
            </Button>{" "}
            <Button
              onClick={() => {
                // console.log(val);
                setModalDetail(true);
                setDataItem(val);
              }}
              variant="success"
              size="md"
              className="btn-link remove text-success"
            >
              <i className="fa fa-info-circle fa-lg" />
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [consumable.stockGudang, orderedItem]);

  React.useEffect(() => {
    let tmp = consumable.kategori.map((val, index) => {
      return {
        label: val.nama,
        value: val.nama,
      };
    });
    setListKategori(tmp);
  }, [consumable.kategori]);

  React.useEffect(() => {
    getStockGudang(dispatch);
    getKategoryCS(dispatch);
  }, []);
  return (
    <>
      <Container fluid>
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Add Item
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Order</h4>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Code",
                      accessor: "code",
                    },
                    {
                      Header: "Item",
                      accessor: "item",
                    },
                    {
                      Header: "Speck",
                      accessor: "speck",
                    },
                    {
                      Header: "Stock",
                      accessor: "qty",
                    },
                    {
                      Header: "Min Stock",
                      accessor: "minStock",
                    },
                    {
                      Header: "Unit",
                      accessor: "satuan",
                    },
                    {
                      Header: "Category",
                      accessor: "kategori",
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Ordering Form</Card.Title>
              </Card.Header>
              <Card.Body className="table-responsive p-0">
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Qty.</th>
                      <th>Unit</th>
                      {/* <th>Price</th>
                      <th>Total</th> */}
                      <th>Category</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderedItem.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{val.kode}</td>
                          <td>{val.nama}</td>
                          <td>
                            <Form action="#" method="#">
                              <Form.Group>
                                <Form.Control
                                  onChange={(e) => {
                                    // console.log(
                                    //   parseInt(
                                    //     orderedItem[index].harga *
                                    //       e.target.value
                                    //   )
                                    // );
                                    setOrderedItem([
                                      ...orderedItem.slice(0, index),
                                      {
                                        ...val,
                                        unit: e.target.value,
                                        totalHarga: parseInt(
                                          orderedItem[index].harga *
                                            e.target.value
                                        ),
                                      },
                                      ...orderedItem.slice(
                                        index + 1,
                                        index.length
                                      ),
                                    ]);
                                  }}
                                  value={val.unit}
                                  type="number"
                                  min={1}
                                  max={Number(val.max)}
                                ></Form.Control>
                              </Form.Group>
                            </Form>
                          </td>
                          <td>{val.satuan}</td>
                          {/* <td>
                            <Form action="#" method="#">
                              <Form.Group>
                                <Form.Control
                                  onChange={(e) => {
                                    // console.log(
                                    //   parseInt(
                                    //     orderedItem[index].unit * e.target.value
                                    //   )
                                    // );
                                    setOrderedItem([
                                      ...orderedItem.slice(0, index),
                                      {
                                        ...val,
                                        harga: e.target.value,
                                        totalHarga: parseInt(
                                          orderedItem[index].unit *
                                            e.target.value
                                        ),
                                      },
                                      ...orderedItem.slice(
                                        index + 1,
                                        index.length
                                      ),
                                    ]);
                                  }}
                                  value={val.harga}
                                  type="number"
                                  min={1}
                                  max={Number(val.max)}
                                ></Form.Control>
                              </Form.Group>
                            </Form>
                          </td> */}
                          {/* <td>
                            {`Rp ${val.totalHarga}`}
                            
                          </td> */}
                          <td>{val.kategori}</td>
                          <td>
                            <Button
                              onClick={() => {
                                setOrderedItem([
                                  ...orderedItem.slice(0, index),
                                  ...orderedItem.slice(index + 1, index.length),
                                ]);
                              }}
                              variant="danger"
                              size="md"
                              className="btn-link remove text-danger"
                            >
                              <i className="fas fa-times fa-lg"></i>
                            </Button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            {orderedItem.length > 0 && (
              <>
                <Form.Group>
                  <label>Divisi</label>
                  <Form.Control
                    onChange={(e) => {
                      setDivisi(e.target.value);
                    }}
                    type="text"
                  ></Form.Control>
                </Form.Group>
                {/* <Select
                  className="react-select primary pb-2"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  onChange={(value) => {
                    setChoosenKategori(value);
                  }}
                  value={choosenKategori}
                  options={[
                    {
                      value: "",
                      label: "Choose the Category",
                      isDisabled: true,
                    },
                    ...listKategori,
                  ]}
                  placeholder="Single Select"
                /> */}
                <Button
                  className="btn-wd mr-1"
                  onClick={() => {
                    let tmp = orderedItem.map((val) => {
                      return {
                        idItem: val.idItem,
                        qty: val.unit,
                        harga: parseInt(val.harga),
                        totalHarga: val.totalHarga,
                      };
                    });
                    pesanDariGudang({
                      items: JSON.stringify(tmp),
                      divisi: divisi,
                    });
                  }}
                  variant="primary"
                >
                  Submit
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Add Stock
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="6">
                        <Form.Group>
                          <label>Code</label>
                          <Form.Control
                            onChange={(e) => {
                              setKode(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <Form.Group>
                          <label>Item Name</label>
                          <Form.Control
                            onChange={(e) => {
                              setNama(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <Form.Group>
                          <label>Stock</label>
                          <Form.Control
                            onChange={(e) => {
                              setStock(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="number"
                            min={1}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <Form.Group>
                          <label>Unit</label>
                          <Form.Control
                            onChange={(e) => {
                              setSatuan(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Speck</label>
                          <Form.Control
                            onChange={(e) => {
                              setSpek(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Brand</label>
                          <Form.Control
                            onChange={(e) => {
                              setBrand(e.target.value);
                              // setDataBaru({
                              //   ...dataBaru,
                              //   gedung: e.target.value,
                              // });
                            }}
                            // placeholder="Masukan Nama Gedung"
                            type="text"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Category</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={(value) => {
                              setChoosenKategori(value);
                            }}
                            value={choosenKategori}
                            options={[
                              {
                                value: "",
                                label: "Choose the Category",
                                isDisabled: true,
                              },
                              ...listKategori,
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={onsubmit}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={modalDetail}
        onHide={() => {
          setModalDetail(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="3" style={{ fontWeight: "bold" }}>
                        Code
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="8">{dataItem.kode}</Col>
                    </Row>
                    <Row>
                      <Col sm="3" style={{ fontWeight: "bold" }}>
                        Item
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="8">{dataItem.nama}</Col>
                    </Row>
                    <Row>
                      <Col sm="3" style={{ fontWeight: "bold" }}>
                        Speck
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="8">{dataItem.spek}</Col>
                    </Row>
                    <Row>
                      <Col sm="3" style={{ fontWeight: "bold" }}>
                        Stock
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="8">{dataItem.stock}</Col>
                    </Row>
                    <Row>
                      <Col sm="3" style={{ fontWeight: "bold" }}>
                        Unit
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="8">{dataItem.satuan}</Col>
                    </Row>
                    <Row>
                      <Col sm="3" style={{ fontWeight: "bold" }}>
                        {" "}
                        Brand
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="8">{dataItem.brand}</Col>
                    </Row>
                    <Row>
                      <Col sm="3" style={{ fontWeight: "bold" }}>
                        {" "}
                        Category
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="8">{dataItem.kategori}</Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

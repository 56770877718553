import React from "react";
import Select from "react-select";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import ReactDatetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import {
  getKpiMonitoringProblem,
  getKpiMonitoringKeluhan,
  getKpiMonitoringKunjungan,
  getKpiMonitoringProject,
  exportBandU,
} from "../../../stores";
import { useHistory } from "react-router-dom";

import moment from "moment";
const barColor = [
  "#4287f5",
  "#f51b84",
  "#33d91a",
  "#f77f16",
  "#ff1414",
  "#06cf8c",
  "#dc02f0",
  "#341f91",
  "#8c0e19",
  "#1fdbb6",
  "#1fdb48",
  "#9cdb1f",
  "#11cbfa",
  "#de95c4",
  "#e38d5f",
  "#f23f2c",
];
function Panels() {
  const dispatch = useDispatch();
  const history = useHistory();
  const monitoring = useSelector((state) => state.monitoringReducer);

  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));
  React.useEffect(() => {
    if (monitoring.kpi1) {
      setKpi1Option(monitoring.kpi1.data);
    }
  }, [monitoring.kpi1]);

  React.useEffect(() => {
    if (monitoring.kpi2) {
      setKpi2Option(monitoring.kpi2.data);
    }
  }, [monitoring.kpi2]);
  React.useEffect(() => {
    if (monitoring.kpi3) {
      setKpi3Option(monitoring.kpi3.data);
    }
  }, [monitoring.kpi3]);
  React.useEffect(() => {
    if (monitoring.kpi4) {
      setKpi4Option(monitoring.kpi4.data);
    }
  }, [monitoring.kpi4]);

  const [kpi1Option, setKpi1Option] = React.useState(null);
  const [kpi2Option, setKpi2Option] = React.useState(null);
  const [kpi3Option, setKpi3Option] = React.useState(null);
  const [kpi4Option, setKpi4Option] = React.useState(null);

  const exportB = () => {
    exportBandU({ dariTgl: dariTgl, smpTgl: smpTgl });
  };

  React.useEffect(() => {
    getKpiMonitoringProblem(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiMonitoringKeluhan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiMonitoringProject(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiMonitoringKunjungan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
  }, [dariTgl, smpTgl]);

  return (
    <>
      <Container fluid>
        <Row className="mb-3">
          <Col md="3">
            <h4 className="title">Start Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                }}
                value={dariTgl}
                initialValue={dariTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">End Date</h4>
            <Form.Group>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Date Picker Here",
                }}
                onChange={(e) => {
                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                }}
                value={smpTgl}
                initialValue={smpTgl}
                timeFormat={false}
              ></ReactDatetime>
            </Form.Group>
          </Col>
          <Col md="3">
            <h4 className="title">Export Outside FM</h4>
            <Button
              onClick={() => {
                exportB();
                // history.push("/admin/kunjunganGedung");
              }}
              className="btn-wd btn-outline mr-1"
              type="button"
              variant={"info"}
            >
              Export
            </Button>
          </Col>
        </Row>
        {monitoring.kpi3 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Visiting Achievement</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center mr-3">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {monitoring.kpi3.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {monitoring.kpi3.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi3Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={kpi3Option} width="80vw">
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              {/* <Legend /> */}
                              <Legend verticalAlign="top" height={36} />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey={"OnTime"}
                                barSize={20}
                                fill={"#0ed907"}
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey={"Not OnTime"}
                                barSize={20}
                                fill={"#ff002f"}
                                stackId="a"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/kunjunganGedung");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          monitoring.kpi3.pencapaian < monitoring.kpi3.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
        {monitoring.kpi1 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Problem Achievement</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center mr-3">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {monitoring.kpi1.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {" "}
                            {monitoring.kpi1.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi1Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <BarChart data={kpi1Option}>
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />

                              <Bar
                                dataKey="Solved"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="On Progress"
                                barSize={20}
                                fill="#ff9d00"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Outside FM"
                                barSize={20}
                                fill="#eb34db"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Project"
                                barSize={20}
                                fill="#0011ff"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="OnTime"
                                barSize={20}
                                fill="#0ed907"
                                stackId="b"
                              ></Bar>
                              <Bar
                                dataKey="Not OnTime"
                                barSize={20}
                                fill="#ff002f"
                                stackId="b"
                              ></Bar>
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/rekapProblem");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          monitoring.kpi1.pencapaian < monitoring.kpi1.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
        {monitoring.kpi2 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Complaining Achievement</Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center mr-3">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {monitoring.kpi2.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {monitoring.kpi2.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi2Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <ComposedChart data={kpi2Option}>
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />

                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Solved"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="On Progress"
                                barSize={20}
                                fill="#ff9d00"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Outside FM"
                                barSize={20}
                                fill="#eb34db"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Project"
                                barSize={20}
                                fill="#0011ff"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="OnTime"
                                barSize={20}
                                fill="#0ed907"
                                stackId="b"
                              ></Bar>
                              <Bar
                                dataKey="Not OnTime"
                                barSize={20}
                                fill="#ff002f"
                                stackId="b"
                              ></Bar>
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/rekapKeluhan");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          monitoring.kpi2.pencapaian < monitoring.kpi2.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
        {monitoring.kpi4 && (
          <Row>
            <Col>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">
                    Project Submission Achievement
                  </Card.Title>
                  {/* <p className="card-category">Bootstrap default style</p> */}
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div className="numbers d-flex justify-content-center">
                        <div className="d-flex flex-column align-items-center mr-3">
                          <p className="card-category">Achievement</p>
                          <Card.Title as="h4">
                            {monitoring.kpi4.pencapaian} %
                          </Card.Title>
                        </div>
                        <div className="d-flex flex-column align-items-center ml-3">
                          <p className="card-category">Target</p>
                          <Card.Title as="h4">
                            {" "}
                            {monitoring.kpi4.target} %
                          </Card.Title>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {kpi4Option && (
                        <div style={{ width: "100%", height: 300 }}>
                          <ResponsiveContainer>
                            <BarChart data={kpi4Option}>
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend verticalAlign="top" height={36} />
                              <CartesianGrid stroke="#f5f5f5" />
                              <Bar
                                dataKey="Waiting for Approval"
                                barSize={20}
                                fill="#eb34db"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="Done"
                                barSize={20}
                                fill="#006605"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="On Progress"
                                barSize={20}
                                fill="#ff9d00"
                                stackId="a"
                              ></Bar>
                              <Bar
                                dataKey="OnTime"
                                barSize={20}
                                fill="#0ed907"
                                stackId="b"
                              ></Bar>
                              <Bar
                                dataKey="Not OnTime"
                                barSize={20}
                                fill="#ff002f"
                                stackId="b"
                              ></Bar>
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          history.push("/admin/verifyProject");
                        }}
                        className="btn-wd btn-outline mr-1"
                        type="button"
                        variant={
                          monitoring.kpi4.pencapaian < monitoring.kpi4.target
                            ? "danger"
                            : "info"
                        }
                      >
                        Detail
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
        {/* end row */}
      </Container>
    </>
  );
}

export default Panels;

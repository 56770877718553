const initialState = {
  getListChecklist: [],
  // getGrafikIncident: null,
};
const uniformChecklistReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (action.type) {
    case "SET_GETLISTCHECKLIST":
      return {
        ...state,
        getListChecklist: data,
      };
    case "SET_GRAFIKINCIDENT":
      return {
        ...state,
        getGrafikIncident: data,
      };
    default:
      return state;
  }
};
export default uniformChecklistReducer;

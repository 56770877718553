import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export function getAbsenAll(dispatch) {
  baseAxios
    .get(`/absen/getabsen`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTABSEN", data: respon.data });
    })
    .catch(errorHandler);
}

export function getGrafikAbsen(dispatch, data) {
  baseAxios
    .get(
      `/absen/getgrafikabsen?dari=${data.dariTgl}&sampai=${data.smpTgl}&idCabangWeb=${data.idCabangWeb}`,
      {
        headers: { token: localStorage.getItem("tokenjti") },
      }
    )
    .then(async (respon) => {
      dispatch({ type: "SET_KPIABSEN", data: respon.data });
    })
    .catch(errorHandler);
}

export function importAbsenKaryawan(data) {
  baseAxios
    .post(`/absen/importabsen`, data, {
      headers: {
        token: localStorage.getItem("tokenjti"),
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        console.log(
          parseInt(
            Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
          )
        );
        console.log(ProgressEvent);
      },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function tambahAbsensi(data) {
  baseAxios
    .post(`/absen/createabsen`, data, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function getPersonnel(dispatch) {
  baseAxios
    .get(`/absen/getpersonel`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_LISTPERSONNEL", data: respon.data });
    })
    .catch(errorHandler);
}

import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import { getRekapLaporan } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import { CardBody } from "reactstrap";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const monitoring = useSelector((state) => state.monitoringReducer);
  const [modal, setModal] = React.useState(false);
  //   const [project, setProject] = React.useState({});
  const [data, setData] = React.useState([]);
  const [dataKunjungan, setDataKunjungan] = React.useState([]);
  const history = useHistory();

  React.useEffect(() => {
    let tmp = monitoring.rekapLaporan.map((val, index) => {
      return {
        ...val,
        tglLaporan: moment(val.createdAt).format("DD MMM YYYY / h:mm:ss a"),
        status: val.inTime ? "Valid" : "Tidak Valid",
        actions: (
          <div className="actions-right">
            <Button
              onClick={() => {
                //   setDataGedung(val);
                setDataKunjungan(val);
                setModal(true);
                // downloadFile(val.filePendukung[0]);
                // setDataProblem(val);
                // console.log(val);
              }}
              variant="info"
              size="md"
              className="btn-link remove text-info"
            >
              <i className="fas fa-map-marked-alt fa-lg"></i>
            </Button>{" "}
          </div>
        ),
      };
    });
    setData(tmp);
  }, [monitoring.rekapLaporan]);

  React.useEffect(() => {
    getRekapLaporan(dispatch);
  }, []);

  const RegularMap = () => {
    const mapRef = React.useRef(null);
    React.useEffect(() => {
      let google = window.google;
      let map = mapRef.current;
      // let lat = "40.748817";
      // let lng = "-73.985428";
      const myLatlng = new google.maps.LatLng(
        dataKunjungan.latitude,
        dataKunjungan.longitude
      );
      const mapOptions = {
        zoom: 17,
        center: myLatlng,
        scrollwheel: false,
      };

      map = new google.maps.Map(map, mapOptions);

      const marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        animation: google.maps.Animation.DROP,
        title: "Light Bootstrap Dashboard PRO React!",
      });

      // const contentString =
      //   '<div class="info-window-content"><h2>Light Bootstrap Dashboard PRO React</h2>' +
      //   "<p>A premium Admin for React-Bootstrap, Bootstrap, React, and React Hooks.</p></div>";

      // const infowindow = new google.maps.InfoWindow({
      //   content: contentString,
      // });

      // google.maps.event.addListener(marker, "click", function () {
      //   infowindow.open(map, marker);
      // });
    }, []);
    return (
      <>
        <div className="map" id="regularMap" ref={mapRef} />
      </>
    );
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h4 className="title">Visiting Report</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Building",
                      accessor: "gedung",
                    },
                    {
                      Header: "Area",
                      accessor: "area",
                    },
                    {
                      Header: "Name",
                      accessor: "dilaporkanOleh",
                    },
                    {
                      Header: "Visiting Date",
                      accessor: "tglLaporan",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Visit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="6">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Detail</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Building
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{dataKunjungan.gedung}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Area
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{dataKunjungan.area}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Name
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">{dataKunjungan.dilaporkanOleh}</Col>
                  </Row>
                  <Row>
                    <Col sm="6" style={{ fontWeight: "bold" }}>
                      Date / Time
                    </Col>
                    <Col sm="0.5">:</Col>
                    <Col sm="5">
                      {moment(dataKunjungan.createdAt).format(
                        "DD MMM YYYY / h:mm:ss a"
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Branch Name</Card.Title>
                </Card.Header>
                <Card.Body>
                  <RegularMap />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

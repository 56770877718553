import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  TabContent,
  TabPane,
  Container,
  Collapse,
  Row,
  Col,
  Tab,
  Image,
} from "react-bootstrap";
// import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactDatetime from "react-datetime";
import logoBAP from "../assets/img/image.jpg";

import {
  getKpiMonitoringProblem,
  getKpiMonitoringKeluhan,
  getKpiMonitoringProject,
  getKpiMonitoringKunjungan,
  getKpi1Consumable,
  getKpi1Training,
  getKpiReportShift,
  getKpi2Pembinaan,
  getKpi2PembinaanPenampilan,
  getKpi1KunjunganPest,
  getKpi2TemuanPest,
  getKpi1ItProject,
  getKpi1Safety,
  getKpi1Sidak,
  getGrafikAbsen,
  getKpiPatroli,
  getKpi1VisitIssue,
  getKpi2ResponIssue,
  getKpi2Hardware,
  getKpi3Software,
  getKpi3Absensi,
  getKpiMonitoringSurvey,
  getTargetByMonth,
  getTargetByMonthCabang,
  getCabangAktif,
  getCabangDashboard,
  getAchievementPatroli,
  getAchievementShift,
  getKpiReportShiftDashboard,
  getKpiPatroliDashboard,
  getClient,
} from "../stores";
import DashboardBakalan from "./DashboardBakalan";
import { CardHeader } from "reactstrap";
function Panels() {
  const history = useHistory();
  const dispatch = useDispatch();
  const trainingPersonnel = useSelector((state) => state.trainingReducer);
  const listShift = useSelector((state) => state.shiftReportReducer);
  const pembinaan = useSelector((state) => state.pembinaanReducer);
  const penampilan = useSelector((state) => state.penampilanReducer);
  const safety = useSelector((state) => state.safetyReducer);
  const auth = useSelector((state) => state.authReducer);
  const sidak = useSelector((state) => state.sidakReducer);
  const absen = useSelector((state) => state.absenReducer);
  const monitoring = useSelector((state) => state.monitoringReducer);
  const target = useSelector((state) => state.targetReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  // const issue = useSelector((state) => state.industrialReducer);
  // const survey = useSelector((state) => state.surveyReducer);
  // const pestRodent = useSelector((state) => state.pestRodentReducer);
  // const projectIt = useSelector((state) => state.itProjectReducer);
  // const monitoring = useSelector((state) => state.monitoringReducer);
  // const consumable = useSelector((state) => state.consumableReducer);
  const date = new Date();
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );
  const [persenIsmCabang, setPersenIsmCabang] = React.useState("");
  const [smpTgl, setSmpTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 0, 0)
  );
  const [filter, setFilter] = React.useState({
    tahun: new Date().getFullYear(),
  });
  const [totalKpi, setTotalKpi] = React.useState("");
  const [dashboardUtama, setDashboardUtama] = React.useState(true);
  const [openBakalan, setOpenBakalan] = React.useState(false);
  const [cardUtama, setCardUtama] = React.useState(false);
  const [openCard, setOpenCard] = React.useState(false);
  const [kirimIdCabang, setKirimIdCabang] = React.useState("");
  const [client, setClient] = React.useState([]);
  const [listCabang, setListCabang] = React.useState([]);
  const [rekap, setRekap] = React.useState([]);
  const [targetGsc, setTargetGsc] = React.useState(0);
  const [targetMarket, setTargetMarket] = React.useState(0);
  const [persenTotal, setPersenTotal] = React.useState(0);
  const [persenGsc, setPersenGsc] = React.useState(0);
  const [persenMarket, setPersenMarket] = React.useState(0);
  const [cardGsc, setCardGsc] = React.useState(false);
  const [cardMarket, setCardMarket] = React.useState(false);
  // Card Patrol
  const [kpiPatrol, setKpiPatrol] = React.useState(null);

  React.useEffect(() => {
    getCabangAktif(dispatch);
    getClient(dispatch);

    getCabangDashboard(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getAchievementPatroli(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getAchievementShift(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1Training(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiReportShift(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1Safety(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2Pembinaan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi2PembinaanPenampilan(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpi1Sidak(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getGrafikAbsen(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getKpiPatroli(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });

    getTargetByMonth(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    getTargetByMonthCabang(dispatch, {
      dariTgl: dariTgl,
      smpTgl: smpTgl,
    });
    // getKpiMonitoringProblem(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpiMonitoringKeluhan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpiMonitoringProject(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpiMonitoringKunjungan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1Consumable(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2Pembinaan(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1KunjunganPest(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2TemuanPest(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1ItProject(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi1VisitIssue(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2ResponIssue(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi2Hardware(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi3Software(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
    // getKpi3Absensi(dispatch, {
    //   dariTgl: dariTgl,
    //   smpTgl: smpTgl,
    // });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getKpiMonitoringSurvey(dispatch, filter);
  }, [filter]);

  React.useEffect(() => {
    // monitoring.kpi1 &&
    // monitoring.kpi2 &&
    // monitoring.kpi4 &&
    // monitoring.kpi3 &&
    trainingPersonnel.kpi1 &&
    listShift.kpi &&
    safety.kpi1 &&
    pembinaan.kpi2 &&
    penampilan.kpiPenampilan &&
    // sidak.kpi1 &&
    absen.kpiAbsen &&
    monitoring.kpiPatroli
      ? // &&
        // trainingPersonnel.kpi2 &&
        // consumable.kpi1 &&
        // pestRodent.kpi1 &&
        // pestRodent.kpi2 &&
        // projectIt.kpi1 &&
        // safety.kpi1 &&
        // issue.kpi1 &&
        // issue.kpi2 &&
        // projectIt.kpi2 &&
        // projectIt.kpi3 &&
        // trainingPersonnel.kpi3 &&
        // survey.variablesurvey
        (setPersenIsmCabang(
          Math.ceil(
            (trainingPersonnel.kpi1.pencapaian +
              listShift.kpi.pencapaian +
              (safety.kpi1.F2 +
                safety.kpi1.FAC2 +
                safety.kpi1.LTI2 +
                safety.kpi1.MTC2 +
                safety.kpi1.NM2 +
                safety.kpi1.RWC2) /
                6 +
              pembinaan.kpi2.kejadian +
              penampilan.kpiPenampilan.kejadian +
              absen.kpiAbsen.pencapaian +
              monitoring.kpiPatroli.pencapaian) /
              7
          )
          // Math.ceil(
          //   (monitoring.kpi1.pencapaian +
          //     monitoring.kpi2.pencapaian +
          //     monitoring.kpi3.pencapaian +
          //     monitoring.kpi4.pencapaian +
          //     consumable.kpi1.pencapaian +
          //     trainingPersonnel.kpi1.pencapaian +
          //     trainingPersonnel.kpi2.pencapaian +
          //     pestRodent.kpi1.pencapaian +
          //     pestRodent.kpi2.pencapaian +
          //     projectIt.kpi1.pencapaian +
          //     safety.kpi1.pencapaian +
          //     issue.kpi1.pencapaian +
          //     issue.kpi2.pencapaian +
          //     projectIt.kpi2.pencapaian +
          //     projectIt.kpi3.pencapaian +
          //     trainingPersonnel.kpi3.pencapaian +
          //     survey.variablesurvey.pencapaian) /
          //     17
          // )
        ),
        setTotalKpi(
          trainingPersonnel.kpi1.pencapaian +
            listShift.kpi.pencapaian +
            safety.kpi1.F2 +
            safety.kpi1.FAC2 +
            safety.kpi1.LTI2 +
            safety.kpi1.MTC2 +
            safety.kpi1.NM2 +
            safety.kpi1.RWC2 +
            pembinaan.kpi2.kejadian +
            penampilan.kpiPenampilan.kejadian +
            absen.kpiAbsen.pencapaian +
            monitoring.kpiPatroli.pencapaian
          // monitoring.kpi1.pencapaian +
          //   monitoring.kpi2.pencapaian +
          //   monitoring.kpi3.pencapaian +
          //   monitoring.kpi4.pencapaian +
          //   consumable.kpi1.pencapaian +
          //   trainingPersonnel.kpi1.pencapaian +
          //   trainingPersonnel.kpi2.pencapaian +
          //   pestRodent.kpi1.pencapaian +
          //   pestRodent.kpi2.pencapaian +
          //   projectIt.kpi1.pencapaian +
          //   safety.kpi1.pencapaian +
          //   issue.kpi1.pencapaian +
          //   issue.kpi2.pencapaian +
          //   projectIt.kpi2.pencapaian +
          //   projectIt.kpi3.pencapaian +
          //   trainingPersonnel.kpi3.pencapaian +
          //   survey.variablesurvey.pencapaian
        ))
      : setPersenIsmCabang("100");
  });
  // React.useEffect(() => {
  //   setPersenIsmCabang(
  //     Math.round(
  //       (monitoring.kpiPatroli.pencapaian + listShift.kpi.pencapaian) / 2
  //     )
  //       ? Math.round(
  //           (monitoring.kpiPatroli.pencapaian + listShift.kpi.pencapaian) / 2
  //         )
  //       : 0
  //   );
  // });
  // React.useEffect(() => {
  //   if (
  //     target.getTargetByMonth.targetCabang &&
  //     target.getTargetByMonth.targetCabang
  //   ) {
  //     let tmp = target.getTargetByMonth.targetCabang.map((val, index) => {
  //       console.log(val, "???????????????????????");
  //       return {
  //         ...val,
  //       };
  //     });
  //     // setCabang(tmp); // pastikan ini tidak di-comment
  //   }
  // }, [target.getTargetByMonth]);
  React.useEffect(() => {
    let client = cabang.getClient.map((val, index) => {
      return { ...val, idClient: val.idClient };
    });
    setClient(client);
  }, [cabang.getClient]);
  React.useEffect(() => {
    // let tmp = cabang.getCabangDashboard.map((val, index) => {
    //   console.log(val, "????????????????????");
    //   return {
    //     ...val,
    //   };
    // });
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
      };
    });
    setListCabang(tmp);

    let targetGsc = 0;
    let targetMarket = 0;

    tmp.map((val) => {
      if (val.idClient === "73") {
        targetGsc += val.totalTarget;
      } else {
        targetMarket += val.totalTarget;
      }
    });
    setTargetGsc(targetGsc);
    setTargetMarket(targetMarket);

    let tmpShift = [];
    let tmpPatroli = [];
    let promises = [];

    for (let i = 0; i < tmp.length; i++) {
      let promise = getKpiReportShiftDashboard(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
        idCabangWeb: tmp[i].idCabang,
      }).then((results) => {
        tmpShift.push({
          idCabang: tmp[i].idCabang,
          pencapaianShift: results.data.pencapaian,
          targetShift: results.data.target,
        });
      });
      let promise2 = getKpiPatroliDashboard(dispatch, {
        dariTgl: dariTgl,
        smpTgl: smpTgl,
        idCabangWeb: tmp[i].idCabang,
      }).then((results) => {
        tmpPatroli.push({
          idCabang: tmp[i].idCabang,
          pencapaianPatroli: results.data.pencapaian,
          targetPatroli: results.data.target,
        });
      });
      promises.push(promise);
      promises.push(promise2);
      // promises2.push(promise2);
    }

    Promise.all(promises).then((resul) => {
      let gabungan = [];
      tmpShift.map((val) => {
        tmpPatroli.map((val2) => {
          if (val.idCabang === val2.idCabang) {
            const idClient = tmp.find(
              (c) => c.idCabang === val.idCabang
            )?.idClient;
            gabungan.push({
              idClient: idClient,
              idCabang: val.idCabang,
              capaianShift: val.pencapaianShift,
              targetShift: val.targetShift,
              capaianPatroli: val2.pencapaianPatroli,
              targetPatroli: val2.targetPatroli,
              persenCapaian: Math.round(
                (val.pencapaianShift + val2.pencapaianPatroli) / 2
              ),
              persenTarget: Math.round(
                (val.targetShift + val2.targetPatroli) / 2
              ),
            });
          }
        });
      });
      setRekap(gabungan);

      let totalPersenCapaian = 0;
      gabungan.map((val) => {
        totalPersenCapaian += val.persenCapaian;
      });
      setPersenTotal(Math.round(totalPersenCapaian / tmp.length));

      let capaianGsc = 0;
      let capaianMarket = 0;
      gabungan.map((val) => {
        if (val.idClient === "73") {
          capaianGsc += val.persenCapaian;
        } else {
          capaianMarket += val.persenCapaian;
        }
      });
      setPersenGsc(Math.round(capaianGsc / 2));
      setPersenMarket(Math.round(capaianMarket / 1));
    });
  }, [cabang.getCabangDashboard]);
  React.useEffect(() => {
    if (monitoring.kpiPatroli) {
      setKpiPatrol(monitoring.kpiPatroli.data);
    }
  }, [monitoring.kpiPatroli]);
  return (
    <>
      <Container fluid>
        {openBakalan ? (
          <Button
            style={{ backgroundColor: "#04293A" }}
            onClick={() => (setDashboardUtama(true), setOpenBakalan(false))}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Button>
        ) : null}

        {dashboardUtama ? (
          <>
            <Row>
              <Col md="12">
                <Card style={{ backgroundColor: "#04293A" }}>
                  {/* <Card.Header
                    className="d-none d-md-inline"
                    style={{ backgroundColor: "white" }}
                  >
                    <Card.Title as="h2">
                      <Row>
                        <Col md="8" className="p-3">
                          <b>
                            {auth.role === "gsc"
                              ? "ISM Performance Achievements GSC"
                              : auth.role === "market"
                              ? "ISM Performance Achievements Market"
                              : auth.role === "cabang"
                              ? "ISM Performance Achievements"
                              : "ISM National Performance Achievements"}
                          </b>
                        </Col>
                        <Col md="4" className="p-3 d-flex justify-content-end">
                          <Image
                            src={
                              "https://backoffice.bapguard.com/upload/logo_bap.png"
                            }
                            width="auto"
                            height="50px"
                          />
                        </Col>
                      </Row>
                    </Card.Title>
                  </Card.Header> */}
                  <Card.Body className="d-none d-md-inline">
                    <Row>
                      <Col
                        as="h2"
                        className="d-none d-lg-flex align-items-center "
                        style={{ color: "white" }}
                      >
                        {auth.role === "gsc"
                          ? "ISM Performance Achievements GSC"
                          : auth.role === "market"
                          ? "ISM Performance Achievements Market"
                          : auth.role === "cabang"
                          ? "ISM Performance Achievements"
                          : "ISM National Performance Achievements"}
                        {/* <i
                          className="fas fa-user-shield fa-5x"
                          style={{ color: "white" }}
                        ></i> */}
                      </Col>
                      {/* <Col lg="7" xs="12">
                        <div className="numbers d-flex justify-content-around">
                          <div className="d-flex flex-column align-items-center">
                            <p className="card-category">
                              <h3>
                                <b style={{ color: "white" }}>Achievement</b>
                              </h3>
                            </p>
                            <Card.Title
                              as="h2"
                              style={{
                                color:
                                  persenTotal < target.getTargetByMonth.target
                                    ? "#CD1818"
                                    : "#20c400",
                                fontFamily: "sans-serif",
                              }}
                            >
                              <b>{persenTotal}%</b>
                            </Card.Title>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <p className="card-category">
                              <h3>
                                <b style={{ color: "white" }}>Target</b>
                              </h3>
                            </p>
                            <Card.Title
                              as="h2"
                              style={{
                                color: "#20c400",
                                fontFamily: "sans-serif",
                              }}
                            >
                              <b>
                                {target.getTargetByMonth.target
                                  ? target.getTargetByMonth.target
                                  : 0}
                                %
                              </b>
                            </Card.Title>
                          </div>
                        </div>
                      </Col> */}
                      <Col md="4" className="p-3 d-flex justify-content-end">
                        <Image
                          src={
                            "https://ptbap.net/assets/images/logo/invert-bap.png"
                          }
                          width="auto"
                          height="50px"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3">
                <h4 className="title mt-2">Start Date</h4>
                <Form.Group>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                    }}
                    onChange={(e) => {
                      setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                    }}
                    value={dariTgl}
                    initialValue={dariTgl}
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>
              </Col>
              <Col md="3">
                <h4 className="title mt-2">End Date</h4>
                <Form.Group>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Date Picker Here",
                    }}
                    onChange={(e) => {
                      setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                    }}
                    value={smpTgl}
                    initialValue={smpTgl}
                    timeFormat={false}
                  ></ReactDatetime>
                </Form.Group>
              </Col>
            </Row>
            {auth.role === "all" ? (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Row>
                          <Col md="6">
                            <Card>
                              <Card.Header style={{ marginLeft: 15 }}>
                                <Row>
                                  <div className="text-center">
                                    <i className="fas fa-industry fa-2x"></i>
                                  </div>
                                  <Card.Title
                                    as="h4"
                                    style={{ marginLeft: 30 }}
                                  >
                                    PT. Karyadibya Mahardhika (JTI - GSC)
                                  </Card.Title>
                                </Row>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col xs="3">
                                    {/* <div className="text-center">
                                            {val.idClient === "73" ? (
                                              <i className="fas fa-industry fa-2x"></i>
                                            ) : (
                                              <i className="fas fa-warehouse fa-2x"></i>
                                            )}
                                          </div> */}
                                  </Col>
                                  {/* <Col xs="8">
                                          <div className="numbers d-flex justify-content-around">
                                            <div className="d-flex flex-column align-items-center">
                                              <p className="card-category">
                                                Achievement
                                              </p>
                                              <Card.Title as="h5">
                                                {val.idClient === "73"
                                                  ? persenGsc
                                                  : persenMarket}
                                                %
                                              </Card.Title>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                              <p className="card-category">
                                                Target
                                              </p>
                                              <Card.Title as="h5">
                                                {val.idClient === "73"
                                                  ? Math.round(targetGsc / 2)
                                                  : Math.round(
                                                      targetMarket / 1
                                                    )}
                                                %
                                              </Card.Title>
                                            </div>
                                          </div>
                                        </Col> */}
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <hr></hr>
                                <Row>
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      onClick={() => {
                                        setCardGsc(true);
                                      }}
                                      className="btn-wd btn-outline mr-1"
                                      type="button"
                                      variant="info"
                                    >
                                      Detail
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card>
                              <Card.Header style={{ marginLeft: 15 }}>
                                <Row>
                                  <div className="text-center">
                                    <i className="fas fa-warehouse fa-2x"></i>
                                  </div>
                                  <Card.Title
                                    as="h4"
                                    style={{ marginLeft: 30 }}
                                  >
                                    PT. Karyadibya Mahardhika (JTI - Market)
                                  </Card.Title>
                                </Row>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col xs="3">
                                    {/* <div className="text-center">
                                            {val.idClient === "73" ? (
                                              <i className="fas fa-industry fa-2x"></i>
                                            ) : (
                                              <i className="fas fa-warehouse fa-2x"></i>
                                            )}
                                          </div> */}
                                  </Col>
                                  {/* <Col xs="8">
                                          <div className="numbers d-flex justify-content-around">
                                            <div className="d-flex flex-column align-items-center">
                                              <p className="card-category">
                                                Achievement
                                              </p>
                                              <Card.Title as="h5">
                                                {val.idClient === "73"
                                                  ? persenGsc
                                                  : persenMarket}
                                                %
                                              </Card.Title>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                              <p className="card-category">
                                                Target
                                              </p>
                                              <Card.Title as="h5">
                                                {val.idClient === "73"
                                                  ? Math.round(targetGsc / 2)
                                                  : Math.round(
                                                      targetMarket / 1
                                                    )}
                                                %
                                              </Card.Title>
                                            </div>
                                          </div>
                                        </Col> */}
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <hr></hr>
                                <Row>
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      onClick={() => {
                                        setCardMarket(true);
                                      }}
                                      className="btn-wd btn-outline mr-1"
                                      type="button"
                                      variant="info"
                                    >
                                      Detail
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </Card>
                          </Col>
                          {/* {client.map((val) => {
                            return (
                              <>
                                <Col md="6">
                                  <Card>
                                    <Card.Header style={{ marginLeft: 15 }}>
                                      <Row>
                                        <div className="text-center">
                                          {val.idClient === "73" ? (
                                            <i className="fas fa-industry fa-2x"></i>
                                          ) : (
                                            <i className="fas fa-warehouse fa-2x"></i>
                                          )}
                                        </div>
                                        <Card.Title
                                          as="h4"
                                          style={{ marginLeft: 30 }}
                                        >
                                          {val.namaClient}
                                        </Card.Title>
                                      </Row>
                                    </Card.Header>
                                    <Card.Body>
                                      <Row>
                                        <Col xs="3"> */}
                          {/* <div className="text-center">
                                            {val.idClient === "73" ? (
                                              <i className="fas fa-industry fa-2x"></i>
                                            ) : (
                                              <i className="fas fa-warehouse fa-2x"></i>
                                            )}
                                          </div> */}
                          {/* </Col> */}
                          {/* <Col xs="8">
                                          <div className="numbers d-flex justify-content-around">
                                            <div className="d-flex flex-column align-items-center">
                                              <p className="card-category">
                                                Achievement
                                              </p>
                                              <Card.Title as="h5">
                                                {val.idClient === "73"
                                                  ? persenGsc
                                                  : persenMarket}
                                                %
                                              </Card.Title>
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                              <p className="card-category">
                                                Target
                                              </p>
                                              <Card.Title as="h5">
                                                {val.idClient === "73"
                                                  ? Math.round(targetGsc / 2)
                                                  : Math.round(
                                                      targetMarket / 1
                                                    )}
                                                %
                                              </Card.Title>
                                            </div>
                                          </div>
                                        </Col> */}
                          {/* </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                      <hr></hr>
                                      <Row>
                                        <Col className="d-flex justify-content-end">
                                          <Button
                                            onClick={() => {
                                              if (val.idClient === "73") {
                                                setCardGsc(true);
                                              } else {
                                                setCardMarket(true);
                                              }
                                            }}
                                            className="btn-wd btn-outline mr-1"
                                            type="button"
                                            variant="info"
                                          >
                                            Detail
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              </>
                            );
                          })} */}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  {cardGsc ? (
                    <Col md="6">
                      <Card style={{ border: "0" }}>
                        <Row>
                          <Col>
                            <Row>
                              <Col className="p-3 d-flex justify-content-between align-items-center">
                                <b>GSC</b>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  onClick={() => {
                                    setCardGsc(false);
                                  }}
                                >
                                  &times;
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          {listCabang.map((val) => {
                            if (val.idClient === "73") {
                              return (
                                <>
                                  <Col md="6">
                                    <Card>
                                      <Card.Header style={{ marginLeft: 15 }}>
                                        <Row>
                                          <div className="text-center">
                                            <i className="fas fa-industry fa-2x"></i>
                                          </div>
                                          <Card.Title
                                            as="h4"
                                            style={{ marginLeft: 25 }}
                                          >
                                            {val.namaCabang}
                                          </Card.Title>
                                        </Row>
                                      </Card.Header>
                                      <Card.Body>
                                        <Row>
                                          <Col xs="3">
                                            {/* <div className="text-center">
                                              <i className="fas fa-industry fa-2x"></i>
                                            </div> */}
                                          </Col>
                                          {/* <Col xs="8">
                                            <div className="numbers d-flex justify-content-around">
                                              <div className="d-flex flex-column align-items-center">
                                                <p className="card-category">
                                                  Achievement
                                                </p>
                                                <Card.Title as="h5">
                                                  {rekap.map((val2) => {
                                                    if (
                                                      val.idCabang ===
                                                      val2.idCabang
                                                    ) {
                                                      return val2.persenCapaian;
                                                    }
                                                  })}
                                                  %
                                                </Card.Title>
                                              </div>
                                              <div className="d-flex flex-column align-items-center">
                                                <p className="card-category">
                                                  Target
                                                </p>
                                                <Card.Title as="h5">
                                                  {" "}
                                                  {Math.round(
                                                    val.totalTarget
                                                  )}{" "}
                                                  %
                                                </Card.Title>
                                              </div>
                                            </div>
                                          </Col> */}
                                        </Row>
                                      </Card.Body>
                                      <Card.Footer>
                                        <hr></hr>
                                        <Row>
                                          <Col className="d-flex justify-content-end">
                                            <Button
                                              onClick={() => {
                                                setKirimIdCabang({
                                                  idCabang: val.idCabang,
                                                  idClient: val.idClient,
                                                });
                                                setDashboardUtama(false);
                                                setOpenBakalan(true);
                                              }}
                                              className="btn-wd btn-outline mr-1"
                                              type="button"
                                              variant="info"
                                            >
                                              Detail
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Card.Footer>
                                    </Card>
                                  </Col>
                                </>
                              );
                            }
                          })}
                          {/* <Col>
                            <Card>
                              <Card.Header>
                                <Card.Title as="h4">Bakalan</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col xs="3">
                                    <div className="text-center">
                                      <i className="fas fa-industry fa-2x"></i>
                                    </div>
                                  </Col>
                                  <Col xs="8">
                                    <div className="numbers d-flex justify-content-around">
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">
                                          Achievement
                                        </p>
                                        <Card.Title as="h5">
                                          {monitoring.kpiPatroli
                                            ? Math.ceil(
                                                monitoring.kpiPatroli.pencapaian
                                              )
                                            : "100"}{" "}
                                          %
                                        </Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">Target</p>
                                        <Card.Title as="h5">
                                          {" "}
                                          {
                                            target.getTargetByMonthCabang
                                              .targetBakalan
                                          }{" "}
                                          %
                                        </Card.Title>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <hr></hr>
                                <Row>
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      onClick={() => {
                                        setDashboardUtama(false);
                                        setOpenBakalan(true);
                                      }}
                                      className="btn-wd btn-outline mr-1"
                                      type="button"
                                      variant="info"
                                    >
                                      Detail
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </Card>
                          </Col>
                          <Col>
                            <Card>
                              <Card.Header>
                                <Card.Title as="h4">Pelem</Card.Title>
                              </Card.Header>
                              <Card.Body>
                                <Row>
                                  <Col xs="3">
                                    <div className="text-center">
                                      <i className="fas fa-industry fa-2x"></i>
                                    </div>
                                  </Col>
                                  <Col xs="8">
                                    <div className="numbers d-flex justify-content-around">
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">
                                          Achievement
                                        </p>
                                        <Card.Title as="h5">
                                          {monitoring.kpiPatroli
                                            ? Math.ceil(
                                                monitoring.kpiPatroli.pencapaian
                                              )
                                            : "100"}{" "}
                                          %
                                        </Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center">
                                        <p className="card-category">Target</p>
                                        <Card.Title as="h5">
                                          {
                                            target.getTargetByMonthCabang
                                              .targetPelem
                                          }
                                          %
                                        </Card.Title>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <hr></hr>
                                <Row>
                                  <Col className="d-flex justify-content-end">
                                    <Button
                                      onClick={() => {
                                        setDashboardUtama(false);
                                        setOpenPelem(true);
                                      }}
                                      className="btn-wd btn-outline mr-1"
                                      type="button"
                                      variant="info"
                                    >
                                      Detail
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Footer>
                            </Card>
                          </Col> */}
                        </Row>
                      </Card>
                    </Col>
                  ) : null}

                  {cardMarket ? (
                    <>
                      {cardGsc ? null : <Col md="6"></Col>}

                      <Col md="6">
                        <Card style={{ border: "0" }}>
                          <Row>
                            <Col>
                              <Row>
                                <Col className="p-3 d-flex justify-content-between align-items-center">
                                  <b>Market</b>
                                  <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={() => {
                                      setCardMarket(false);
                                    }}
                                  >
                                    &times;
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            {/* <Col> */}
                            {listCabang.map((val) => {
                              if (val.idClient === "143") {
                                return (
                                  <>
                                    <Col md="6">
                                      <Card>
                                        <Card.Header style={{ marginLeft: 15 }}>
                                          <Row>
                                            <div className="text-center">
                                              <i className="fas fa-warehouse fa-2x"></i>
                                            </div>
                                            <Card.Title
                                              as="h4"
                                              style={{ marginLeft: 30 }}
                                            >
                                              {val.namaCabang}
                                            </Card.Title>
                                          </Row>
                                        </Card.Header>
                                        <Card.Body>
                                          <Row>
                                            <Col xs="3">
                                              {/* <div className="text-center">
                                                <i className="fas fa-warehouse fa-2x"></i>
                                              </div> */}
                                            </Col>
                                            <Col xs="8">
                                              {/*  <div className="numbers d-flex justify-content-around">
                                                <div className="d-flex flex-column align-items-center">
                                                  <p className="card-category">
                                                    Achievement
                                                  </p>
                                                  <Card.Title as="h5">
                                                    {rekap.map((val2) => {
                                                      if (
                                                        val.idCabang ===
                                                        val2.idCabang
                                                      ) {
                                                        return val2.persenCapaian; 
                                                        // return Math.round(
                                                        //   ((val2.capaianShift +
                                                        //     val2.capaianPatroli) /
                                                        //     2) *
                                                        //     100
                                                        // );
                                                    //   }
                                                    // })}
                                                    {/* {monitoring.kpiPatroli
                                                ? Math.ceil(
                                                    monitoring.kpiPatroli
                                                      .pencapaian
                                                  )
                                                : "100"}{" "} */}
                                              {/* % */}
                                              {/* </Card.Title>
                                                </div>
                                                <div className="d-flex flex-column align-items-center">
                                                  <p className="card-category">
                                                    Target
                                                  </p>
                                                  <Card.Title as="h5">
                                                    {" "}
                                                    {Math.round(
                                                      parseInt(val.totalTarget)
                                                    )}{" "}
                                                    %
                                                  </Card.Title>
                                                </div>
                                              </div> */}
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                        <Card.Footer>
                                          <hr></hr>
                                          <Row>
                                            <Col className="d-flex justify-content-end">
                                              <Button
                                                onClick={() => {
                                                  setKirimIdCabang({
                                                    idCabang: val.idCabang,
                                                    idClient: val.idClient,
                                                  });
                                                  setDashboardUtama(false);
                                                  setOpenBakalan(true);
                                                }}
                                                className="btn-wd btn-outline mr-1"
                                                type="button"
                                                variant="info"
                                              >
                                                Detail
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Card.Footer>
                                      </Card>
                                    </Col>
                                  </>
                                );
                              }
                            })}
                            {/* <Card>
                          <Card.Header>
                            <Card.Title as="h4">Rungkut</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs="3">
                                <div className="text-center">
                                  <i className="fas fa-truck fa-2x"></i>
                                </div>
                              </Col>
                              <Col xs="8">
                                <div className="numbers d-flex justify-content-around">
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Achievement</p>
                                    <Card.Title as="h5">
                                      {monitoring.kpiPatroli
                                        ? Math.ceil(
                                            monitoring.kpiPatroli.pencapaian
                                          )
                                        : "100"}{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Target</p>
                                    <Card.Title as="h5">
                                      {" "}
                                      {
                                        target.getTargetByMonthCabang
                                          .targetRungkut
                                      }{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <hr></hr>
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <Button
                                  onClick={() => {
                                    setDashboardUtama(false);
                                    setOpenRungkut(true);
                                  }}
                                  className="btn-wd btn-outline mr-1"
                                  type="button"
                                  variant="info"
                                >
                                  Detail
                                </Button>
                              </Col>
                            </Row>
                          </Card.Footer>
                        </Card> */}
                            {/* </Col> */}
                            {/* <Col>
                        <Card>
                          <Card.Header>
                            <Card.Title as="h4">Jakarta</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs="3">
                                <div className="text-center">
                                  <i className="fas fa-truck fa-2x"></i>
                                </div>
                              </Col>
                              <Col xs="8">
                                <div className="numbers d-flex justify-content-around">
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Achievement</p>
                                    <Card.Title as="h5">
                                      {monitoring.kpiPatroli
                                        ? Math.ceil(
                                            monitoring.kpiPatroli.pencapaian
                                          )
                                        : "100"}{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Target</p>
                                    <Card.Title as="h5">
                                      {" "}
                                      {
                                        target.getTargetByMonthCabang
                                          .targetJakarta
                                      }{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <hr></hr>
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <Button
                                  onClick={() => {
                                    setDashboardUtama(false);
                                    setOpenJakarta(true);
                                  }}
                                  className="btn-wd btn-outline mr-1"
                                  type="button"
                                  variant="info"
                                >
                                  Detail
                                </Button>
                              </Col>
                            </Row>
                          </Card.Footer>
                        </Card>
                      </Col> */}
                          </Row>
                          {/* <Row>
                      <Col>
                        <Card>
                          <Card.Header>
                            <Card.Title as="h4">Bandung</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs="3">
                                <div className="text-center">
                                  <i className="fas fa-truck fa-2x"></i>
                                </div>
                              </Col>
                              <Col xs="8">
                                <div className="numbers d-flex justify-content-around">
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Achievement</p>
                                    <Card.Title as="h5">
                                      {monitoring.kpiPatroli
                                        ? Math.ceil(
                                            monitoring.kpiPatroli.pencapaian
                                          )
                                        : "100"}{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Target</p>
                                    <Card.Title as="h5">
                                      {" "}
                                      {
                                        target.getTargetByMonthCabang
                                          .targetBandung
                                      }{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <hr></hr>
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <Button
                                  onClick={() => {
                                    setDashboardUtama(false);
                                    setOpenBandung(true);
                                  }}
                                  className="btn-wd btn-outline mr-1"
                                  type="button"
                                  variant="info"
                                >
                                  Detail
                                </Button>
                              </Col>
                            </Row>
                          </Card.Footer>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <Card.Header>
                            <Card.Title as="h4">Yogyakarta</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xs="3">
                                <div className="text-center">
                                  <i className="fas fa-truck fa-2x"></i>
                                </div>
                              </Col>
                              <Col xs="8">
                                <div className="numbers d-flex justify-content-around">
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Achievement</p>
                                    <Card.Title as="h5">
                                      {monitoring.kpiPatroli
                                        ? Math.ceil(
                                            monitoring.kpiPatroli.pencapaian
                                          )
                                        : "100"}{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <p className="card-category">Target</p>
                                    <Card.Title as="h5">
                                      {" "}
                                      {
                                        target.getTargetByMonthCabang
                                          .targetYogyakarta
                                      }{" "}
                                      %
                                    </Card.Title>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <hr></hr>
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <Button
                                  onClick={() => {
                                    setDashboardUtama(false);
                                    setOpenYogyakarta(true);
                                  }}
                                  className="btn-wd btn-outline mr-1"
                                  type="button"
                                  variant="info"
                                >
                                  Detail
                                </Button>
                              </Col>
                            </Row>
                          </Card.Footer>
                          </Col>
                        </Row> */}
                        </Card>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </>
            ) : auth.role === "gsc" || auth.role === "market" ? (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Row>
                          {listCabang.map((val) => {
                            if (
                              val.idClient === "73" ||
                              val.idClient === "143"
                            ) {
                              return (
                                <>
                                  <Col md="4">
                                    <Card>
                                      <Card.Header>
                                        <Row>
                                          <Col xs="2">
                                            <div className="text-center">
                                              {val.idClient === "73" ? (
                                                <i className="fas fa-industry fa-2x"></i>
                                              ) : (
                                                <i className="fas fa-warehouse fa-2x"></i>
                                              )}
                                            </div>
                                          </Col>
                                          <Card.Title as="h4">
                                            {val.namaCabang}
                                          </Card.Title>
                                        </Row>
                                      </Card.Header>
                                      <Card.Body>
                                        <Row>
                                          {/* <Col xs="3">
                                            <div className="text-center">
                                              {val.idClient === "73" ? (
                                                <i className="fas fa-industry fa-2x"></i>
                                              ) : (
                                                <i className="fas fa-warehouse fa-2x"></i>
                                              )}
                                            </div>
                                          </Col> */}
                                          {/* <Col xs="8">
                                            <div className="numbers d-flex justify-content-around">
                                              <div className="d-flex flex-column align-items-center">
                                                <p className="card-category">
                                                  Achievement
                                                </p>
                                                <Card.Title as="h5">
                                                  {rekap.map((val2) => {
                                                    if (
                                                      val.idCabang ===
                                                      val2.idCabang
                                                    ) {
                                                      return val2.persenCapaian;

                                                      // return Math.round(
                                                      //   ((val2.capaianShift +
                                                      //     val2.capaianPatroli) /
                                                      //     2) *
                                                      //     100
                                                      // );
                                                    }
                                                  })}
                                                  {monitoring.kpiPatroli
                                                ? Math.ceil(
                                                    monitoring.kpiPatroli
                                                      .pencapaian
                                                  )
                                                : "100"}{" "}
                                                  %
                                                </Card.Title>
                                              </div>
                                              <div className="d-flex flex-column align-items-center">
                                                <p className="card-category">
                                                  Target
                                                </p>
                                                <Card.Title as="h5">
                                                  {" "}
                                                  {Math.round(
                                                    parseInt(val.totalTarget)
                                                  )}{" "}
                                                  %
                                                </Card.Title>
                                              </div>
                                            </div>
                                          </Col> */}
                                        </Row>
                                      </Card.Body>
                                      <Card.Footer>
                                        <hr></hr>
                                        <Row>
                                          <Col className="d-flex justify-content-end">
                                            <Button
                                              onClick={() => {
                                                setKirimIdCabang({
                                                  idCabang: val.idCabang,
                                                  idClient: val.idClient,
                                                });
                                                setDashboardUtama(false);
                                                setOpenBakalan(true);
                                              }}
                                              className="btn-wd btn-outline mr-1"
                                              type="button"
                                              variant="info"
                                            >
                                              Detail
                                            </Button>
                                          </Col>
                                        </Row>
                                      </Card.Footer>
                                    </Card>
                                  </Col>
                                </>
                              );
                            }
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col md="12">
                    <Card>
                      <Card.Header>
                        <Row>
                          <Col xs="1">
                            <div className="text-center">
                              <i className="fas fa-shield-alt text-primary fa-2x"></i>
                            </div>
                          </Col>
                          <Card.Title as="h4">Patrol</Card.Title>
                        </Row>
                      </Card.Header>
                      <Card.Body style={{ marginBlock: -10 }} md="4">
                        <hr></hr>
                        <Row className="justify-content-center align-items-center">
                          {kpiPatrol?.map((val) => {
                            return (
                              <>
                                <Row noGutters>
                                  <Col
                                    // xs="12"
                                    // md="8"
                                    className="p-2 d-flex flex-column justify-content-center align-items-center mx-auto"
                                  >
                                    <div className="d-flex flex-column align-items-center">
                                      <Card
                                        style={{
                                          width: "29rem",
                                          margin: "2px",
                                        }}
                                      >
                                        <Card.Header className="d-flex flex-column align-items-center">
                                          <p
                                            style={{
                                              color: "black",
                                              fontWeight: "bold",
                                            }}
                                            className="card-category"
                                          >
                                            {val.namaShift}
                                          </p>
                                        </Card.Header>
                                        <hr></hr>
                                        <Card.Body>
                                          <Row
                                            noGutters
                                            style={{ marginTop: -20 }}
                                          >
                                            <Col
                                              className="p-1 d-flex justify-content-center"
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 12,
                                                borderRight: "1px solid #ddd",
                                              }}
                                            >
                                              Achievement
                                            </Col>
                                            <Col
                                              className="p-1 d-flex justify-content-center"
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 12,
                                                borderRight: "1px solid #ddd",
                                              }}
                                            >
                                              Target
                                            </Col>
                                            <Col
                                              className="p-1 d-flex justify-content-center"
                                              style={{
                                                fontWeight: "bold",
                                                fontSize: 12,
                                              }}
                                            >
                                              Status
                                            </Col>
                                          </Row>
                                          <Row noGutters>
                                            <Col
                                              className="p-2 d-flex justify-content-center"
                                              style={{
                                                borderRight: "1px solid #ddd",
                                              }}
                                            >
                                              {val["Data Patroli"]}{" "}
                                            </Col>
                                            <Col
                                              className="p-2 d-flex justify-content-center"
                                              style={{
                                                borderRight: "1px solid #ddd",
                                              }}
                                            >
                                              {val["Target Patroli"]}{" "}
                                            </Col>
                                            <Col
                                              className="p-2 d-flex justify-content-center"
                                              style={{
                                                fontWeight: "bold",
                                                color:
                                                  val.hasilPatroli === "Below"
                                                    ? "red"
                                                    : val.hasilPatroli ===
                                                      "Meet"
                                                    ? "orange"
                                                    : "green",
                                              }}
                                            >
                                              {val.hasilPatroli}{" "}
                                            </Col>
                                          </Row>
                                          {/* <div
                                      className="numbers d-flex justify-content-around "
                                      style={{
                                        // backgroundColor: "green",
                                        margin: -15,
                                        marginBottom: 1,
                                      }}
                                    >
                                      <div className="d-flex flex-column align-items-center">
                                        <p
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                          }}
                                          className="card-category"
                                        >
                                          Achievement
                                        </p>
                                        <Card.Title as="h5"></Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center mx-3">
                                        <p
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                          }}
                                          className="card-category"
                                        >
                                          Target
                                        </p>
                                        <Card.Title as="h5"></Card.Title>
                                      </div>
                                      <div className="d-flex flex-column align-items-center mx-3">
                                        <p
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                          }}
                                          className="card-category"
                                        >
                                          Status
                                        </p>
                                        <Card.Title as="h5"></Card.Title>
                                      </div>
                                    </div> */}
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr></hr>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                history.push("/admin/detailPatroli");
                              }}
                              className="btn-wd btn-outline mr-1"
                              type="button"
                              variant="info"
                            >
                              Detail
                            </Button>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>

                  {/* <Col md="3">
                    <Card>
                      <Card.Header>
                        <Card.Title as="h4">Report Shift</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col xs="3">
                            <div className="text-center">
                              <i className="fas fa-tasks fa-2x"></i>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="numbers d-flex justify-content-around">
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Achievement</p>
                                <Card.Title as="h5">
                                  {listShift.kpi
                                    ? Math.ceil(listShift.kpi.pencapaian)
                                    : "100"}{" "}
                                  %
                                </Card.Title>
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Target</p>
                                <Card.Title as="h5">
                                  {listShift.kpi1 && listShift.kpi2
                                    ? Math.ceil(listShift.kpi.target)
                                    : "100"}{" "}
                                  %
                                </Card.Title>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr></hr>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                history.push("/admin/chartShift");
                              }}
                              className="btn-wd btn-outline mr-1"
                              type="button"
                              variant="info"
                            >
                              Detail
                            </Button>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card>
                      <Card.Header>
                        <Card.Title as="h4">Attendance</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col xs="3">
                            <div className="text-center">
                              <i className="fas fa-address-book text-success fa-2x"></i>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="numbers d-flex justify-content-around">
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Achievement</p>
                                <Card.Title as="h5">
                                  {absen.kpiAbsen
                                    ? Math.ceil(absen.kpiAbsen.pencapaian)
                                    : "100"}{" "}
                                  %
                                </Card.Title>
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Target</p>
                                <Card.Title as="h5"> 100 %</Card.Title>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr></hr>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                history.push("/admin/chartAbsen");
                              }}
                              className="btn-wd btn-outline mr-1"
                              type="button"
                              variant="info"
                            >
                              Detail
                            </Button>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card>
                      <Card.Header>
                        <Card.Title as="h4">Training</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col xs="3">
                            <div className="text-center">
                              <i className="fas fa-user-tie fa-2x"></i>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="numbers d-flex justify-content-around">
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Achievement</p>
                                <Card.Title as="h5">
                                  {trainingPersonnel.kpi1
                                    ? Math.ceil(
                                        trainingPersonnel.kpi1.pencapaian
                                      )
                                    : "100"}{" "}
                                  %
                                </Card.Title>
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Target</p>
                                <Card.Title as="h5">
                                  {trainingPersonnel.kpi1 &&
                                  trainingPersonnel.kpi2
                                    ? Math.ceil(
                                        (trainingPersonnel.kpi1.target +
                                          trainingPersonnel.kpi2.target) /
                                          2
                                      )
                                    : "100"}{" "}
                                  %
                                </Card.Title>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr></hr>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                history.push("/admin/chartTraining");
                              }}
                              className="btn-wd btn-outline mr-1"
                              type="button"
                              variant="info"
                            >
                              Detail
                            </Button>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card>
                      <Card.Header>
                        <Card.Title as="h4">Safety</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col xs="3">
                            <div className="text-center">
                              <i className="fas fa-hard-hat text-warning fa-2x"></i>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="numbers d-flex justify-content-around">
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Achievement</p>
                                <Card.Title as="h5">
                                  {safety.kpi1
                                    ? Math.ceil(
                                        (safety.kpi1.F2 +
                                          safety.kpi1.FAC2 +
                                          safety.kpi1.LTI2 +
                                          safety.kpi1.MTC2 +
                                          safety.kpi1.NM2 +
                                          safety.kpi1.RWC2) /
                                          6
                                      )
                                    : "100"}{" "}
                                  %
                                </Card.Title>
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Target</p>
                                <Card.Title as="h5"> 100 %</Card.Title>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr></hr>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                history.push("/admin/chartSafety");
                              }}
                              className="btn-wd btn-outline mr-1"
                              type="button"
                              variant="info"
                            >
                              Detail
                            </Button>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card>
                      <Card.Header>
                        <Card.Title as="h4">Mentoring</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col xs="3">
                            <div className="text-center">
                              <i className="fas fa-file-alt fa-2x"></i>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="numbers d-flex justify-content-around">
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Achievement</p>
                                <Card.Title as="h5">
                                  {pembinaan.kpi2
                                    ? Math.ceil(pembinaan.kpi2.kejadian)
                                    : "100"}{" "}
                                  %
                                </Card.Title>
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Target</p>
                                <Card.Title as="h5">
                                  {pembinaan.kpi2
                                    ? Math.ceil(pembinaan.kpi2.target)
                                    : "100"}{" "}
                                  %
                                </Card.Title>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr></hr>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                history.push("/admin/chartPembinaan");
                              }}
                              className="btn-wd btn-outline mr-1"
                              type="button"
                              variant="info"
                            >
                              Detail
                            </Button>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card>
                      <Card.Header>
                        <Card.Title as="h4">Inspection</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col xs="3">
                            <div className="text-center">
                              <i className="fas fa-search text-primary fa-2x"></i>
                            </div>
                          </Col>
                          <Col xs="8">
                            <div className="numbers d-flex justify-content-around">
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Achievement</p>
                                <Card.Title as="h5">
                                  {sidak.kpi1
                                    ? Math.ceil(sidak.kpi1.pencapaian)
                                    : "100"}{" "}
                                  %
                                </Card.Title>
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <p className="card-category">Target</p>
                                <Card.Title as="h5"> 100 %</Card.Title>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr></hr>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                history.push("/admin/chartSidak");
                              }}
                              className="btn-wd btn-outline mr-1"
                              type="button"
                              variant="info"
                            >
                              Detail
                            </Button>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col> */}
                </Row>
                <Row></Row>
              </>
            )}
          </>
        ) : null}
      </Container>
      {openBakalan ? <DashboardBakalan idCabang={kirimIdCabang} /> : null}
    </>
  );
}

export default Panels;

const initialState = {
  listAbsen: [],
  kpiAbsen: null,
  getPersonnel: [],
};

const absenReducer = (state = initialState, action) => {
  let { type, data } = action;

  switch (type) {
    case "SET_LISTABSEN":
      return {
        ...state,
        listAbsen: data,
      };

    case "SET_KPIABSEN":
      return {
        ...state,
        kpiAbsen: data,
      };
    case "SET_KPIABSEN":
      return {
        ...state,
        kpiAbsen: data,
      };
    case "SET_LISTPERSONNEL":
      return {
        ...state,
        getPersonnel: data,
      };

    default:
      return state;
  }
};

export default absenReducer;

import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Modal,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import { addShift, getListShift } from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import TagsInput from "components/TagsInput/TagsInput.js";

function RegularTables() {
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState(false);
  const [namaShift, setNamaShift] = React.useState("");
  const [durasi, setDurasi] = React.useState("");
  const [jamStr, setJamStr] = React.useState([]);
  const listShift = useSelector((state) => state.shiftReportReducer);
  React.useEffect(() => {
    getListShift(dispatch);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Button
              className="btn-wd mr-1"
              onClick={() => {
                setModal(true);
                setNamaShift("");
                setDurasi("");
                setJamStr([]);
              }}
              variant="primary"
            >
              Add Shift
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Shift Monitoring</Card.Title>
              </Card.Header>
              <Card.Body className="table-responsive p-0">
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Time (Clock)</th>
                      <th>Duration (Hours)</th>
                      {/* <th>Status</th> */}
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {listShift.listShiftPatroli.map((val, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{val.namaShift}</td>
                          <td>
                            {val.jam
                              .map((val, index) => {
                                if (index === 0) {
                                  if (val < 10) {
                                    return "0" + val + ":00";
                                  } else {
                                    return val + ":00";
                                  }
                                } else {
                                  if (val < 10) {
                                    return "0" + val + ":00";
                                  } else {
                                    return val + ":00";
                                  }
                                }
                              })
                              .join("-")}
                          </td>
                          <td>{val.durasi}</td>
                          {/* <td>{val.keadaan === true ? "Running" : "Done"}</td> */}
                          {/* <td>
                            {val.keadaan === true ? (
                              <Button
                                className="btn-wd mr-1"
                                onClick={() => {
                                  console.log(
                                    listShift.listShiftPatroli[index - 1]
                                  );
                                  console.log(val);
                                }}
                                variant="danger"
                              >
                                Stop Shift
                              </Button>
                            ) : (
                              "Done"
                            )}
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Card className="stacked-form">
                <Card.Header>
                  <Card.Title as="h4">Add Shift</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form action="#" method="#">
                    <Form.Group>
                      <label>Shift Name</label>
                      <Form.Control
                        // placeholder="Durasi pengerjaan"
                        type="text"
                        onChange={(e) => {
                          setNamaShift(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <label>Finishing Time</label>
                      <TagsInput
                        value={jamStr}
                        onChange={(e) => {
                          if (
                            (e.length > 0 &&
                              !isNaN(e[e.length - 1]) &&
                              Number(e[e.length - 1]) >= 0 &&
                              Number(e[e.length - 1]) <= 24) ||
                            e.length === 0
                          ) {
                            setJamStr(e);
                          }
                        }}
                        tagProps={{
                          className: "react-tagsinput-tag tag-azure",
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>Duration</label>
                      <Form.Control
                        // placeholder="Durasi pengerjaan"
                        type="text"
                        onChange={(e) => {
                          setDurasi(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <Button
                    onClick={() => {
                      var jam = jamStr.map((i) => Number(i));
                      // console.log(namaShift);
                      addShift({
                        namaShift: namaShift,
                        durasi: durasi,
                        jam: JSON.stringify(jam),
                      });
                    }}
                    className="btn-fill"
                    type="submit"
                    variant="info"
                  >
                    Submit
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RegularTables;

import React from "react";
import Swal from "sweetalert2";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
} from "react-bootstrap";
import Select from "react-select";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  getSidak,
  tambahSidak,
  editSidak,
  kirimReportInspection,
  getCabangAktif,
  cekSidak,
} from "../../../stores";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";

function ReactTables() {
  const dispatch = useDispatch();
  const date = new Date();
  const sidak = useSelector((state) => state.sidakReducer);
  const cabang = useSelector((state) => state.cabangReducer);
  const [modal, setModal] = React.useState(false);
  const [modalSidak, setModalSidak] = React.useState(false);
  const [modalEditSidak, setModalEditSidak] = React.useState(false);
  const [modalApprove, setModalApprove] = React.useState(false);
  const [dataSidak, setDataSidak] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [judul, setJudul] = React.useState("");
  const [sidakId, setSidakId] = React.useState("");
  const [deskripsiSidak, setDeskripsiSidak] = React.useState("");
  const [noteInspection, setNoteInspection] = React.useState("");
  const [gambar, setGambar] = React.useState([]);
  const [modalPreviewFoto, setModalPreviewFoto] = React.useState(false);
  const [dariTgl, setDariTgl] = React.useState(
    new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
  );
  const [smpTgl, setSmpTgl] = React.useState(new Date().setHours(23, 59, 0, 0));
  const respon = "Done";
  const [client, setClient] = React.useState({
    value: "",
    label: "Choose the Client",
    isDisabled: true,
  });
  const [tipeKunjungan, setTipeKunjungan] = React.useState({
    value: "",
    label: "Choose the Type of Visit",
    isDisabled: true,
  });
  const [tipeSidak, setTipeSidak] = React.useState({
    value: "",
    label: "Choose the Type of Activity",
    isDisabled: true,
  });
  const [type, setType] = React.useState({
    value: "",
    label: "Choose the Type Activity",
    isDisabled: true,
  });
  const [tglPelaksanaan, setTglPelaksanaan] = React.useState(new Date());

  const [dropTipeSidak, setDropTipeSidak] = React.useState(false);
  const [dropTipeKunjungan, setDropTipeKunjungan] = React.useState(false);
  const [formSidak, setFormSidak] = React.useState(false);
  const [detailTipeKunjungan, setDetailTipeKunjungan] = React.useState(false);
  const [listCabang, setListCabang] = React.useState([]);
  React.useEffect(() => {
    getCabangAktif(dispatch);
  }, []);
  const validDate = (current) => {
    // Hanya izinkan tanggal hari ini dan setelahnya
    return current.isSameOrAfter(new Date(), "day");
  };
  //   const [status, setStatus] = React.useState("");
  //   const [deskripsi, setDeskripsi] = React.useState("");
  //   console.log(Visit);
  //   function onsubmit(e) {
  //     e.preventDefault();
  //     let fd = new FormData();
  //     fd.append("VisitId", dataVisit._id);
  //     fd.append("respon", status);
  //     fd.append("alasan", deskripsi);

  //     verifikasiVisit(fd);
  //   }

  // console.log(monitoring.listProjectAll);
  const handleTypeChange = (selectedOption) => {
    setType(selectedOption);
    // Reset state sebelum mengatur kondisi baru
    setDropTipeSidak(false);
    setDropTipeKunjungan(false);

    if (selectedOption.value === "Inspection") {
      setDropTipeSidak(true);
    } else if (selectedOption.value === "Visit") {
      setDropTipeKunjungan(true);
    }
  };

  React.useEffect(() => {
    let tmp = cabang.getCabangAktif.map((val, index) => {
      return {
        ...val,
        label: `${val.namaCabang} - ${val.namaClient}`,
        value: val.idCabang,
      };
    });
    setListCabang(tmp);
  }, [cabang.getCabangAktif]);
  // function submitSidak(e) {
  //   e.preventDefault();

  //   tambahSidak({
  //     idCabang: client.idCabang,
  //     type: type.value,
  //     tipeSidak: type.value === "Inspection" ? tipeSidak.value : "Offline",
  //     tipeKunjungan: type.value === "Inspection" ? "-" : tipeKunjungan.value,
  //     judulSidak: judul,
  //     deskripsi: deskripsiSidak,
  //     startDate: new Date(dariTgl).toISOString(),
  //     deadlineDate: new Date(smpTgl).toISOString(),
  //   });
  // }

  function submitSidak(e) {
    e.preventDefault();

    Swal.fire({
      title: "Konfirmasi",
      text: "Apakah Anda yakin ingin melakukan rencana sidak atau management visit?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        tambahSidak({
          idCabang: client.idCabang,
          type: type.value,
          tipeSidak: type.value === "Inspection" ? tipeSidak.value : "Offline",
          tipeKunjungan:
            type.value === "Inspection" ? "-" : tipeKunjungan.value,
          judulSidak: judul,
          deskripsi: deskripsiSidak,
          startDate: new Date(dariTgl).toISOString(),
          deadlineDate: new Date(smpTgl).toISOString(),
        }).then((response) => {
          if (response.status === 200) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.message,
              showConfirmButton: false,
              timer: 1500,
            });
            getSidak(dispatch);
            setModalSidak(false);
          } else if (response.status === 400) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
          }
        });
      }
    });
  }

  function edit(e) {
    e.preventDefault();
    editSidak({
      sidakId: sidakId,
      startDate: new Date(dariTgl).toISOString(),
      deadlineDate: new Date(smpTgl).toISOString(),
    });
  }

  function submitReportSidak(e) {
    e.preventDefault();
    kirimReportInspection({
      noteInspection,
      gambar,
      sidakId,
      respon,
      tglPelaksanaan,
    });
  }

  function handleOpenModal() {
    setFormSidak(false);
    setClient({
      value: "",
      label: "Choose the Client",
      isDisabled: true,
    });

    setType({
      value: "",
      label: "Choose the Type Activity",
      isDisabled: true,
    });
    setTipeKunjungan({
      value: "",
      label: "Choose the Type of Visit",
      isDisabled: true,
    });
    setTipeSidak({
      value: "",
      label: "Choose the Type Activity",
      isDisabled: true,
    });
    setDropTipeKunjungan(false);
    setDropTipeSidak(false);
    setJudul("");
    setDeskripsiSidak("");
    setDariTgl(
      new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
    );
    setSmpTgl(new Date().setHours(23, 59, 0, 0));
  }
  function cekTipeKunjungan() {
    if (type.value === "Inspection") {
      setFormSidak(true);
    } else {
      if (tipeKunjungan.value === "Non Management Visit") {
        setFormSidak(true);
      } else {
        cekSidak(dispatch, {
          // dariTgl: new Date(dariTgl).toISOString(),
          // smpTgl: new Date(smpTgl).toISOString(),
          dariTgl: dariTgl,
          smpTgl: smpTgl,
          idCabangWeb: client.idCabang,
        }).then((respon) => {
          if (respon.status === 200) {
            setFormSidak(true);
          } else {
            Swal.fire({
              title: respon.data.message,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
            }).then((result) => {
              if (result.isConfirmed) {
                setFormSidak(true);
              }
            });
          }
        });
      }
    }
  }
  function handleOpenModalReport() {
    setTglPelaksanaan(new Date());
    setNoteInspection("");
    setGambar([]);
  }
  function handleOpenModalEdit() {
    setDariTgl(
      new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
    );
    setSmpTgl(new Date().setHours(23, 59, 0, 0));
  }
  const history = useHistory();
  React.useEffect(() => {
    let tmp = sidak.listSidak.map((val, index) => {
      return {
        ...val,
        tglMulai: moment(val.startDate).format("DD MMM YYYY / h:mm:ss a"),
        tglSelesai: moment(val.deadlineDate).format("DD MMM YYYY / h:mm:ss a"),
        actions:
          val.status == "On Progress" ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModalEditSidak(true);
                  setSidakId(val._id);
                  handleOpenModalEdit();
                }}
                variant="info"
                size="md"
                className="btn-link remove text-info"
                disabled={val.edited === true}
              >
                <i className="fa fa-edit fa-lg" />
              </Button>
              <Button
                onClick={() => {
                  setModalApprove(true);
                  setSidakId(val._id);
                  handleOpenModalReport();
                }}
                variant="success"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-check fa-lg" />
              </Button>{" "}
              <Button
                onClick={() => {
                  setModal(true);
                  setDataSidak(val);
                }}
                variant="warning"
                size="md"
                className="btn-link remove text-warning"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : val.solvedInTime === true ? (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataSidak(val);
                  if (val.type === "Visit") {
                    setDetailTipeKunjungan(true);
                  } else if (val.type === "Inspection") {
                    setDetailTipeKunjungan(false);
                  }
                }}
                variant="success"
                size="md"
                className="btn-link remove text-success"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ) : (
            <div className="actions-right">
              <Button
                onClick={() => {
                  setModal(true);
                  setDataSidak(val);
                  if (val.type === "Visit") {
                    setDetailTipeKunjungan(true);
                  } else if (val.type === "Inspection") {
                    setDetailTipeKunjungan(false);
                  }
                }}
                variant="danger"
                size="md"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-info-circle fa-lg" />
              </Button>{" "}
            </div>
          ),
      };
    });
    setData(tmp);
  }, [sidak.listSidak]);

  React.useEffect(() => {
    getSidak(dispatch);
  }, []);

  return (
    <>
      <div
        id="previewImage"
        style={{
          display: modalPreviewFoto ? "block" : "none",
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: "9999",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={modalPreviewFoto}
          alt="Preview"
          style={{
            borderRadius: "5px",
            boxShadow: "4px 4px 8px 0px rgba(0,0,0,0.75)",
            maxHeight: "80%",
            maxWidth: "80%",
            objectFit: "contain",
            background: "transparent",
            position: "absolute",
            top: "50%", // Posisi vertikal ke tengah
            left: "50%", // Posisi horizontal ke tengah
            transform: "translate(-50%, -50%)",
          }}
        />
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => setModalPreviewFoto("")}
        >
          &times;
        </button>
      </div>
      <Container fluid>
        {/* <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModal(true);
          }}
          variant="primary"
        >
          Pengajuan Project
        </Button> */}
        <Button
          className="btn-wd mr-1"
          onClick={() => {
            setModalSidak(true);
            handleOpenModal();
          }}
          variant="primary"
        >
          Add Inspection or Visit Plan
        </Button>
        <Row>
          <Col md="12">
            <h4 className="title">Inspection and Management Visit Detail</h4>

            <Card>
              <Card.Body>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      Header: "Title",
                      accessor: "judulSidak",
                    },
                    {
                      Header: "Submitter",
                      accessor: "diajukanOleh",
                    },
                    {
                      Header: "Activity",
                      accessor: "type",
                    },
                    {
                      Header: "Type",
                      accessor: "tipeSidak",
                    },
                    {
                      Header: "Start Date",
                      accessor: "tglMulai",
                    },
                    {
                      Header: "End Date",
                      accessor: "tglSelesai",
                    },
                    {
                      Header: "Branch",
                      accessor: "namaCabang",
                    },
                    {
                      Header: "Client",
                      accessor: "namaClient",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className="-striped -highlight primary-pagination"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ fontWeight: "bold" }}
          >
            Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Title
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSidak.judulSidak}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Submitter
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSidak.diajukanOleh}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Activity
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSidak.type}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Type of Activity
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSidak.tipeSidak}</Col>
                    </Row>
                    {/* {detailTipeKunjungan && ( */}
                    <>
                      <Row>
                        <Col sm="6" style={{ fontWeight: "bold" }}>
                          Type of Visit
                        </Col>
                        <Col sm="0.5">:</Col>
                        <Col sm="5">{dataSidak.tipeKunjungan}</Col>
                      </Row>
                    </>
                    {/* )} */}

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Branch
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSidak.namaCabang}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Client
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSidak.namaClient}</Col>
                    </Row>

                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Start Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataSidak.startDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        End Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {moment(dataSidak.deadlineDate).format(
                          "DD MMM YYYY / h:mm:ss a"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Description
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSidak.deskripsi}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Status
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">{dataSidak.status}</Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        Solve Date
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!dataSidak.solvedDate
                          ? "-"
                          : moment(dataSidak.solvedDate).format(
                              "DD MMM YYYY / h:mm:ss a"
                            )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        On Schedule
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {dataSidak.status != "Done"
                          ? "-"
                          : dataSidak.solvedInTime
                          ? "Ya"
                          : "Tidak"}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ fontWeight: "bold" }}>
                        {" "}
                        Note
                      </Col>
                      <Col sm="0.5">:</Col>
                      <Col sm="5">
                        {!dataSidak.keterangan ? "-" : dataSidak.keterangan}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                      <Col
                        sm="12"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        Final Documentation
                      </Col>
                    </Row>
                    <Row>
                      {!dataSidak.buktiSelesai ||
                      dataSidak.buktiSelesai.length == 0 ? (
                        <Col sm="12" style={{ textAlign: "center" }}>
                          Final Documentation is Not Available
                        </Col>
                      ) : (
                        dataSidak.buktiSelesai.map((val, index) => {
                          return (
                            <>
                              <Col sm="4">
                                <Image
                                  src={val}
                                  style={{
                                    width: 200,
                                    height: 250,
                                    margin: 20,
                                  }}
                                  thumbnail
                                  onClick={() => {
                                    setModalPreviewFoto(val);
                                  }}
                                />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalSidak}
        onHide={() => {
          setModalSidak(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Add Inspection or Visit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Client's Name</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            isDisabled={formSidak === true ? true : false}
                            onChange={(value) => {
                              setClient(value);
                            }}
                            value={client}
                            options={[
                              {
                                value: "",
                                label: "Choose the Client",
                                isDisabled: true,
                              },
                              ...listCabang,
                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label>Activity</label>
                          <Select
                            className="react-select primary pb-2"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            onChange={handleTypeChange}
                            isDisabled={formSidak === true ? true : false}
                            value={type}
                            options={[
                              {
                                value: "",
                                label: "Choose the Type",
                                isDisabled: true,
                              },
                              {
                                value: "Inspection",
                                label: "Inspection",
                              },
                              {
                                value: "Visit",
                                label: "Visit",
                              },

                              "-",
                            ]}
                            placeholder="Single Select"
                          />
                        </Form.Group>

                        {dropTipeSidak && (
                          <>
                            <Form.Group>
                              <label>Type of Activity</label>
                              <Select
                                className="react-select primary pb-2"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                isDisabled={formSidak === true ? true : false}
                                onChange={(value) => {
                                  setTipeSidak(value);
                                }}
                                value={tipeSidak}
                                options={[
                                  {
                                    value: "",
                                    label: "Choose the Type of Activity",
                                    isDisabled: true,
                                  },
                                  {
                                    value: "Online",
                                    label: "Online",
                                  },
                                  {
                                    value: "Offline",
                                    label: "Offline",
                                  },

                                  "-",
                                ]}
                                placeholder="Single Select"
                              />
                            </Form.Group>
                          </>
                        )}
                        {dropTipeKunjungan && (
                          <>
                            <Form.Group>
                              <label>Type of Visit</label>
                              <Select
                                className="react-select primary pb-2"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                isDisabled={formSidak === true ? true : false}
                                onChange={(value) => {
                                  setTipeKunjungan(value);
                                }}
                                value={tipeKunjungan}
                                options={[
                                  {
                                    value: "",
                                    label: "Choose the Type Visit",
                                    isDisabled: true,
                                  },
                                  {
                                    value: "Management Visit",
                                    label: "Management Visit",
                                  },
                                  {
                                    value: "Non Management Visit",
                                    label: "Non Management Visit",
                                  },

                                  "-",
                                ]}
                                placeholder="Single Select"
                              />
                            </Form.Group>
                          </>
                        )}
                        <Row>
                          <Col sm="6">
                            <label>Start Date</label>
                            <Form.Group
                              style={{
                                pointerEvents:
                                  formSidak === true ? "none" : "auto",
                                opacity: formSidak ? 0.5 : 1,
                              }}
                            >
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                }}
                                onChange={(e) => {
                                  setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                                }}
                                value={dariTgl}
                                initialValue={dariTgl}
                                disabled={formSidak}
                                timeFormat={false}
                              ></ReactDatetime>
                            </Form.Group>
                          </Col>
                          <Col sm="6">
                            <label>End Date</label>
                            <Form.Group
                              style={{
                                pointerEvents:
                                  formSidak === true ? "none" : "auto",
                                opacity: formSidak ? 0.5 : 1,
                              }}
                            >
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Date Picker Here",
                                }}
                                disabled={formSidak}
                                onChange={(e) => {
                                  setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                                }}
                                value={smpTgl}
                                initialValue={smpTgl}
                                timeFormat={false}
                              ></ReactDatetime>
                            </Form.Group>
                          </Col>
                        </Row>
                        {formSidak === true ? (
                          ""
                        ) : (
                          <>
                            <Button
                              className="btn-fill pull-right"
                              // type="submit"
                              variant="info"
                              disabled={
                                client.value === "" &&
                                tipeKunjungan.value === "" &&
                                tipeSidak.value === ""
                              }
                              style={{
                                backgroundColor:
                                  client.value === "" &&
                                  tipeKunjungan.value === "" &&
                                  tipeSidak.value === ""
                                    ? "#d3d3d3"
                                    : "", // Warna abu-abu ketika dinonaktifkan
                                borderColor:
                                  client.value === "" &&
                                  tipeKunjungan.value === "" &&
                                  tipeSidak.value === ""
                                    ? "#d3d3d3"
                                    : "", // Warna abu-abu ketika dinonaktifkan
                                color:
                                  client.value === "" &&
                                  tipeKunjungan.value === "" &&
                                  tipeSidak.value === ""
                                    ? "#fff"
                                    : "", // Warna teks ketika dinonaktifkan
                                cursor:
                                  client.value === "" &&
                                  tipeKunjungan.value === "" &&
                                  tipeSidak.value === ""
                                    ? "not-allowed"
                                    : "pointer", // Menunjukkan bahwa tombol tidak bisa diklik
                              }}
                              onClick={() => {
                                cekTipeKunjungan();
                              }}
                            >
                              Submit
                            </Button>
                          </>
                        )}
                      </Col>
                      {formSidak && (
                        <>
                          <Col sm="12">
                            <Form.Group>
                              <label>Title</label>
                              <Form.Control
                                onChange={(e) => {
                                  setJudul(e.target.value);
                                  // setDataBaru({
                                  //   ...dataBaru,
                                  //   gedung: e.target.value,
                                  // });
                                }}
                                // placeholder="Masukan Nama Gedung"
                                type="text"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col sm="12">
                            <Form.Group>
                              <label>Description</label>
                              <Form.Control
                                onChange={(e) => {
                                  setDeskripsiSidak(e.target.value);
                                  // setProject({
                                  //   ...project,
                                  //   deskripsi: e.target.value,
                                  // });
                                }}
                                // placeholder="Tuliskan Deskripsi Project"
                                as="textarea"
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Button
                              className="btn-fill pull-right"
                              type="submit"
                              variant="info"
                              onClick={submitSidak}
                            >
                              Submit
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalEditSidak}
        onHide={() => {
          setModalEditSidak(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Edit</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="6">
                        <label>Start Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={dariTgl}
                            initialValue={dariTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                      <Col sm="6">
                        <label>End Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={smpTgl}
                            initialValue={smpTgl}
                            timeFormat={false}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={edit}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalApprove}
        onHide={() => {
          setModalApprove(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title as="h4">Report Inspection</Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <label>Implimentation Date</label>
                        <Form.Group>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                            }}
                            onChange={(e) => {
                              setTglPelaksanaan(e.toDate());
                            }}
                            value={tglPelaksanaan}
                            initialValue={tglPelaksanaan}
                            // isValidDate={validDate}
                            timeFormat={true}
                          ></ReactDatetime>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label>Note</label>
                          <Form.Control
                            onChange={(e) => {
                              setNoteInspection(e.target.value);
                              // setProject({
                              //   ...project,
                              //   deskripsi: e.target.value,
                              // });
                            }}
                            // placeholder="Tuliskan Deskripsi Project"
                            as="textarea"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <label
                      className="label-input-file btn btn-danger text-white mb-4"
                      for="import2"
                    >
                      Add Photo
                      <input
                        type="file"
                        id="import2"
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                        onChange={(e) => {
                          setGambar([...gambar, ...Array.from(e.target.files)]);
                        }}
                        style={{ display: "none" }}
                        accept="image/*"
                        multiple
                      />
                    </label>
                    {gambar.map((val, index) => {
                      return (
                        <div className="d-flex align-items-start">
                          <p>{val.name}</p>
                          <Button
                            onClick={() => {
                              setGambar([
                                ...gambar.slice(0, index),
                                ...gambar.slice(index + 1, gambar.length),
                              ]);
                            }}
                            variant="danger"
                            size="sm"
                            className="btn-link remove text-danger"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </div>
                      );
                    })}

                    <div className="clearfix"></div>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitReportSidak}
                    >
                      Submit
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReactTables;

import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";

export function getPertanyaan(dispatch) {
  baseAxios
    .get("/kuisioner/getsurveyquestion", {})
    .then(async (respon) => {
      dispatch({
        type: "SET_PERTANYAAN",
        data: respon.data,
      });
    })
    .catch(errorHandler);
}

export function kirimHasilSurvey(data) {
  console.log(data);
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  let valid = true;
  data.pertanyaanArr.forEach((element) => {
    if (!element.score || element.score === 0) {
      valid = false;
    }
  });
  if (valid) {
    baseAxios
      .post("/kuisioner/lakukansurvey", {
        pertanyaanArr: JSON.stringify(data.pertanyaanArr),
        nama: data.nama,
        karyawanId: data.karyawanId,
      })
      .then(async (respon) => {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(errorHandler);
  } else {
    Swal.fire({
      title: "Harap isi semua pertanyaan terlebih dahulu!",
      icon: "error",
    });
  }
}

export function deletePertanyaan(data) {
  Swal.fire({
    title: "Loading ...",
    didOpen() {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
  baseAxios
    .delete("/kuisioner/hapuspertanyaan", {
      data: { pertanyaanId: data },
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      Swal.fire({
        title: respon.data.message,
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    })
    .catch(errorHandler);
}

export function simpanPertanyaan(data) {
  let valid = true;
  data.forEach((element) => {
    if (!element.pertanyaan) {
      valid = false;
    }
  });

  if (valid) {
    baseAxios
      .post(
        "/kuisioner/daftarpertanyaan",
        {
          pertanyaanArr: JSON.stringify(data),
        },
        {
          headers: { token: localStorage.getItem("tokenjti") },
        }
      )
      .then(async (respon) => {
        Swal.fire({
          title: respon.data.message,
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(errorHandler);
  } else {
    Swal.fire({
      title:
        "Harap isi semua pertanyaan terlebih dahulu atau hapus pertanyaan tersebut!",
      icon: "error",
    });
  }
}

export function getKpiMonitoringSurvey(dispatch, data) {
  baseAxios
    .get(`/kuisioner/grafikkuisioner?tahun=${data.tahun}`, {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then((respon) => {
      dispatch({ type: "SET_VARIABLESURVEY", data: respon.data });
    })
    .catch(errorHandler);
}

export function getRekapSurvey(dispatch) {
  baseAxios
    .get("/kuisioner/getallkuisioner", {
      headers: { token: localStorage.getItem("tokenjti") },
    })
    .then(async (respon) => {
      dispatch({ type: "SET_REKAPSURVEY", data: respon.data });
    })
    .catch(errorHandler);
}

export function cekStatusUser(dispatch, data) {
  if (data.nama == "" || data.karyawanId == "") {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Name and Employee ID is not be Empty!",
    });
  } else {
    baseAxios
      .post("/kuisioner/cekidusersurvey", data)
      .then(async (respon) => {
        // console.log(respon.data.length);
        if (respon.data.length != 0) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You have filled out the survey!",
          });
        } else {
          dispatch({ type: "SET_STATUS", data: true });
        }
        // dispatch({ type: "SET_STATUS", data: true });
      })
      .catch(errorHandler);
  }
}
